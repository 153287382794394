import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { PrimeButton } from "../../../styles/styles";
import Select from "react-select";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";

const InfoPrompt = styled("span")`
  width: 100%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

function Forms() {
  const [commentSent, setCommentSent] = useState(null);
  const [contact, setContact] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    type: "meeting",
    comment: null,
  });
  const contactTypes = [
    { label: "Sales Inquiry", value: "sales" },
    { label: "Technical Support", value: "support" },
    { label: "Product Demo Request", value: "meeting" },
    { label: "Billing/Pricing Questions", value: "price" },
    { label: "Partnership/Collaboration", value: "collab" },
    { label: "Feature Request", value: "feature" },
    { label: "General Feedback", value: "general" },
    { label: "Other", value: "other" },
  ];

  const handleContact = () => {
    console.log(contact);

    if (
      contact.firstName === null ||
      contact.lastName === null ||
      contact.email === null
    ) {
      setCommentSent({
        success: false,
        message: "Missing required information",
      });
      return;
    }

    setDoc(doc(db, "contact_us", uuidv4()), contact)
      .then((done) => {
        setCommentSent({
          success: true,
          message: "Thank you for your message, we will get back to you soon!",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (commentSent) {
      setTimeout(() => {
        setCommentSent(null);
      }, 5000);
    }
  }, [commentSent]);

  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact--info-area">
                <div className="single-info">
                  <h5>Headquaters</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faHome}
                      style={{ marginRight: "8px" }}
                    />
                    7 Dundas Street, Edinburgh,
                    <br /> EH3 6QG
                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ marginRight: "8px" }}
                    />
                    Coming Soon
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ marginRight: "8px" }}
                    />
                    help@vektagroup.com
                  </p>
                </div>
                <div className="ab-social">
                  <h5>Follow Us</h5>
                  <a
                    className="lin"
                    href="https://www.linkedin.com/company/vekta-group/posts/?feedView=all"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  {/* <a className="you" href="#">
                    <i className="fab fa-youtube"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="contact-form">
                {commentSent && (
                  <InfoPrompt success={commentSent.success}>
                    {commentSent.message}
                  </InfoPrompt>
                )}

                <h4>Contact Form</h4>
                <p>
                  Please select a subject to help use route your inquiry
                  efficiently.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="f-name"
                      placeholder="First Name *"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          firstName:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="l-name"
                      placeholder="Last Name *"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          lastName:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address *"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          email:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          phoneNumber:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    {/* <input
                      type="text"
                      name="suject"
                      placeholder="Subject"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          org:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    /> */}
                    <Select
                      id="userOrgType"
                      defaultValue={{
                        label: "Product Demo Request",
                        value: "meeting",
                      }}
                      isSearchable
                      options={contactTypes}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          // height: "57px",
                          background: "#f5f5f7",
                          fontSize: "14px",
                          color: "#7e7c87",
                          border: "none",
                        }),
                      }}
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          type: e.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      style={{ marginTop: "16px" }}
                      placeholder="How can we help?"
                      onChange={(e) => {
                        setContact((contact) => ({
                          ...contact,
                          comment:
                            e.target.value.length > 0 ? e.target.value : null,
                        }));
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <div className="condition-check">
                      <input id="terms-conditions" type="checkbox" />
                      <label htmlFor="terms-conditions">
                        I agree to the <a href="#">Terms & Conditions</a>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 text-right">
                    <PrimeButton
                      style={{
                        width: "fit-content",
                        fontSize: "18px",
                      }}
                      onClick={handleContact}
                    >
                      Send Message
                    </PrimeButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bisylms-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2233.59768905109!2d-3.2010931227053474!3d55.95634457316029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887c793e5e6a50d%3A0x334afc6bf9dd4660!2s7%20Dundas%20St%2C%20Edinburgh%20EH3%206QG!5e0!3m2!1sen!2suk!4v1738000270309!5m2!1sen!2suk"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
    </>
  );
}

export default Forms;
