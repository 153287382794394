"use client"
import React, { useRef, useEffect, useState } from "react";
import { color_map } from "./Data";
// import { layers } from "./Data";
import styled from "styled-components";
import CableDiameterArrow from "../icons/CableDiameterArrow.svg";
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5a5a5a;
  max-height: 411px;
  min-height: 411px;
`;

const LoadingCircle = styled.div`
  min-width: 400px;
  min-height: 400px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  border-radius: 50%;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

function Cable2D({ layers }) {
  const [totalDiameter, setTotalDiameter] = useState(0);
  let ref = useRef();
  // const layers = props.layers;
  const getPixelRatio = (context) => {
    var backingStore =
      context.backingStorePixelRatio ||
      context.webkitBackingStorePixelRatio ||
      context.mozBackingStorePixelRatio ||
      context.msBackingStorePixelRatio ||
      context.oBackingStorePixelRatio ||
      context.backingStorePixelRatio ||
      1;
    return (window?.devicePixelRatio || 1) / backingStore;
  };

  useEffect(() => {
    let canvas = ref.current;
    if (!canvas) return; // Add this line
    let context = canvas.getContext("2d");
    let ratio = getPixelRatio(context);
    let width = getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);
    canvas.width = width * ratio;
    canvas.height = width * ratio;
    // canvas.style.width = `${width}px`;
    // canvas.style.height = `${height}px`;
    let requestId;

    const lineWidth = 1;

    const drawBasic = (x, y, radius, color) => {
      context.beginPath();
      context.arc(
        canvas.width * x,
        canvas.height * y,
        Math.abs(canvas.width * radius - lineWidth / Math.PI),
        0,
        2 * Math.PI
      );
      context.lineWidth = lineWidth;
      context.strokeStyle = "black";
      context.stroke();
      context.fillStyle = color;
      context.closePath();
      context.fill();
    };
    const drawArray = (x, y, radius, thickness, color) => {
      const r_1 = radius - thickness / 2;
      const r_2 = thickness;

      const a = (2 * r_1 * r_1 - r_2 * r_2) / (2 * r_1);
      const h = Math.sqrt(r_1 * r_1 - a * a);
      const theta = Math.atan2(h, a);
      const N = Math.floor((2 * Math.PI) / Math.abs(theta));

      const angle_increment = (2 * Math.PI) / N;

      for (let i = 0; i < N; i++) {
        const dx = Math.sin(angle_increment * i) * r_1;
        const dy = Math.cos(angle_increment * i) * r_1;
        // console.log(dx)
        drawBasic(x + dx, y + dy, thickness / 2, color);
      }
    };
    const drawSpacedArray = (
      x,
      y,
      radius,
      thickness,
      color,
      color2,
      spacing
    ) => {
      const r_1 = radius - thickness / 2;
      const r_2 = thickness;

      const a = (2 * r_1 * r_1 - r_2 * r_2) / (2 * r_1);
      const h = Math.sqrt(r_1 * r_1 - a * a);
      const theta = Math.atan2(h, a);
      const N = Math.floor((2 * Math.PI) / Math.abs(theta));

      const angle_increment = (2 * Math.PI) / N;

      for (let i = 0; i < N; i++) {
        const dx = Math.sin(angle_increment * i) * r_1;
        const dy = Math.cos(angle_increment * i) * r_1;
        // console.log(dx)
        if (i % spacing) {
          drawBasic(x + dx, y + dy, thickness / 2, color2);
        } else {
          drawBasic(x + dx, y + dy, thickness / 2, color);
        }
      }
    };

    const drawPacked = (x, y, radius, strand_thickness, color) => {
      const num_steps = Math.floor(radius / strand_thickness) + 1;
      if (num_steps<15){
        for (let i = 0; i < num_steps; i++) {
          const new_radius = radius - strand_thickness * i;
          // console.log(strand_thickness,new_radius)
          drawArray(x, y, new_radius, strand_thickness, color);
          if (
            new_radius < strand_thickness &&
            new_radius > strand_thickness / 2
          ) {
            drawBasic(x, y, strand_thickness / 2, color);
          }
        }
      }else{
        // drawBasic(x, y, strand_thickness / 2, color);
        drawBasic(x, y, radius, `rgb(0,0,0,${num_steps/50 + 0.05})`);
      }
    };

    const drawCable = (layers, x, y, max_radius) => {
      const radii = [];
      const colors = [];

      for (let i = 0; i < layers.length; i++) {
        // lower case
        const mode = layers[i].mode.toLowerCase();
        colors[i] = color_map[mode];
        if (i > 0) {
          if (layers[i].type === "triple") {
            let r_1 = radii[i - 1];

            const k_1 = 1 / r_1;
            const k_2 = 3 * k_1 + 2 * Math.sqrt(3 * k_1 * k_1);
            const r_2 = 1 / k_2;
            const r = 2 * r_1 + r_2;
            radii[i] = r;
          } else {
            radii[i] = radii[i - 1] + layers[i].thickness;
          }
        } else {
          radii[i] = layers[i].thickness;
        }
      }

      for (let i = layers.length - 1; i >= 0; i--) {
        // console.log(i)
        const radius = radii[i] / max_radius;
        const thickness = layers[i].thickness / max_radius;
        if (layers[i].type === "basic") {
          // console.log("basic")
          drawBasic(x, y, radius, colors[i]);
        }
        if (layers[i].type === "array") {
          // console.log("array")
          drawBasic(x, y, radius, colors[i]);
          drawArray(x, y, radius, thickness, colors[i]);
        }
        if (layers[i].type === "spaced_array") {
          // console.log("array")
          drawBasic(x, y, radius, layers[i].color2);
          drawSpacedArray(
            x,
            y,
            radius,
            thickness,
            layers[i].color,
            layers[i].color2,
            layers[i].spacing
          );
        }
        if (layers[i].type === "packed") {
          const strand_thickness = (
            layers[i].strand_thickness / max_radius
          ).toFixed(3);

          drawBasic(x, y, radius, colors[i]);
          drawPacked(x, y, radius, strand_thickness, colors[i]);
        }
        if (layers[i].type === "triple") {
          // console.log("triple")

          const inner_radius = radii[i] / max_radius;

          const newLayers = [...layers].splice(0, i);

          drawBasic(x, y, inner_radius, layers[i].color);

          // console.log("inner diameter",inner_radius*2)
          // drawCable(newLayers,x ,y,max_radius );

          const r1 = 1;
          const k1 = 1 / r1; // of of the 3 identical circles

          const k2 = 3 * k1 + 2 * Math.sqrt(3 * (k1 * k1)); // center circle
          const r2 = 1 / k2; // derived from curvature d = a + b + c + sqrt(ab + bc + ca) since a and b and c = 1

          const r3 = r2 + 2 * r1; // biggest radius  align to inner radius
          const k3 = -1 / r3; // outer circle inverted curvature

          const k4 = k1 + k1 + k3 + 2 * Math.sqrt(k1 * k1 + k1 * k3 + k3 * k1); // big filler
          const r4 = 1 / k4;

          const k5 = k1 + k3 + k4 + 2 * Math.sqrt(k1 * k3 + k3 * k4 + k4 * k1);
          const r5 = 1 / k5;

          const r = inner_radius / r3;

          const dxs = [
            Math.sin(0) * (r + r / k2),
            Math.sin((2 * Math.PI) / 3) * (r + r / k2),
            Math.sin((4 * Math.PI) / 3) * (r + r / k2),
          ];
          const dys = [
            Math.cos(0) * (r + r / k2),
            Math.cos((2 * Math.PI) / 3) * (r + r / k2),
            Math.cos((4 * Math.PI) / 3) * (r + r / k2),
          ];

          // test Circles
          // drawBasic(x,y,r3*r,"white")
          // drawBasic(x-dxs[0],y-dys[0],r,"white")
          // drawBasic(x-dxs[1],y-dys[1],r,"white")
          // drawBasic(x-dxs[2],y-dys[2],r,"white")

          const a = r4 - r5;
          const b = r3 - r4;
          const c = r3 - r5;

          const delta_theta = Math.acos((a * a - b * b - c * b) / (-2 * b * c));

          //fillers
          drawBasic(x, y, r / k2, layers[i].filler_color);
          drawBasic(
            x + Math.sin(0) * (r3 * r - r4 * r),
            y + Math.cos(0) * (r3 * r - r4 * r),
            r4 * r,
            layers[i].filler_color
          );
          drawBasic(
            x + Math.sin((2 * Math.PI) / 3) * (r3 * r - r4 * r),
            y + Math.cos((2 * Math.PI) / 3) * (r3 * r - r4 * r),
            r4 * r,
            layers[i].filler_color
          );
          drawBasic(
            x + Math.sin((4 * Math.PI) / 3) * (r3 * r - r4 * r),
            y + Math.cos((4 * Math.PI) / 3) * (r3 * r - r4 * r),
            r4 * r,
            layers[i].filler_color
          );

          drawBasic(
            x + Math.sin(0 + delta_theta) * (r3 * r - r5 * r),
            y + Math.cos(0 + delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );
          drawBasic(
            x + Math.sin(0 - delta_theta) * (r3 * r - r5 * r),
            y + Math.cos(0 - delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );

          drawBasic(
            x + Math.sin((2 * Math.PI) / 3 + delta_theta) * (r3 * r - r5 * r),
            y + Math.cos((2 * Math.PI) / 3 + delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );
          drawBasic(
            x + Math.sin((2 * Math.PI) / 3 - delta_theta) * (r3 * r - r5 * r),
            y + Math.cos((2 * Math.PI) / 3 - delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );

          drawBasic(
            x + Math.sin((4 * Math.PI) / 3 + delta_theta) * (r3 * r - r5 * r),
            y + Math.cos((4 * Math.PI) / 3 + delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );
          drawBasic(
            x + Math.sin((4 * Math.PI) / 3 - delta_theta) * (r3 * r - r5 * r),
            y + Math.cos((4 * Math.PI) / 3 - delta_theta) * (r3 * r - r5 * r),
            r5 * r,
            layers[i].filler_color
          );

          drawCable(newLayers, x - dxs[0], y - dys[0], max_radius);
          drawCable(newLayers, x - dxs[1], y - dys[1], max_radius);
          drawCable(newLayers, x - dxs[2], y - dys[2], max_radius);

          if (layers[i].optic) {
            drawBasic(
              x + Math.sin((2 * Math.PI) / 3) * (r3 * r - r4 * r),
              y + Math.cos((2 * Math.PI) / 3) * (r3 * r - r4 * r),
              layers[i].optic_thickness / 2 / max_radius,
              layers[i].optic_color
            );
            drawArray(
              x + Math.sin((2 * Math.PI) / 3) * (r3 * r - r4 * r),
              y + Math.cos((2 * Math.PI) / 3) * (r3 * r - r4 * r),
              (layers[i].optic_thickness / 2 +
                layers[i].optic_armour_thickness) /
                max_radius,
              layers[i].optic_armour_thickness / max_radius,
              layers[i].optic_armour_color
            );
          }
          break;
        }
      }
    };
    const render = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      let max_radius = 1;

      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === "triple" ) {
          const r_1 = max_radius / 2;
          const k_1 = 1 / r_1;
          const k_2 = 3 * k_1 + 2 *   Math.sqrt(3 * k_1 * k_1);
          const r_2 = 1 / k_2;
          const r = 2 * r_1 + 2 * r_2;
          max_radius += r;
        } else {
          max_radius += layers[i].thickness * 2;
        }
      }
      setTotalDiameter((max_radius - 1) );
      // console.log(radii, colors);
      drawCable(layers, 0.5, 0.5, max_radius);
    };
    render();
    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [layers]);
  return (
    <Column>
      {Array.isArray(layers) && layers.length > 0 ? (
        <canvas id={"cable2d"} ref={ref} style={{ width: "100%" }} />
      ) : (
        <LoadingCircle />
      )}
      <img src={CableDiameterArrow.src} style={{ width: "100%" }} />
      {totalDiameter.toFixed(0)}mm
    </Column>
  );
}

export default Cable2D;
