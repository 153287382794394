import { noConflict } from "jquery";
import { max, min } from "simple-statistics";

export const color_map = {
  copper: "#ff7351",
  aluminium: "#b8b8b8",
  xlpe: "#096c96",
  xlpe_semi_conductive: "#ffd484",
  lead: "#5571d6",
  epr: "#003333",
  pvc: "#73c4f4",
  pe: "#a0e0a9",
  hdpe: "#868686",
  fibrous_material: "#ff7351",
  stainless_steel: "#ffa1a1",
  galvanized_steel: "#6699cc",
  plastic: "#ff7351",
}

export const layerTypes = {
  conductor: {
    name: "Conductor",
    // thickness: 28.34/2,
    thickness: 29.8/2,
    color: "#ff7351",
    type: "packed",
    strand_thickness: 2.2/2, // maybe use diameter, more people are familiar with that
    min:1,
    max:3000,
    step:1,
    units: "mm2", // weird because of display conversion
    min_2: 0.8/2,
    max_2: 7.9/2,
    step_2: 0.2,
    mode: "Copper",
    mode_options: ["Copper", "Aluminium"],
    toggle: true,
  }, // conductor radius
  conductor_shield: {
    name: "Conductor Shield",
    thickness: 1.4,
    color: "#ffd484",
    type: "basic",
    mode: "XLPE_semi_conductive",
    min:0.1,
    max:4,
    units: "mm",
    mode_options: ["XLPE_semi_conductive"],
    step:0.1,
  }, // semi conducting sheath thickness
  insulation: {
    name: "Insulation",
    thickness: 9,
    color: "#096c96",
    type: "basic",
    mode: "XLPE",
    min:1,
    max:20,
    units: "mm",
    mode_options: ["XLPE","EPR","HDPE"],
    step:1,
  }, //insulation
  insulation_shield: {
    name: "Insulation Shield",
    thickness: 1.4,
    color: "#ffd484",
    type: "basic",
    mode: "XLPE_semi_conductive",
    mode_options: ["XLPE_semi_conductive"],
    min:0.1,
    max:4,
    units: "mm",
    step:0.1,
  }, // semi conducting sheath thickness
  metallic_screen: {
    name: "Metallic Screen",
    thickness: 2,
    color: "#5571d6",
    type: "array",
    mode: "Lead",
    mode_options: ["Copper", "Aluminium", "Lead"],
    min:0.1,
    max:4,
    units: "mm",
    step:1,
  }, // metallic sheath
  protective_sheath: {
    name: "Protective sheath",
    thickness: 2,
    color: "#868686",
    type: "basic",
    mode: "HDPE",
    mode_options: ["HDPE","XLPE","EPR", "PVC"],
    min:5,
    max:5,
    units: "mm",
    step:1,
    disabled: true,
  }, // non metallic sheath

  triple: {
    name: "Triple",
    thickness: 0,
    color: "#ffffff",
    type: "triple",
    mode: "no_optic_cable",
    mode_options: ["no_optic_cable"],
    filler_color: "#ffefc7",
    min:0,
    max:0,
    units: "mm",
    step:1,
    disabled: true,
    // optic: true,
    // optic_thickness: 6,
    // optic_armour_thickness: 5,
    // optic_color: "#2c2c2c",
    // optic_armour_color: "white",
    // gasket_recursion: 2,
    // mode: "fibre glass",
    // mode_options: ["with optic cable", "no optic cable"],
  },
  armour_bedding: {
    name: "Armour Bedding",
    thickness: 5,
    color: "#a0e0a9",
    type: "basic",
    mode: "PE",
    mode_options: ["XLPE", "PE","Fibrous_Material"],
    min:5,
    max:5,
    units: "mm",
    step:1,
    disabled: true,
  }, // bedding
  armour: {
    name: "Armour",
    thickness: 5,
    color: "#ffa1a1",
    type: "array",
    mode: "Stainless_Steel",
    mode_options: ["Stainless_Steel", "Galvanized_Steel", "Aluminium", "Plastic"],
    min:1,
    max:10,
    units: "mm",
    step:0.1,
  }, //armour
 
  outer_cover: {
    name: "Outer Cover",
    thickness: 7.5,
    color: "#73c4f4",
    type: "basic",
    mode: "PVC",
    mode_options: ["XLPE", "PE", "EPR", "PVC"],
    min:1,
    max:20,
    units: "mm",
    step:0.5,
    toggle: true,
  }, 
};

export const initLayers = [
  { position: 0, id: "1", ...layerTypes.conductor },
  { position: 1, id: "2", ...layerTypes.conductor_shield },
  { position: 2, id: "3", ...layerTypes.insulation },
  { position: 3, id: "4", ...layerTypes.insulation_shield },
  { position: 4, id: "5", ...layerTypes.metallic_screen },
  { position: 6, id: "6", ...layerTypes.protective_sheath },
  { position: 7, id: "7", ...layerTypes.triple },
  { position: 8, id: "8", ...layerTypes.armour_bedding },
  { position: 5, id: "6", ...layerTypes.armour },
  { position: 6, id: "7", ...layerTypes.outer_cover },
];

// export const searchableLayers = [
//   {
//     id: "conductor",
//     name: "Conductor",
//     description:
//       "Conductor Core - The central part of the cable responsible for transmitting electrical current. Common materials include copper and aluminum.",
//   },
//   {
//     id: "insulation",
//     name: "Insulation",
//     description:
//       "Insulation Layer - Surrounds the conductor to prevent electrical leakage and maintain dielectric properties. Common materials include cross-linked polyethylene (XLPE) and ethylene propylene rubber (EPR).",
//   },
//   {
//     id: "insulation_shield",
//     name: "Sheath Semi-Conductor",
//     description:
//       "Semi-Conductor Sheath - A layer surrounding the insulation to equalize electrical stress and prevent partial discharges. Made of semi-conductive materials.",
//   },
//   {
//     id: "metallic_screen",
//     name: "Sheath Metallic",
//     description:
//       "Metallic Sheath - A protective layer surrounding the cable, providing mechanical protection and a return path for fault currents. Common materials include copper or aluminum tape or wire.",
//   },
//   {
//     id: "sheath_non",
//     name: "Sheath Non-Metallic",
//     description:
//       "Non-Metallic Sheath - A protective layer made of non-metallic materials, such as polyvinyl chloride (PVC) or polyethylene (PE), offering mechanical protection and resistance to moisture.",
//   },
//   {
//     id: "armour",
//     name: "Armour",
//     description:
//       "Armour Layer - Provides additional mechanical protection against external forces and abrasion. Common materials include steel wire or steel tape.",
//   },
//   {
//     id: "armour_bedding",
//     name: "Armour Bed",
//     description:
//       "Armour Bedding - A layer between the armor and the underlying components, providing cushioning and electrical insulation. Common materials include PVC or rubber compounds.",
//   },
//   {
//     id: "outer_cover",
//     name: "Sheath Outer",
//     description:
//       "Outer Sheath - The external layer of the cable, providing protection from environmental factors and mechanical stress. Common materials include PVC, polyethylene (PE), or other protective compounds.",
//   },
//   {
//     id: "triple",
//     name: "Triple",
//     description:
//       "Triple turns the cable into a three-core cable with an optional Optic Fiber cable for data transmission and communication purposes.",
//   },
// ];

// export const items = [
//   { name: "Fruit", description: "Delicious and nutritious" },
//   { name: "Cat", description: "Cute and cuddly" },
//   { name: "Jumpers", description: "Warm and cozy" },
//   { name: "Bicycle", description: "Environmentally friendly transportation" },
//   { name: "Laptop", description: "Portable computing device" },
//   { name: "Guitar", description: "Musical instrument with strings" },
//   { name: "Smartphone", description: "Mobile communication device" },
//   { name: "Coffee", description: "Popular hot beverage" },
//   { name: "Car", description: "Four-wheeled motor vehicle" },
//   { name: "Pizza", description: "Italian dish with various toppings" },
//   { name: "Dog", description: "Loyal and friendly animal" },
//   { name: "Book", description: "Collection of written works" },
//   { name: "Flower", description: "Colorful and fragrant plant" },
//   { name: "Watch", description: "Time-telling accessory" },
//   { name: "Television", description: "Home entertainment device" },
//   { name: "Camera", description: "Photography and videography equipment" },
//   { name: "Shoes", description: "Footwear for various activities" },
//   { name: "Chair", description: "Seating furniture" },
//   { name: "Sunglasses", description: "Eyewear to protect from sun glare" },
//   { name: "Headphones", description: "Audio device for personal listening" },
//   { name: "Backpack", description: "Convenient carry-on for belongings" },
//   { name: "Pencil", description: "Writing and drawing instrument" },
//   { name: "Water", description: "Essential for life and hydration" },
//   { name: "Bird", description: "Feathered and often flying creature" },
//   { name: "Ice Cream", description: "Frozen dessert treat" },
//   { name: "Candle", description: "Source of light and ambiance" },
//   { name: "Computer", description: "Electronic device for various tasks" },
//   { name: "Clock", description: "Time-telling instrument" },
//   {
//     name: "Mountain",
//     description: "Large natural elevation of Earth's surface",
//   },
//   { name: "Beach", description: "Sandy shoreline near the ocean" },
//   { name: "Hat", description: "Headwear for fashion and protection" },
//   { name: "Glasses", description: "Eyewear for vision correction" },
//   { name: "Tablet", description: "Portable touch-screen computing device" },
//   { name: "Pen", description: "Writing instrument with ink" },
//   { name: "Keyboard", description: "Input device for typing" },
// ];

export const initCharacteristics = [
  {
    id: "rated_current_iec_60287_ac_method",
    name: "Rated Current (IEC 60287 AC Method)",
    description: "rated_current_iec_60287_ac_method",
    units: "A",
    value:0,
  },
 
  {
    id: "total_loss_iec_60287_ac",
    name: "Total Electrical Loss (IEC 60287 AC Method)",
    description: "total_loss_iec_60287_ac",
    units: "W/m",
    value:0,
  },
  {
    id: "max_three_phase_fault_current",
    name: "Max Three Phase Fault Current",
    description: "max_three_phase_fault_current",
    units: "KA",
    value:0,
  },
  {
    id: "charging_current",
    name: "Charging Current",
    description: "charging_current",
    units: "A/km",
    value:0,
  }
 
];

// export const searchableCharacteristics_old = [
//   {
//     id: "system_voltage_u",
//     name: "System Voltage U",
//     description: "The nominal voltage of the system in volts (V)",
//   },
//   {
//     id: "system_voltage_um",
//     name: "System Voltage Um",
//     description:
//       "The maximum voltage the system can withstand, also known as the maximum system voltage",
//   },
//   {
//     id: "system_frequency_f",
//     name: "System Frequency f",
//     description:
//       "The frequency of the alternating current (AC) system, typically measured in hertz (Hz)",
//   },
//   {
//     id: "ac_resistance",
//     name: "AC resistance",
//     description:
//       "The resistance of the cable to alternating current, measured in ohms (Ω)",
//   },
//   {
//     id: "conductor_losses",
//     name: "Conductor Losses",
//     description:
//       "Power losses in the cable conductor due to its resistance, measured in watts (W)",
//   },
//   {
//     id: "dielectric_losses",
//     name: "Dielectric Losses",
//     description:
//       "Power losses in the cable insulation due to dielectric heating, measured in watts (W)",
//   },
//   {
//     id: "metallic_screen_losses",
//     name: "Metallic Screen Losses",
//     description:
//       "Power losses in the metallic screen of the cable, measured in watts (W)",
//   },
//   {
//     id: "total_losses",
//     name: "Total Losses",
//     description:
//       "The sum of all power losses in the cable, measured in watts (W)",
//   },
// ];

// export const searchableCharacteristics = [
//   {
//     id: "system_voltage_max",
//     name: "System Voltage Max",
//     description: "The maximum voltage of the system in volts (V)",
//     units: "kV",
//   },
//   {
//     id: "impulse_withstand_voltage",
//     name: "Impulse Withstand Voltage",
//     description:
//       "The maximum voltage the cable can withstand during a surge, also known as the impulse withstand voltage",
//     units: "kV",
//   },
//   {
//     id: "ac_resistance",
//     name: "AC Resistance",
//     description:
//       "The resistance of the cable to alternating current, measured in ohms (Ω)",
//     units: "Ω/m",
//   },
//   {
//     id: "rated_current",
//     name: "Rated Current",
//     description:
//       "The maximum current that the cable is designed to carry, measured in amperes (A)",
//     units: "A",
//   },
//   {
//     id: "conductor_losses",
//     name: "Conductor Losses",
//     description:
//       "Power losses in the cable conductor due to its resistance, measured in watts (W)",
//     units: "W/m",
//   },
//   {
//     id: "screen_losses",
//     name: "Screen Losses",
//     description:
//       "Power losses in the screen of the cable, measured in watts (W)",
//     units: "W/m",
//   },
//   {
//     id: "armour_losses",
//     name: "Armour Losses",
//     description:
//       "Power losses in the armour of the cable, measured in watts (W)",
//     units: "W/m",
//   },
//   {
//     id: "total_losses",
//     name: "Total Losses",
//     description:
//       "The sum of all power losses in the cable, measured in watts (W)",
//     units: "W/m",
//   },
//   {
//     id: "over_diameter",
//     name: "Overall Diameter",
//     description:
//       "The total diameter of the cable, including the insulation, conductors, and outer sheath, if present, measured in millimeters (mm)",
//     units: "mm",
//   },
//   {
//     id: "single_core_diameter",
//     name: "Single Core Diameter",
//     description:
//       "The diameter of a single conductor in the cable, measured in millimeters (mm)",
//     units: "mm",
//   },
//   {
//     id: "weight_in_air",
//     name: "Weight in Air",
//     description:
//       "The weight of the cable when suspended in air, measured in kilograms (kg)",
//     units: "kg/m",
//   },
//   {
//     id: "weight_in_seawater",
//     name: "Weight in Seawater",
//     description:
//       "The weight of the cable when submerged in seawater, measured in kilograms (kg)",
//     units: "kg/m",
//   },
//   {
//     id: "tensile_strength",
//     name: "Tensile Strength",
//     description:
//       "The maximum amount of tensile stress that the cable can withstand before failing, measured in newtons (N)",
//     units: "MPa",
//   },
// ];


export const initEnvironment = {
  system_voltage: 66,
  burial_depth: 1.2,
  ground_thermal_resistivity: 0.7,
  ground_temperature: 12,
  mode: "sand",
  mode_options: ["sand"],
};
