import { Box, Divider } from '@mui/material'
import React, { useState, useEffect, useSyncExternalStore } from 'react'
import styled from "styled-components";

import { InputText } from 'primereact/inputtext';
import SearchBar from '../../../common/SearchBar';

import { auth, db } from '../../../firebase';
import { onAuthStateChanged } from "firebase/auth";

import CloseIcon from '@mui/icons-material/Close';

import Select from 'react-select';

import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { getCompanyIds, getCompanyNames, getQueryables, getUserPermissions, getUserQueryables, mergeQueryables, queryCableCollection, updateWorkspace, uploadCable } from '../../../functions/functions';
import { all } from 'q';

import SearchNameList from '../../../common/SearchNameList';
import { ApplyButton, MeButton, PrimeButton, Section, SectionFooter, SectionFooterLeft, SectionFooterRight } from '../../../styles/styles';
import { doc, getDoc, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Looks } from '@mui/icons-material';
import { BpGraph } from '../../../schema/BpGraph_Cables';
import { prettierName, prettierFormatCableName } from '../../../common/helperFunctions';

const CloseModal = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;
const CableRow = styled("div")`
  /* height: 50%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;
  padding: 4px;

  border-radius: 8px;

  background-color: white;
  color: black;
`;

const SpanTitle = styled("span")`
  width: 35%;
  height: 3rem;
  
  background-color: ${(props) =>
        props.missing ? "rgb(206, 69, 69)" : (props.required ? "#009ec6" : "#f1f1f1")} !important;
  color: ${(props) =>
        props.missing ? "white" : (props.required ? "white" : "black")} !important;

  padding: 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  /* justify-content: center; */

  font-weight: 600;
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
        props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

const PillButton = styled("button")`
  background-color: ${(props) =>
        props.selected ? "#009ec6" : "#d9d9d9"} !important;

    border-radius: 50px;
    border: none;

    font-size: 1rem;

    cursor: ${(props) =>
        props.disabled ? "not-allowed" : "pointer"} !important;

    color: ${(props) =>
        props.selected ? "white" : "black"} !important;

    padding: 16px;
    width: 10%;
`;

const sortList = (option_list) => {
    return option_list.sort((a, b) => {
        const numA = Number(a);
        const numB = Number(b);

        // Check if both can be converted to numbers
        if (!isNaN(numA) && !isNaN(numB)) {
            // Compare numerically
            return numA - numB;
        }
        // Fallback to string comparison if either is not a number
        if (a.localeCompare) {
            return a.localeCompare(b);
        }
    });

}


function FilterModal({
    user,
    handleClose,
    options,
    setOptions,
    queryables,
    filterNames,
    setFilterNames,
    selectedFilters,
    setSelectedFilters,
    count,
    activeWorkspace,
    setActiveWorkspace
}) {
    const bpOptions = new BpGraph;
    const [preSetConfig, setPreSetConfig] = useState(null)

    useEffect(() => {
        console.log("filter or options changed")
        console.log(user)
        if (user && selectedFilters && options) {
            const new_workspace = {
                collection: {
                    selectedFilters: selectedFilters,
                    options: options
                }
            }
            setActiveWorkspace(prev => ({ ...prev, ...new_workspace }))
            // updateWorkspace(user, new_workspace)
        }

    }, [selectedFilters, options, user])


    useEffect(() => {
        const user_filter_names = Object.keys(queryables)
        setFilterNames(user_filter_names)
    }, [queryables])

    const ParameterSearch = () => {
        const parameter_names = [...Object.keys(queryables).filter(item => item !== "id" && item !== "cid" && Object.keys(bpOptions.bp_graph).includes(item)), "organisation", "Last edited by"]

        const parameter_options = parameter_names.map((parameter) => {
            return { value: parameter, label: prettierName(parameter) }
        })

        return <div style={{ width: "88.5%" }}><Select
            options={parameter_options}
            isMulti
            isSearchable
            isOptionDisabled={() => (selectedFilters.length >= 10)}
            placeholder={`Highlight Parameters`}
            defaultValue={selectedFilters.map((item, i) => { if (i <= 10) return { value: item, label: prettierName(item) } })}
            onChange={(selected) => {
                console.log("react select highlight change")
                const selected_values = selected.map(item => item.value)
                let new_selected_filters = []
                if (selected_values.length === 0) {
                    new_selected_filters = []
                } else {
                    new_selected_filters = selected_values
                }
                setSelectedFilters(new_selected_filters)
            }}
        /></div>
    }



    const listFilters = (options, selectedFilters) => {
        const temp = [...filterNames];

        if (selectedFilters.length === 0) {
            temp.sort((a, b) => (Object.keys(bpOptions.bp_graph).indexOf(a) - Object.keys(bpOptions.bp_graph).indexOf(b)))
        }

        const cleaned_temp = [...temp.filter(item => item !== "id" && item !== "cid" && Object.keys(bpOptions.bp_graph).includes(item)), "organisation", "Last edited by"]
        return cleaned_temp.map((filter) => {
            const options_list = sortList(queryables[filter] ? queryables[filter] : [])
            // const options_list = sortList(queryables[filter])
            const drop_options = []
            options_list.map(item => {
                drop_options.push({ value: item, label: prettierFormatCableName(item) })
            })

            let visible = selectedFilters.includes(filter)
            if (selectedFilters.length === 0) visible = true
            const uuid = uuidv4()

            let filter_options = options[filter]
            if (filter_options === undefined) filter_options = []
            filter_options = sortList(filter_options)

            let units = null;
            if (bpOptions.bp_graph.hasOwnProperty(filter)) units = bpOptions.bp_graph[filter].units;

            return (
                <CableRow className='cableRow' key={uuid} style={{
                    display: visible ? "flex" : "none"
                }}>
                    <SpanTitle>{prettierName(filter)}</SpanTitle>

                    <Select
                        options={drop_options}
                        className="filterSelect"
                        isMulti
                        isSearchable
                        placeholder={``}
                        value={filter_options ? filter_options.map(item => { return { value: item, label: prettierName(item) } }) : []}
                        onChange={(selected) => {
                            console.log("react select change")
                            const selected_values = selected.map(item => item.value)
                            const ordered_values = selected_values.sort((a, b) => {
                                const numA = Number(a);
                                const numB = Number(b);

                                // Check if both can be converted to numbers
                                if (!isNaN(numA) && !isNaN(numB)) {
                                    // Compare numerically
                                    return numA - numB;
                                }
                                // Fallback to string comparison if either is not a number
                                return a.localeCompare(b);
                            });
                            setOptions({ ...options, [filter]: ordered_values })
                        }}
                    />
                    {units ? <SpanTitle style={{ width: "10%" }}>{units}</SpanTitle> : <div style={{ width: "10%" }}></div>}
                </CableRow>
            )
        })
    }

    const handlePreSetSelection = () => {
        if (preSetConfig === "custom") {
            setPreSetConfig(null);
            setSelectedFilters([])
            return;
        }

        setPreSetConfig("custom");
        const temp = ["metadata:conductor_size", "metadata:system_voltage", "design:cable_type", "conductor:material", "organisation"].sort((a, b) => (Object.keys(bpOptions.bp_graph).indexOf(a) - Object.keys(bpOptions.bp_graph).indexOf(b)))
        setSelectedFilters(temp);
    }

    return (
        <div
            className='filterModal'
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "background.paper",
                    borderRadius: "8px",
                    padding: 2,
                    maxHeight: "90vh",
                    position: "fixed",
                    inset: "auto 15%",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
            >
                <header>
                    <h2>Filter Options</h2>
                    <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {ParameterSearch()}
                        <PillButton selected={preSetConfig === "custom"} onClick={() => handlePreSetSelection("custom")}>Custom</PillButton>
                    </div>
                    <CloseModal onClick={handleClose}>
                        <StyledIconButton>
                            <CloseIcon />
                        </StyledIconButton>
                    </CloseModal>
                </header>

                <CableRow style={{ marginTop: "16px" }} key={uuidv4()}>
                    <label key={uuidv4()} style={{ width: "35%", textAlign: "left", fontWeight: "600" }}>Specification Name</label>
                    <label key={uuidv4()} style={{ width: "55%", textAlign: "left", fontWeight: "600" }}>Value</label>
                    <label key={uuidv4()} style={{ width: "10%", textAlign: "left", fontWeight: "600" }}>Units</label>
                </CableRow>

                <div style={{ overflowY: "scroll", height: "100%", marginBottom: "16px", paddingBottom: "300px" }}>
                    {
                        listFilters(options, selectedFilters)
                    }
                </div>
                <SectionFooter>
                    <SectionFooterLeft>Results: {count}</SectionFooterLeft>
                    <PrimeButton
                        variant='contained'
                        onClick={async () => {
                            // document.getElementById("SecretFilterButton").click()
                            handleClose()
                        }} >Close</PrimeButton>
                </SectionFooter>
            </Box>
        </div>
    )
}

export default FilterModal