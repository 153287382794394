import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { proxy_url } from "../../../../../firebase";
import {
  getCableByName,
  getVesselByName,
} from "../../../../../functions/functions";
import { BpGraph } from "../../../../../schema/BpGraph_Cables";
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import { StyledIconButton } from "../../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Divider, Modal } from "@mui/material";
import BpModal from "../../../../cables/bp/BpModal";
import CloseIcon from "@mui/icons-material/Close";
import { InputText } from "primereact/inputtext";
import CaraDiagram from "../../../../../media/three-core-cable.png";

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const CableRow = styled("div")`
  /* height: 50%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;
  padding: 4px;

  border-radius: 8px;

  background-color: white;
  color: black;
`;

const SpanTitle = styled("span")`
  width: 35%;
  height: 3rem;

  background-color: ${(props) =>
    props.missing
      ? "rgb(206, 69, 69)"
      : props.required
      ? "#009ec6"
      : "#f1f1f1"} !important;
  color: ${(props) =>
    props.missing ? "white" : props.required ? "white" : "black"} !important;

  padding: 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  /* justify-content: center; */

  font-weight: 600;
`;

const inputStyle = {
  width: "55%",
  height: "3rem",
  fontFamily: "Montserrat",
  fontWeight: "medium",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "10px",
  borderRadius: "4px",
  color: "hsl(0, 0%, 20%)",
};

function CarouselSummary({ loadedScenario, setCaraInfo }) {
  const [loading, setLoading] = useState(true);
  const [caraSepcs, setCaraSepcs] = useState(null);
  const [cableInfo, setCableInfo] = useState({ open: false, cable: null });
  const [carousel, setCarousel] = useState("2-Carousel");
  const order = [
    "name",
    "weight",
    "max_speed",
    "cruising_speed",
    "draft",
    "number_of_carousels",
    "carousel_1_inner_diameter",
    "carousel_1_outer_diameter",
    "carousel_1_weight",
    "carousel_1_height",
    "carousel_2_inner_diameter",
    "carousel_2_outer_diameter",
    "carousel_2_weight",
    "carousel_2_height",
  ];

  const handleGetCable = async (cableName) => {
    const cableData = await getCableByName("vekta", cableName);

    setCableInfo({ open: true, cable: cableData });
  };

  const displayName = (name) => {
    const split = name.split("_");

    return split.map(
      (item) =>
        String(item).charAt(0).toUpperCase() + String(item).slice(1) + " "
    );
  };

  useEffect(() => {
    const updateCara = async (selectedCable, user) => {
      let caraData = await getVesselByName(
        "vekta",
        loadedScenario["vessels:selected_vessel"].replaceAll(" ", "_")
      );

      let keys = Object.keys(caraData);
      keys.sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b);
      });

      setCaraSepcs(
        keys.map((row) => {
          let unit = "m";
          if (row.includes("weight")) {
            unit = "t";
          } else if (row.includes("speed")) {
            unit = "kn";
          }

          return (
            row !== "id" &&
            row !== "cid" &&
            row !== "organisation" &&
            row !== "carousel" && (
              <CableRow className="cableRow">
                <SpanTitle>{displayName(row)}</SpanTitle>
                <InputText
                  readOnly
                  id={row}
                  disabled={false}
                  value={caraData[row]} // Needs to be value to get the right value ???
                  style={inputStyle}
                />

                {row !== "name" && row !== "number_of_carousels" ? (
                  <SpanTitle style={{ width: "10%" }}>{unit}</SpanTitle>
                ) : (
                  <div style={{ width: "10%" }}></div>
                )}
              </CableRow>
            )
          );
        })
      );
    };

    const temp = loadedScenario["vessels:selected_vessel"].split("_");
    setCarousel(temp[temp.length - 1]);
    updateCara();
  }, [loadedScenario["vessels:selected_vessel"]]);

  // Render the table
  return (
    <div
      className="cableModal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        id="scenarioModal"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          // maxHeight: "90vh",
          position: "fixed",
          inset: "10% 10%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <header style={{ gridArea: "1 / 1 / 2 / 7" }}>
          <h2>Vessel Details</h2>
          <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
          <CloseModal onClick={() => setCaraInfo(false)}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>
        </header>

        <body
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            minHeight: "300px",
            height: "85%",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              // minHeight: "300px",
              // height: "78%",
              // marginBottom: "16px",
              // marginTop: "16px",
              width: "55%",
              overflowY: "scroll",
              height: "100%",
            }}
          >
            <CableRow style={{ backgroundColor: "white", fill: "black" }}>
              <label
                style={{ width: "35%", textAlign: "left", fontWeight: "600" }}
              >
                Specification Name
              </label>
              <label
                style={{ width: "55%", textAlign: "left", fontWeight: "600" }}
              >
                Value
              </label>
              <label
                style={{ width: "10%", textAlign: "left", fontWeight: "600" }}
              >
                Unit
              </label>
            </CableRow>
            {caraSepcs}
          </div>

          <div
            style={{
              width: "45%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
            }}
          >
            <img
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain" }}
              src={"./media/carousel_diagrams/" + carousel + ".png"}
              alt={"test"}
            />
          </div>
        </body>
      </Box>
    </div>
  );
}

export default CarouselSummary;
