import React, { useEffect, useState } from "react";
import { getCheckoutUrl, getPortalUrl } from "../helperFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebase";

import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { PrimeButton } from "../../../styles/styles";
import { Divider } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";

function PricingHomeOne({
  className,
  setLoginSelected,
  setDemoSelected,
  toolInfo,
  homePage,
  setHomePage,
}) {
  const [toggleSwitch, setSwitchValue] = useState(true);
  const [user, loading, error] = useAuthState(auth);

  const [loadingCheckout, setLoadingCheckout] = useState({
    month: { vc: false, export: false },
    year: { vc: false, export: false },
    monthTrial: false,
    yearTrial: false,
  });

  const handleToolBuy = async (tool, interval, trial = false, toolName) => {
    // console.log(tool);
    if (user) {
      setLoadingCheckout((loadingCheckout) => ({
        ...loadingCheckout,
        [interval + (trial ? "Trial" : "")]: toolName
          ? {
              ...loadingCheckout[interval + (trial ? "Trial" : "")],
              [toolName]: true,
            }
          : true,
      }));

      const permissions = await getDoc(doc(db, "permissions", user.uid));

      let baseTool = "prod_RalRylHosOXKuZ";
      if (interval === "year") baseTool = "prod_RalSN1KcQQ9WEd";

      // console.log(trial);

      const checkoutUrl = await getCheckoutUrl(
        [
          toolInfo[tool[0]].prices[interval].id,
          ...(permissions.data() === undefined && toolName === "export"
            ? [toolInfo[baseTool].prices[interval].id]
            : []),
        ],
        trial
      );
      // console.log(checkoutUrl);
      window.location.href = checkoutUrl.url;

      setLoadingCheckout({
        month: { vc: false, export: false },
        year: { vc: false, export: false },
        monthTrial: false,
        yearTrial: false,
      });
      return;
    }

    setLoginSelected(true);
  };

  useEffect(() => {
    // console.log(loadingCheckout);
  }, [loadingCheckout]);

  return (
    <>
      <section
        id="pricing-cards"
        className={`appie-pricing-area pt-90 pb-90 ${className || ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Choose your plan</h3>
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? "active" : ""}`}>
                  <a href="#">Quarterly</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? "on" : "off"}`}
                  >
                    <span className="slider round" />
                  </label>
                </li>
                <li
                  className={`year ${toggleSwitch === false ? "active" : ""}`}
                >
                  <a href="#">Annually</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tabed-content">
            {toggleSwitch ? (
              <div id="month">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Trial</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                          <p>/two weeks</p>
                        </div>
                        <p>Get 14 days free trial</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>14 Days Completely Free</li>
                          <li>Access all features in the "Single User" plan</li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "month",
                                true
                              )
                            }
                          >
                            {loadingCheckout.monthTrial ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="#00a0c6"
                              />
                            ) : (
                              "Activate Trial"
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Vekta Cables</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>1,500</span>
                          <p>/quarter</p>
                        </div>
                        <p>Billed £1,500 per 3 months. (Single User)</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta Cables - Quarterly"
                                ),
                                "month",
                                false,
                                "vc"
                              )
                            }
                          >
                            {loadingCheckout.month.vc ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Purchase"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Installation Module</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>500</span>
                          <p>/quarter (limited offer)</p>
                        </div>
                        <p>Billed £500 per 3 months. (Single User)</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Installation Module (Into) - Quarterly"
                                ),
                                "month",
                                false,
                                "export"
                              )
                            }
                          >
                            {loadingCheckout.month.export ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Purchase"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>New Release</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Enterprise</h6>
                        <p style={{ margin: "0" }}>Starts From</p>
                        <div className="price-range">
                          <sup>£</sup> <span>7,000</span>
                          <p>/quarter</p>
                        </div>
                        <p>Billed £7,000 per 3 months</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() => setDemoSelected(true)}
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div id="year">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Trial</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                          <p>/two weeks</p>
                        </div>
                        <p>Get 14 days free trial</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>14 Days Completely Free</li>
                          <li>Access all features in the "Single User" plan</li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta-Cables_Single-User"
                                ),
                                "year",
                                true
                              )
                            }
                          >
                            {loadingCheckout.yearTrial ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="#00a0c6"
                              />
                            ) : (
                              "Activate Trial"
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Vekta Cables</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>5,000</span>
                          <p>/yearly</p>
                        </div>
                        <p>Billed £5,000 annually. (Single User)</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Vekta Cables - Annually"
                                ),
                                "year",
                                false,
                                "vc"
                              )
                            }
                          >
                            {loadingCheckout.year.vc ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Purchase"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Installation Module</h6>
                        <div className="price-range">
                          <sup>£</sup> <span>2,000</span>
                          <p>/yearly (limited offer)</p>
                        </div>
                        <p>Billed £2,000 annually. (Single User)</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {homePage ? (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() => {
                              getPortalUrl().then((url) => {
                                window.location.href = url;
                              });
                            }}
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <a
                            className="main-btn"
                            style={{ width: "100%" }}
                            href="#"
                            onClick={() =>
                              handleToolBuy(
                                Object.keys(toolInfo).filter(
                                  (tool) =>
                                    toolInfo[tool].info.name ===
                                    "Installation Module (Into) - Annually"
                                ),
                                "year",
                                false,
                                "export"
                              )
                            }
                          >
                            {loadingCheckout.year.export ? (
                              <BeatLoader
                                style={{ height: "100%" }}
                                color="white"
                              />
                            ) : (
                              "Purchase"
                            )}
                          </a>
                        )}
                      </div>
                      <div className="pricing-rebon">
                        <span>New Release</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight mb-20">
                    <div
                      className="pricing-one__single center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="pricig-heading">
                        <h6>Enterprise</h6>
                        <p style={{ margin: "0" }}>Starts From</p>
                        <div className="price-range">
                          <sup>£</sup> <span>25,000</span>
                          <p>/yearly</p>
                        </div>
                        <p>Billed £25,000 annually</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Access Over 600 Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Design Custom Cables
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Financial & Environment Settings
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Uniform & Customizable Reports
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Technical Support & Updates
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Neural Network Pricing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Feature Requests
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Company Account up to 20 Users
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() => setDemoSelected(true)}
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                // display: "flex",
                // flexWrap: "wrap",
                justifyContent: "space-around",
                // alignItems: "center",
                // flexDirection: "row",
                gap: "8px",
                margin: "0",
                // height: "150px",
              }}
              className="pricing-one__single row align-items-center"
            >
              <h4 className="col-lg-3" style={{ textAlign: "center" }}>
                Vekta Cables & <br></br> Installation Module
              </h4>

              <Divider orientation="vertical" flexItem />

              {/* <ul> */}
              <p
                className="col-lg-3"
                style={{ textAlign: "center", margin: "0" }}
              >
                14 Days Completely Free
              </p>
              {/* <li>Access all features in the "Single User" plan</li> */}
              {/* </ul> */}

              <Divider orientation="vertical" flexItem />

              <div
                className="col-lg-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {homePage ? (
                  <PrimeButton
                    // className="main-btn"
                    // href="#"
                    onClick={() => {
                      getPortalUrl().then((url) => {
                        window.location.href = url;
                      });
                    }}
                  >
                    Manage Subscription
                  </PrimeButton>
                ) : (
                  <PrimeButton
                    // className="main-btn"
                    // href="#"
                    onClick={() =>
                      handleToolBuy(
                        Object.keys(toolInfo).filter(
                          (tool) =>
                            toolInfo[tool].info.name ===
                            "Vekta Cables - Quarterly"
                        ),
                        "month",
                        true,
                        false
                      )
                    }
                  >
                    {loadingCheckout.monthTrial ? (
                      <BeatLoader style={{ height: "100%" }} color="white" />
                    ) : (
                      "Activate Trial"
                    )}
                  </PrimeButton>
                )}
              </div>
            </div>
            <p style={{ textAlign: "center" }}>
              *International buyers, please note that no additional taxes will
              be applied to the purchase of any of the above licenses.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingHomeOne;
