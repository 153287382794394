import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { group_to_titles } from "./helperFunctions";
import TableRender from "./TableRender";
import FrontPage from "./FrontPage";
import SecondPage from "./SecondPage";
import StyledTable from "../outputs/StyledTable";
import DatasheetTable from "../outputs/DatasheetTable";
import VesselSummary from "../../common/VesselSummaryTable";
import DeplayBarChart from "../outputs/DeplayBarChart";
import Sunburst from "../outputs/Sunburst";
import Steams from "../outputs/Steams";

// Styled components
const OuterBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ReportContainer = styled.div`
  position: relative;
  margin-top: 0px;
  padding: 16px;
`;

const ReportRender = ({ logo, settings, previewPDF, data, includedSpecs }) => {
  const [tables, setTables] = useState(null);

  useEffect(() => {
    // // console.log(logo);
  }, [logo]);

  useEffect(() => {
    // console.log({ data });

    if (data?.resultsData && data?.costData) {
      setTables([
        <TableRender
          data={{
            duration: data.resultsData.scenarioSummary,
            cost: data.costData,
          }}
          settings={settings}
          logo={logo}
          title={"Installation Costs and Duration"}
          tableType={[
            <StyledTable
              header={null}
              data={
                data.resultsData?.scenarioSummary
                  ? data.resultsData?.scenarioSummary
                  : [{}]
              }
              order={false}
              style={{ compact: true, colour: settings.primaryColor }}
            />,
            <StyledTable
              header={null}
              data={data.costData}
              order={false}
              style={{ compact: true, colour: settings.primaryColor }}
            />,
          ]}
        />,
        <TableRender
          data={{
            cables: data.cables,
          }}
          settings={settings}
          logo={logo}
          title={"Cable Information"}
          tableType={[
            <DatasheetTable
              title={null}
              data={data.cables}
              filtered={[
                "cable_name",
                "cable_type",
                "conductor_material",
                "conductor_size",
                "insulation_material",
                "system_voltage",
                "mass",
                "axial_stiffness",
                "max_allowable_pulling_tension_basket_grip",
                "max_allowable_sidewall_pressure",
                "min_bend_radius_drum",
                "min_bend_radius_installation",
              ]}
              style={{
                primaryColor: settings.primaryColor,
                secondaryColor: settings.secondaryColor,
                tertiaryColor: settings.tertiaryColor,
              }}
            />,
          ]}
        />,
        <TableRender
          data={{
            cables: data.cables,
          }}
          settings={settings}
          logo={logo}
          title={"Installation Sequence"}
          tableType={[
            <StyledTable
              header={null}
              data={
                data.batchData?.listIn?.site_exportSections[
                  data.batchData.scenario["site:minimise_by"]
                ]
              }
              order={["ids", "End 1", "End 2", "carousel", "Length", "Load"]}
              style={{ compact: true, colour: settings.primaryColor }}
            />,
          ]}
        />,
        <TableRender
          data={{
            cables: data.cables,
          }}
          settings={settings}
          logo={logo}
          title={
            data.batchData.scenario["site:scenario_setting"] === "weathered"
              ? "Vessel Breakdown - Part 1 of 4"
              : "Vessel Breakdown - Part 1 of 2"
          }
          tableType={[
            <VesselSummary
              cables={[
                data.batchData.scenario["vessels:primary_carousel_cable"],
                data.batchData.scenario["vessels:secondary_carousel_cable"],
              ]}
              capacities={data.batchData?.listIn?.capacities}
              vessel={data.batchData.scenario["vessels:selected_vessel"]}
              style={{ height: "100%", colour: settings.primaryColor }}
            />,
          ]}
        />,
        ...(data.batchData.scenario["site:scenario_setting"] === "weathered"
          ? [
              <TableRender
                data={{
                  cables: data.cables,
                }}
                settings={settings}
                logo={logo}
                title={"Vessel Breakdown - Part 2 of 4"}
                tableType={[
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "8px",
                      border: `2px solid ${settings.primaryColor}`,
                      borderRadius: "8px",
                      padding: "8px",
                      margin: "20px",
                    }}
                  >
                    <DeplayBarChart
                      resultData={{
                        data: data.resultsData?.recordDelays,
                        names: data.resultsData?.delayNames,
                      }}
                      percentile={data.percent}
                      title={false}
                    />
                    <span>
                      <i>Figure 3 - Operational delay relative to activity</i>
                    </span>
                  </div>,
                ]}
              />,
            ]
          : []),
        ...(data.batchData.scenario["site:scenario_setting"] === "weathered"
          ? [
              <TableRender
                data={{
                  cables: data.cables,
                }}
                settings={settings}
                logo={logo}
                title={"Vessel Breakdown - Part 3 of 4"}
                tableType={[
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "8px",
                      border: `2px solid ${settings.primaryColor}`,
                      borderRadius: "8px",
                      padding: "8px",
                      margin: "20px",
                    }}
                  >
                    <Steams
                      resultsData={data.resultsData}
                      minimise={data.batchData.scenario["site:minimise_by"]}
                      percentile={data.percent}
                      title={false}
                    />
                    <span>
                      <i>
                        Figure 4 - Weather probability for total installation{" "}
                      </i>
                    </span>
                  </div>,
                ]}
              />,
            ]
          : []),
        <TableRender
          data={{
            cables: data.cables,
          }}
          settings={settings}
          logo={logo}
          title={
            data.batchData.scenario["site:scenario_setting"] === "weathered"
              ? "Vessel Breakdown - Part 4 of 4"
              : "Vessel Breakdown - Part 2 of 2"
          }
          tableType={[
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                border: `2px solid ${settings.primaryColor}`,
                borderRadius: "8px",
                padding: "8px",
                margin: "20px",
              }}
            >
              <Sunburst
                resultData={data.resultsData?.ganttSummary}
                title={false}
              />
              <span>
                <i>Figure 5 - Breakdown of vessel activity </i>
              </span>
            </div>,
          ]}
        />,
      ]);
    }
  }, [data, logo, settings, includedSpecs]);

  return (
    <OuterBox
      style={{
        position: !previewPDF ? "fixed" : "relative",
        left: !previewPDF ? "999999px" : "0px",
        width: "100%",
      }}
    >
      <ReportContainer
        id="reportContainer"
        style={{
          position: "absolute",
          height: "100%",
          margin: "auto",
          left: "50%",
          top: "0px",
          transform: "translate(-50%, 0px)",
        }}
      >
        <FrontPage logo={logo} settings={settings} />
        <SecondPage logo={logo} settings={settings} />
        {tables}
      </ReportContainer>
    </OuterBox>
  );
};

export default ReportRender;
