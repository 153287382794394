import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Divider, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  PrimeButton,
  SecButton,
  StyledIconButton,
} from "../../../styles/styles";

import { BpGraph_Export } from "../../../schema/BpGraph_Export";
import Map from "./additions/site/Map";

import Select from "react-select";
import { InputText } from "primereact/inputtext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { prettierName } from "../../../common/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import CableSplit from "./additions/vessel/CableSplit";
import {
  generateConditions,
  getCables,
  getUserPermissions,
  getUserQueryables,
  getVesselByName,
  getVessels,
  uploadScenario,
} from "../../../functions/functions";
import SiteExportConnections from "./additions/site/SiteExportConnections";
import { getCapacity, handleAddScenario } from "../helperFunctions";
// import ScheduleModal from "../common/ScheduleModal";
import VesselSummary from "../common/VesselSummaryTable";
import GenericTable from "../common/GenericTable";
import VesselOperations from "./additions/vessel/VesselOperations";
import CableSummary from "./additions/vessel/CableSummary";
import CarouselSummary from "./additions/vessel/CarouselSummary";
import { InputNumber } from "primereact/inputnumber";
import EditTable from "../../../media/tutorials/edit-table-cables.gif";

import Joyride, { STATUS } from "react-joyride";
import { OverlayPanel } from "primereact/overlaypanel";

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const CableRow = styled("div")`
  /* height: 50%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;
  padding: 4px;

  border-radius: 8px;

  background-color: white;
  color: black;
`;

const SpanTitle = styled("span")`
  width: 35%;
  height: 3rem;

  background-color: ${(props) =>
    props.missing
      ? "rgb(206, 69, 69)"
      : props.required
      ? "#00a0c6"
      : "#f1f1f1"} !important;
  color: ${(props) =>
    props.missing ? "white" : props.required ? "white" : "black"} !important;

  padding: 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  /* justify-content: center; */

  font-weight: 600;
`;

const inputStyle = {
  width: "55%",
  height: "3rem",
  fontFamily: "Montserrat",
  fontWeight: "medium",
  // paddingTop: "5px",
  // paddingBottom: "5px",
  // paddingLeft: "10px",
  borderRadius: "4px",
  color: "hsl(0, 0%, 20%)",
};

const CableSpecHeader = styled("header")`
  background: #00a0c6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;

  padding: 8px;

  h4 {
    margin: 0;
  }
`;

// BpModal component - renders the modal for scenario configuration
function BpModal({
  user,
  handleClose,
  type,
  modalName,
  caraCable,
  setCaraCable,
  scenario = false,
  batchData,
  setBatchData,
  setBatchSummary,
}) {
  dayjs.extend(customParseFormat); // Enable custom format parsing
  // State variables for component data and logic
  const [weatherPoint, setWeatherPoint] = useState({ on: false, point: null });
  const getWeatherPoint = (setting) => {
    setWeatherPoint({ on: true, point: setting });
  };
  const [vesselOperations, setVesselOperations] = useState(false);
  const [caraInfo, setCaraInfo] = useState(false);
  const bpGraph = new BpGraph_Export(
    {
      weather: getWeatherPoint,
      vessel: setVesselOperations,
      carousel: setCaraInfo,
    }
    // checkDisabled
  );

  const orderScenario = (unordered) => {
    const ordered = bpGraph.get_defaults();

    // console.log({ unordered });

    Object.keys(ordered).forEach((key) => {
      ordered[key] = unordered[key];
    });

    ordered.batchId = unordered.batchId;

    return ordered;
  };
  const [loadedScenario, setLoadedScenario] = useState(
    !scenario ? bpGraph.get_defaults() : orderScenario(scenario)
  );

  const [cableAction, setCableAction] = useState(type);
  const [editCable, setEditCable] = useState(type === "Close");
  const [stepIndex, setStepIndex] = useState(0);
  const [cableCollection, setCableCollection] = useState([]);
  const [vesselCollection, setVesselCollection] = useState([]);
  const [vesselCap, setVesselCap] = useState([]);
  const [review, setReview] = useState();

  const editTables = useRef(null);
  const [runJoyride, setRunJoyride] = useState(false);
  const [tutorial, setTutorial] = useState("Cable_Connections");
  const [steps, setSteps] = useState({
    Cable_Connections: [
      {
        content: (
          <div>
            <h2>Editing Table</h2>
            {/* <img width={"100%"} src={CableRow} /> */}
            <p>
              Edit tables the same way they can be editing in Excel - Double
              click the cell you wish to edit
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "top",
        target: "#table-edits",
        disableBeacon: true,
      },
    ],
  });
  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const handleTutorial = (name) => {
    setTutorial(name.replace(" ", "_"));
    setRunJoyride(true);
  };

  useEffect(() => {
    // console.log({ loadedScenario });
  }, [loadedScenario]);

  /**
   *
   * Cable dB Connection - Getting all cables from the cable.
   * This populates the cable selection drop downs.
   *
   */
  useEffect(() => {
    const initCables = async (setCableCollection) => {
      const queryables = await getUserQueryables(user.uid);
      const conditions = generateConditions(queryables, []);

      let cables = await getCables(conditions, null, 5000);
      cables = cables[0].map((cable) => cable["metadata:name"]);

      setCableCollection(cables);
    };

    const initVessels = async (setVesselCollection) => {
      const queryables = await getUserQueryables(user.uid);
      const conditions = generateConditions(queryables, []);

      // console.log({ queryables, conditions });
      let vessels = await getVessels(conditions, null, 5000);
      vessels = vessels[0].map((vessel) => vessel["name"]);

      setVesselCollection(vessels);
    };

    initCables(setCableCollection);
    initVessels(setVesselCollection);
  }, []);

  /**
   *
   * Vessel Capacity - Calling getCapcity endpoint to get the vessel
   * capacity based on changes to selected vessel or cable change
   *
   */
  useEffect(() => {
    const capacity = async () => {
      const temp = await getCapacity(
        loadedScenario["vessels:selected_vessel"],
        loadedScenario["cable:carousel"]
      );

      setVesselCap(temp);
    };

    if (!modalName.includes("Settings")) capacity();
  }, [
    loadedScenario["vessels:selected_vessel"],
    loadedScenario["cable:carousel"],
  ]);

  /**
   *
   * Vessel Capacity - Calling getCapcity endpoint to get the vessel
   * capacity based on changes to selected vessel or cable change
   *
   */
  // useEffect(() => {
  //   handleUpdateScenario(
  //     { value: loadedScenario["vessels:primary_carousel_cable"] },
  //     "vessels:secondary_carousel_cable"
  //   );
  // }, [loadedScenario["vessels:primary_carousel_cable"]]);

  /**
   *
   * Updating the "Cable Connections" table if changes to number of x values or cables.
   * Changes the options for the end point dropdowns, how many rows the table has and
   * what cables can be selected.
   *
   */
  useEffect(() => {
    // console.log("Cable Connections --- Updated to cable connection variables");
    if (!modalName.includes("Settings")) {
      const hdds = Array.from(
        { length: loadedScenario["site:number_of_HDD"] },
        (_, i) => `Landfall ${i + 1}`
      );
      const osps = Array.from(
        { length: loadedScenario["site:number_of_OSP"] },
        (_, i) => `OSP ${i + 1}`
      );
      const endPoints = [...hdds, ...osps];
      const cables = [
        loadedScenario["vessels:primary_carousel_cable"],
        loadedScenario["vessels:secondary_carousel_cable"],
      ];

      const defaultRow = (i) => {
        return {
          ids: { value: `EXP${i + 1}`, options: { editable: false } },
          "End 1": {
            value: "Landfall 1",
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: endPoints,
              },
            },
            label: "End Point 1",
          },
          "End 2": {
            value: "OSP 1",
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: endPoints,
              },
            },
            label: "End Point 2",
          },
          carousel: {
            value: loadedScenario["vessels:primary_carousel_cable"],
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: [
                  loadedScenario["vessels:primary_carousel_cable"],
                  loadedScenario["vessels:secondary_carousel_cable"],
                ],
              },
            },
            label: "Cable",
          },
          Length: { value: 40, options: { editable: true } },
        };
      };

      const generateTable = (length) => {
        const tempLength = length > 10 ? 10 : length < 1 ? 1 : length;

        console.log({ length, tempLength });

        return Array.from(
          {
            length: tempLength,
          },
          (_, i) => defaultRow(i)
        );
      };

      if (
        loadedScenario["site:number_of_export_cables"] >
          loadedScenario["site:exports"].length &&
        loadedScenario["site:number_of_export_cables"] !== 0
      ) {
        const exports = generateTable(
          loadedScenario["site:number_of_export_cables"]
        );

        setLoadedScenario((loadedScenario) => ({
          ...loadedScenario,
          "site:exports": exports,
        }));
      } else if (
        (loadedScenario["site:number_of_export_cables"] <
          loadedScenario["site:exports"].length &&
          loadedScenario["site:number_of_export_cables"] !== 0) ||
        endPoints.length !==
          loadedScenario["site:exports"][0]["End 1"].options.cellEditorParams
            .values.length ||
        JSON.stringify(cables) !==
          JSON.stringify(
            loadedScenario["site:exports"][0]["carousel"].options
              .cellEditorParams.values
          )
      ) {
        const exports = generateTable(
          loadedScenario["site:number_of_export_cables"]
        );

        setLoadedScenario((loadedScenario) => ({
          ...loadedScenario,
          "site:exports": exports,
        }));
      }
    }
  }, [
    loadedScenario["site:number_of_HDD"],
    loadedScenario["site:number_of_OSP"],
    loadedScenario["site:number_of_export_cables"],
    loadedScenario["vessels:primary_carousel_cable"],
    loadedScenario["vessels:secondary_carousel_cable"],
  ]);

  const formatCreateInput = (names, spec = null) => {
    const formatted = [];

    for (let i = 0; i < names.length; i++) {
      formatted.push({
        value: names[i],
        label: prettierName(names[i]),
        spec: spec,
      });
    }

    return formatted;
  };

  const formattedData = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "-" + mm + "-" + yyyy;
  };

  const handleAction = (action, scenario) => {
    switch (action) {
      case "Close":
        handleClose();
        break;
      case "Save":
        if (user) {
          uploadScenario(loadedScenario, user);
        }
        break;
      case "Apply to All":
        const defaultSettings = Object.keys(bpGraph.get_defaults()).filter(
          (item) => item.split(":")[0] === "vessels"
        );
        const settings = Object.keys(loadedScenario).filter(
          (item) => item.split(":")[0] === "vessels"
        );
        const temp = { ...batchData };

        Object.keys(temp).forEach((element) => {
          for (let i = 0; i < settings.length; i++) {
            if (scenario[settings[i]] !== defaultSettings[settings[i]]) {
              temp[element].scenario[settings[i]] = loadedScenario[settings[i]];
            }
          }

          handleAddScenario(
            temp[element].scenario,
            setBatchData,
            setBatchSummary
          );
        });
        break;
      case "Apply":
        const tempBatch = { ...batchData };
        // console.log({ loadedScenario });

        tempBatch[loadedScenario.batchId].scenario = loadedScenario;
        break;
      default:
        // console.log(action);
        break;
    }
  };

  const getVesselData = async (value) => {
    let caraData = await getVesselByName("vekta", value.replaceAll(" ", "_"));

    return {
      "vessels:laden_speed": caraData.cruising_speed,
      "vessels:unladen_speed": caraData.max_speed,
    };
  };

  const handleUpdateScenario = async (e, input, type = null) => {
    let value = e.value;
    if (type === "number") value = Number(value);

    let vesselSpeeds = {};
    if (input === "vessels:selected_vessel") {
      vesselSpeeds = await getVesselData(value);
    }

    const scenario = {
      ...loadedScenario,
      [input]: value,
      ...(input === "vessels:primary_carousel_cable"
        ? { "vessels:secondary_carousel_cable": value }
        : {}),
      ...vesselSpeeds,
    };

    setLoadedScenario(scenario);
  };

  /**
   *
   * Input row render. Generates the input based on the blueprint.
   *
   */
  const renderScenarioRow = (input) => {
    const handleSelectOptions = (options) => {
      if (input.includes("carousel_cable")) {
        return formatCreateInput(cableCollection.sort(), input);
      }

      if (input === "vessels:selected_vessel") {
        return formatCreateInput(vesselCollection.sort(), input);
      }

      return formatCreateInput(options, input);
    };

    const checkDate = () => {
      const date = dayjs(
        loadedScenario[input] === "today"
          ? formattedData()
          : loadedScenario[input],
        "DD/MM/YYYY"
      );

      if (loadedScenario[input] === "today") {
        // console.log({ input, date });
        handleUpdateScenario(
          // { value: value }, // Format it back to dd/MM/yyyy
          { value: date.format("DD/MM/YYYY") }, // Format it back to dd/MM/yyyy
          input
        );
      }

      return date;
    };

    //Custom search filter so that it only returns the cable that matches the search string.
    const customFilter = (option, searchText) => {
      searchText = searchText.split(" ");

      if (
        searchText.every((el) =>
          option.data.label.toLowerCase().includes(el.toLowerCase())
        ) ||
        searchText.every((el) =>
          option.data.value.toLowerCase().includes(el.toLowerCase())
        )
      ) {
        return true;
      } else {
        return false;
      }
      // }
    };

    const checkCondition = (condition) => {
      let conditionMet = true;
      if (condition) {
        conditionMet = false;

        Object.keys(condition).map((key) => {
          if (
            loadedScenario[key] === condition[key] ||
            condition[key].indexOf(loadedScenario[key]) > -1
          ) {
            conditionMet = true;
          }
        });
      }

      return conditionMet;
    };

    const requires =
      Object.keys(bpGraph.bp_graph[input]?.requires).length > 0
        ? checkCondition(bpGraph.bp_graph[input]?.requires)
        : true;
    const unit = bpGraph.bp_graph[input].hasOwnProperty("unit")
      ? bpGraph.bp_graph[input].unit.value
      : false;
    const placeholder = loadedScenario[input]
      ? prettierName(loadedScenario[input])
      : `Default - ${prettierName(bpGraph.bp_graph[input].default)}`;
    const min_max = {
      min: bpGraph.bp_graph[input].min,
      max: bpGraph.bp_graph[input].max,
    };

    const has_min_max = min_max.min !== undefined && min_max.max !== undefined;
    const text_input_default = has_min_max
      ? `min-${min_max.min} max-${min_max.max}`
      : "Enter Value . . .";
    const options = bpGraph.bp_graph[input].options || [];
    let customStyles = {};

    return (
      !bpGraph.bp_graph[input].disabled &&
      requires && (
        <CableRow className="cableRow">
          {bpGraph.bp_graph[input].type !== "table" && (
            <SpanTitle>
              {bpGraph.bp_graph[input].name
                ? bpGraph.bp_graph[input].name
                : prettierName(input)}
            </SpanTitle>
          )}
          {editCable && bpGraph.bp_graph[input].type !== "table" ? (
            <InputText
              readOnly
              id={input}
              disabled={false}
              placeholder={placeholder}
              style={inputStyle}
            />
          ) : options.length > 0 ? (
            <Select
              className="cableSelect"
              classNamePrefix="select"
              placeholder={placeholder}
              // isClearable
              isSearchable
              // styles={customStyles}
              isDisabled={false} // used to be editCable handled
              value={loadedScenario[input]}
              // defaultValue={default_value}
              menuPortalTarget={document.getElementById("scenarioModal")}
              menuPosition="fixed"
              onChange={(e) => handleUpdateScenario(e, input)}
              options={handleSelectOptions(options)}
              filterOption={customFilter}
            />
          ) : bpGraph.bp_graph[input].type === "text" ? (
            <>
              <InputText
                id={input}
                disabled={false}
                defaultValue={
                  modalName.includes("Settings") && loadedScenario[input]
                }
                value={
                  !modalName.includes("Settings")
                    ? prettierName(loadedScenario[input])
                    : loadedScenario[input]
                }
                placeholder={text_input_default}
                onChange={(e) =>
                  handleUpdateScenario(
                    e.target,
                    input,
                    bpGraph.bp_graph[input].type
                  )
                }
                style={inputStyle}
              />
            </>
          ) : bpGraph.bp_graph[input].type === "number" ? (
            <div className="number-input">
              <InputNumber
                id={input}
                disabled={false}
                min={bpGraph.bp_graph[input].min}
                max={bpGraph.bp_graph[input]?.max || null}
                defaultValue={
                  modalName.includes("Settings") && loadedScenario[input]
                }
                value={
                  !modalName.includes("Settings")
                    ? prettierName(loadedScenario[input])
                    : loadedScenario[input]
                }
                placeholder={text_input_default}
                onChange={(e) => {
                  console.log(e);

                  handleUpdateScenario(e, input, bpGraph.bp_graph[input].type);
                }}
                style={{
                  width: "100%",
                  height: "3rem",
                  fontFamily: "Montserrat",
                  fontWeight: "medium",
                  borderRadius: "4px",
                  color: "hsl(0, 0%, 20%)",
                }}
              />
            </div>
          ) : bpGraph.bp_graph[input].type === "table" ? (
            <div id="table-edits" style={{ width: "100%" }}>
              <header style={{ display: "flex", gap: "8px" }}>
                <h4>{bpGraph.bp_graph[input].name}</h4>
                <StyledIconButton onClick={(e) => editTables.current.toggle(e)}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </StyledIconButton>
                <OverlayPanel
                  ref={editTables}
                  id="editTables"
                  style={{
                    padding: "8px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <div>
                    <h3>Edited Table</h3>
                    <img width={"80%"} src={EditTable} />
                    <p style={{ marginTop: "16px" }}>
                      Edit tables the same way they can be editing in Excel -
                      <br></br>{" "}
                      <strong>Double click the cell you wish to edit</strong>
                    </p>
                  </div>
                </OverlayPanel>
              </header>
              <GenericTable
                tableInfo={loadedScenario[input]}
                input={input}
                pagination={true}
                order={bpGraph.bp_graph[input].order}
                handleUpdateScenario={handleUpdateScenario}
                edit={editCable}
              />
            </div>
          ) : bpGraph.bp_graph[input].type === "date" ? (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                sx={{
                  width: "55%",
                  ".MuiInputBase-input": {
                    fontFamily: "Montserrat !important",
                  },
                }}
                format="DD/MM/YYYY" // Update format to Day.js compliant
                // minDate={dayjs("2018-01-01")}
                // maxDate={dayjs("3000-01-01")}
                value={checkDate()} // Parse loaded scenario as 'YYYY-MM-DD'
                onChange={(value) => {
                  if (value) {
                    // console.log(value);

                    handleUpdateScenario(
                      // { value: value }, // Format it back to dd/MM/yyyy
                      { value: value.format("DD/MM/YYYY") }, // Format it back to dd/MM/yyyy
                      input
                    );
                  }
                }}
                views={["day", "month", "year"]}
              />
            </LocalizationProvider>
          ) : null}
          {unit ? (
            typeof unit === "string" ? (
              <SpanTitle
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                {unit}
              </SpanTitle>
            ) : checkCondition(bpGraph.bp_graph[input].unit.condition) ? (
              <PrimeButton
                disabled={editCable}
                style={{
                  width: "10%",
                  height: "3rem",
                  padding: "0",
                }}
              >
                {unit}
              </PrimeButton>
            ) : (
              <div style={{ width: "10%" }}></div>
            )
          ) : bpGraph.bp_graph[input].type !== "table" ? (
            <div style={{ width: "10%" }}></div>
          ) : null}
        </CableRow>
      )
    );
  };

  const renderScenarioGroup = (inputs) => {
    const groups = bpGraph.prefixes;

    // console.log({ inputs });

    return groups.map((group, index) => {
      // if (!modalName.includes("Settings")) {
      return (
        <div
          style={
            stepIndex === index || modalName.includes("Settings")
              ? {
                  marginRight: "8px",
                  border: "2px solid #00a0c6",
                  borderRadius: "8px",
                }
              : { display: "none" }
          }
        >
          <CableSpecHeader>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ color: "white" }}>
                {(group.charAt(0).toUpperCase() + group.slice(1)).replace(
                  "_",
                  " "
                )}
              </h4>
            </div>
          </CableSpecHeader>

          <div style={{ overflow: "scroll" }}>
            {Object.keys(inputs).map((input) => {
              return input.split(":")[0] === group && renderScenarioRow(input);
            })}
          </div>
        </div>
      );
    });
  };

  const handleGetScenarioInfo = async () => {
    if (stepIndex === 1) {
      const tempBatch = await handleAddScenario(
        loadedScenario,
        setBatchData,
        setBatchSummary,
        false
      );

      setReview(tempBatch);
    }

    setStepIndex(stepIndex + 1);
  };

  return (
    <>
      {/* <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps[tutorial]}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#00a0c6",
            zIndex: 10000,
          },
        }}
      /> */}
      <div
        className="cableModal"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          id="scenarioModal"
          sx={{
            display: "grid",
            gridTemplateColumns:
              "repeat(2, 1fr) repeat(2, 0.5fr) repeat(2, 1fr)",
            gridTemplateRows:
              "minmax(0, 0.25fr) repeat(3, minmax(0, 1fr)) minmax(0, 0.15fr)",
            gridColumnGap: "0px",
            gridRowGap: "0px",
            // display: "flex",
            // flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            // maxHeight: "90vh",
            position: "fixed",
            inset: !modalName.includes("Settings") ? "5% 5%" : "10% 20%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <header style={{ gridArea: "1 / 1 / 2 / 7" }}>
            <h2>{modalName}</h2>
            <Divider
              style={{ marginBottom: "16px" }}
              sx={{ bgcolor: "black" }}
            />
            <CloseModal onClick={handleClose}>
              <StyledIconButton>
                <CloseIcon />
              </StyledIconButton>
            </CloseModal>
          </header>

          <div
            style={{
              gridArea: !modalName.includes("Settings")
                ? "2 / 1 / 5 / 4"
                : "2 / 1 / 5 / 7",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "scroll",
            }}
          >
            <div>
              {!modalName.includes("Settings")
                ? renderScenarioGroup(loadedScenario)
                : renderScenarioGroup(bpGraph.get_settings())}
            </div>
          </div>

          {!modalName.includes("Settings") && (
            <div style={{ gridArea: "2 / 4 / 5 / 7" }}>
              {stepIndex === 0 && (
                <div
                  style={{
                    height: "100%",
                    overflowY: "scroll",
                    padding: "0 8px",
                  }}
                >
                  <CableSummary
                    setCaraCable={setCaraCable}
                    selectedCable={{
                      main: loadedScenario["vessels:primary_carousel_cable"],
                      below: loadedScenario["vessels:secondary_carousel_cable"],
                    }}
                    user={user}
                    loadedScenario={loadedScenario}
                    setLoadedScenario={setLoadedScenario}
                  />

                  <VesselSummary
                    title={<h4>Vessel Capacities</h4>}
                    cables={[
                      loadedScenario["vessels:primary_carousel_cable"],
                      loadedScenario["vessels:secondary_carousel_cable"],
                    ]}
                    capacities={vesselCap}
                    vessel={loadedScenario["vessels:selected_vessel"]}
                    style={{ height: "300px", colour: "#00a0c6" }}
                  />
                </div>
              )}
              {stepIndex === 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "scroll",
                  }}
                >
                  <Map
                    weatherPoint={weatherPoint}
                    setWeatherPoint={setWeatherPoint}
                    loadedScenario={loadedScenario}
                    setLoadedScenario={setLoadedScenario}
                  />
                </div>
              )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              gridArea: "5 / 1 / 6 / 7",
            }}
          >
            {!modalName.includes("Settings") && stepIndex !== 0 && (
              <SecButton
                disabled={stepIndex === 0}
                onClick={() => setStepIndex(stepIndex - 1)}
              >
                Prev
              </SecButton>
            )}
            {!modalName.includes("Settings") &&
              stepIndex !== bpGraph.prefixes.length - 1 && (
                <PrimeButton
                  disabled={stepIndex === bpGraph.prefixes.length - 1}
                  onClick={handleGetScenarioInfo}
                >
                  Next
                </PrimeButton>
              )}
          </div>

          <footer
            style={{
              gridArea: "5 / 6 / 6 / 7",
            }}
          >
            {type !== "Apply to All" && type !== "Apply" && (
              <SecButton
                className="editAction"
                onClick={() => {
                  setEditCable(!editCable);
                  if (editCable) {
                    setCableAction("Save");
                  } else {
                    setCableAction("Close");
                  }
                }}
              >
                {editCable ? "Edit" : "View"}
              </SecButton>
            )}
            {/* {stepIndex > 0 && ( */}
            <PrimeButton
              // style={{ width: "fit-content" }}
              onClick={() => {
                handleClose();
                handleAction(cableAction, loadedScenario);
              }}
            >
              {cableAction}
            </PrimeButton>
            {/* )} */}
          </footer>
        </Box>

        <Modal
          open={vesselOperations}
          onClose={() => setVesselOperations(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <VesselOperations
            loadedScenario={loadedScenario}
            bpGraph={bpGraph.bp_graph}
            setVesselOperations={setVesselOperations}
            handleUpdateScenario={handleUpdateScenario}
          />
        </Modal>

        <Modal
          open={caraInfo}
          onClose={() => setCaraInfo(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <CarouselSummary
            loadedScenario={loadedScenario}
            // bpGraph={bpGraph.bp_graph}
            setCaraInfo={setCaraInfo}
          />
        </Modal>
      </div>
    </>
  );
}

export default BpModal;
