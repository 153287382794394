import React, { useEffect } from "react";
import StickyMenu from "../lib/StickyMenu.js";
import Navigation from "../Navigation.jsx";

import VDLogo from "../../../media/vektacables_logo.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase.js";
import { PrimeButton } from "../../../styles/styles.js";

function HeaderHomeSix({
  action,
  setPage,
  setLoginSelected,
  homePage,
  setHomePage,
}) {
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    // StickyMenu();
  });
  return (
    <>
      <header
        className="appie-header-area appie-sticky pb-10 sticky"
        // style={{ paddingTop: "0" }}
      >
        {/* <span
          style={{
            // position: "fixed",
            // zIndex: "10000",
            background: "#00a0c657",
            // height: "24px",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "600",
            letterSpacing: "0.1em",
            padding: "8px",
            width: "100%",
            textAlign: "center",
            marginBottom: "8px",
          }}
          // pauseOnHover
        >
          <a
            href="https://giveaway.vektagroup.com/anniversary"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "black",
              textTransform: "uppercase",
            }}
          >
            Enter our 5th anniversary giveaway and win a year's subscription to
            Vekta Cables or Vekta Digital+
          </a>
        </span> */}
        <div className="container">
          <div className="header-nav-box header-nav-box-6">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                <div className="appie-logo-box">
                  <a
                    href="#"
                    onClick={() => {
                      setPage("home");
                      document.getElementById("landing-page").scrollTop = 0;
                    }}
                  >
                    <img src={VDLogo} alt="Vekta Digital Logo" width={50} />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                <div className="appie-header-main-menu">
                  <Navigation setPage={setPage} />
                </div>
              </div>
              <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                <div
                  className="appie-btn-box text-right justify-content-end"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {!user ? (
                    <a
                      className="login-btn"
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      href="#"
                      onClick={() => {
                        console.log("set login to true and open popup");
                        setLoginSelected(true);
                      }}
                    >
                      <i className="fa-solid fa-user"></i>LOGIN
                    </a>
                  ) : (
                    <a
                      className="login-btn"
                      href="#"
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        auth.signOut();
                      }}
                    >
                      <i className="fa-solid fa-user"></i>SIGN OUT
                    </a>
                  )}

                  {/* <PrimeButton style={{ width: "fit-content" }}>
                    Request a demo
                  </PrimeButton> */}
                  {homePage ? (
                    <a
                      className="main-btn ml-30"
                      href="#"
                      onClick={() => setHomePage(false)}
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      LAUNCH TOOL
                    </a>
                  ) : (
                    <a
                      className="main-btn ml-30"
                      href="#"
                      onClick={() => {
                        setPage("contact");
                        document.getElementById("landing-page").scrollTop = 0;
                      }}
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      REQUEST A DEMO
                    </a>
                  )}

                  <div
                    onClick={(e) => action(e)}
                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                  >
                    <i className="fa fa-bars"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeSix;
