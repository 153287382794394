import React from "react";
// import { Link } from "react-router-dom";

function Navigation({ setPage }) {
  return (
    <>
      <ul id="nav">
        {/* <li>
          <a style={{ cursor: "pointer" }} onClick={() => setPage("home")}>
            Home
          </a>
        </li> */}
        {/* <li>
          <a
            style={{ cursor: "pointer", fontSize: "14px", fontWeight: "600" }}
            onClick={() => setPage("insights")}
          >
            Insights
          </a>
        </li> */}
        <li>
          <a
            style={{ cursor: "pointer", fontSize: "14px", fontWeight: "600" }}
            onClick={() => {
              setPage("product-updates");
              document.getElementById("landing-page").scrollTop = 0;
            }}
          >
            PRODUCT UPDATES
          </a>
          {/* <a href="#">Product Updates</a> */}
          {/* <ul className="sub-menu">
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/about-us-another">About 2</Link>
            </li>
            <li>
              <Link to="/error">Error</Link>
            </li>
            <li>
              <Link to="/shops">Shops</Link>
            </li>
            <li>
              <Link to="/shops/shop-details">Shop details</Link>
            </li>
          </ul> */}
        </li>
        <li>
          <a
            style={{ cursor: "pointer", fontSize: "14px", fontWeight: "600" }}
            onClick={() => {
              setPage("contact");
              document.getElementById("landing-page").scrollTop = 0;
            }}
          >
            CONTACT
          </a>
        </li>
        <li>
          <a
            style={{ cursor: "pointer", fontSize: "14px", fontWeight: "600" }}
            onClick={() => {
              setPage("education");
              document.getElementById("landing-page").scrollTop = 0;
            }}
          >
            EDUCATION
          </a>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
