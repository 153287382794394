import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { app, auth, db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getCablesTools = async () => {
  // console.log("Get tools from the store");
  const allTools = {};
  const colRef = collection(db, "products");

  try {
    getDocs(colRef).then((col) => {
      col.forEach(async (products) => {
        // This gets the products from stripe
        if (
          products.data().name.includes("- Annually") ||
          products.data().name.includes("- Quarterly")
        ) {
          const priceRef = collection(colRef, products.id, "prices");
          getDocs(priceRef).then((prices) => {
            // This gets all the pricing information for the product
            const allPrices = { year: null, month: null };
            prices.forEach((price) => {
              let priceData = price.data();
              // console.log(priceData);
              if (priceData.active) {
                priceData["id"] = price.id;
                allPrices[priceData.interval] = priceData;
              }
            });

            var temp = products.data();
            temp["id"] = products.id;

            // console.log(temp);

            allTools[products.id] = {
              info: temp,
              prices: allPrices,
            };
          });
        }
      });

      // console.log({ allTools });
      return allTools;
    });
  } catch (error) {
    // console.log(error);
  }

  // console.log(allTools);
  return allTools;
};

export const getCheckoutUrl = async (priceId, trial) => {
  const userId = auth.currentUser?.uid;
  let firstTrial = true;
  let continuePurchase = true;

  if (!userId) throw new Error("User is not authenticated");

  const checkoutSessionRef = collection(
    db,
    "customers",
    userId,
    "checkout_sessions"
  );

  if (trial) {
    const trial_used = (
      await getDoc(doc(db, "accessControl", "trial_used"))
    ).data().used;

    trial_used.forEach((email) => {
      if (auth.currentUser.email === email) firstTrial = false;
    });

    if (!firstTrial)
      continuePurchase = window.confirm(
        "A free trial has already been used with your login credentials, continue to go to purchase page"
      );
  }

  if (continuePurchase) {
    var document;
    document = {
      currency: "gbp",
      success_url: window.location.href,
      cancel_url: window.location.href,
      automatic_tax: true,
      ...(trial && firstTrial && { trial_period_days: 14 }),
      // trial_settings: {
      //     end_behavior: {
      //         missing_payment_method: 'cancel',
      //     },
      // },
      payment_method_collection: "if_required",
      mode: "subscription",
      //   price: priceId,
      line_items: [
        ...priceId.map((id) => ({ price: id, quantity: 1 })),
        ...(trial && firstTrial
          ? [
              {
                price: "price_1Qrem4IowKhyLz4fa7LMriXu",
                quantity: 1,
              },
            ]
          : []),
      ],
    };

    const docRef = await addDoc(checkoutSessionRef, document);

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const url = snap.data();
        // console.log(snap.data());
        if (url.error) {
          unsubscribe();
          reject(new Error(`An error occurred: ${url.error.message}`));
        }

        if (url.url) {
          // console.log("Stripe Checkout URL:", url);
          unsubscribe();
          resolve(url);
        }
      });
    });
  }

  return { url: window.location.href };
};

export const getPortalUrl = async () => {
  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = getFunctions(app, "europe-central2");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-pgda-createPortalLink"
    );

    // https://billing.stripe.com/p/login/test_28o3d9boOdYu4PC8ww

    const { data } = await functionRef({
      returnUrl: window.location.origin,
      //   flow_data: {
      //     type: "payment_method_update",
      //     after_completion: {
      //       type: "hosted_confirmation",
      //       hosted_confirmation: "Change successful made",
      //     },
      //   },
      // features: {
      //     customer_update: {
      //         allowed_updates: ['email', 'address', "name"],
      //         enabled: true,
      //     },
      //     invoice_history: {
      //         enabled: true,
      //     },
      //     payment_method_update: {
      //         enabled: true,
      //     },
      //     subscription_cancel: {
      //         enabled: true,
      //         cancellation_reason: {
      //             enabled: true,
      //             options: ["customer_service", "low_quality", "missing_features", "too_complex", "too_expensive", "unused", "switched_service", "other"]
      //         }
      //     },
      //     subscription_update: {
      //         enabled: false,
      //     },
      // },
    });

    // Add a type to the data
    dataWithUrl = data;
  } catch (error) {
    console.error(error);
    auth.signOut();
  }

  return new Promise((resolve, reject) => {
    // console.log(dataWithUrl);
    if (dataWithUrl.url) {
      // console.log(dataWithUrl.url);
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

export const getUserInfo = async (uid) => {
  try {
    const userInfo = await getDoc(doc(db, "user_accounts", uid));

    return new Promise((resolve, reject) => {
      // console.log(userInfo);
      if (userInfo) {
        resolve(userInfo.data());
      } else {
        reject(new Error("No user info found"));
      }
    });
  } catch (error) {
    console.error(error);
  }
};
