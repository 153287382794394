import getSymbolFromCurrency from "currency-symbol-map";

export const prettierName = (name) => {
  // make sure name is a string
  if (typeof name === "number") {
    return name;
  }

  if (typeof name === "object") {
    let newName = "";

    Object.keys(name).map((key) => {
      newName = newName + key + ":" + name[key] + ", ";
    });

    return newName;
  }

  // replace : and _ with space
  // remove metadata and settings and design from name
  name = name.replace(/metadata./g, "");
  name = name.replace(/settings./g, "");
  name = name.replace(/design./g, "");
  name = name.replace(/environment./g, "");
  name = name.replace(/cable./g, "");
  name = name.replace(/vessels/g, "");
  name = name.replace(/vessel_ops./g, "");
  name = name.replace(/review./g, "");
  // name = name.replace(/vessel_type./g, "");
  name = name.replace(/site./g, "");
  name = name.replace(/financial./g, "");
  name = name.replace(/[:_]/g, " ");

  return name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
};

export function formatCableName(name) {
  if (name.includes("DC Cable")) {
    name = name.replace("DC Cable", "DC");
  }
  if (name.includes("Armoured")) {
    name = name.replace("Armoured", "AR");
  }
  if (name.includes("with lead sheath")) {
    name = name.replace("with lead sheath", "SCRN");
  }
  return name;
}

export const prettierFormatCableName = (name) => {
  // make sure name is a string
  if (typeof name !== "string") {
    return name;
  }

  // replace : and _ with space
  // remove metadata and settings and design from name
  name = name.replace(/metadata./g, "");
  name = name.replace(/settings./g, "");
  name = name.replace(/design./g, "");
  name = name.replace(/environment./g, "");
  name = name.replace(/cable./g, "");
  name = name.replace(/financial./g, "");
  name = name.replace(/[:_]/g, " ");

  let formatted;
  if (name.toLowerCase().startsWith("dc")) {
    formatted =
      name.charAt(0).toUpperCase() +
      name.charAt(1).toUpperCase() +
      name.slice(2);
  } else {
    formatted = name.charAt(0).toUpperCase() + name.slice(1);
  }

  if (formatted.includes("DC Cable")) {
    formatted = formatted.replace("DC Cable", "DC");
  }
  if (formatted.includes("Armoured")) {
    formatted = formatted.replace("Armoured", "AR");
  }
  if (formatted.includes("with lead sheath")) {
    formatted = formatted.replace("with lead sheath", "SCRN");
  }
  return formatted;
};

// export const toNormalDecimal = (val, key) => {
//     if (!val || val === "undefined") return "N/A"
//     // Helper function to convert a number to scientific notation if necessary with 2 decimal places
//     const convertToScientificNotation = (num) => {
//         if (Math.abs(num) >= 10000 || Math.abs(num) < 0.0001) {
//             return num.toExponential(2);
//         }
//         return num.toFixed(2);
//     };

//     if (typeof val === "string") {
//         // if val is an imaginary number
//         if (val.includes("+")) {
//             const splitVal = val.split("+");
//             const realPart = parseFloat(splitVal[0]);
//             const imaginaryPart = parseFloat(splitVal[1].split("j")[0]);

//             return convertToScientificNotation(realPart) + "+" + convertToScientificNotation(imaginaryPart) + "j";
//         }
//         return val.replace(/_/g, ' ');
//     } else if (typeof val === "number") {
//         return convertToScientificNotation(val);
//     }

//     // If the value is neither string nor number, return it as is
//     return val;
// };

export const toNormalDecimal = (val, key, units) => {
  //   // console.log({ val, key, units });

  if (!val || val === "undefined") return "N/A";

  const formatUnits = (unit) => {
    let formatted = getSymbolFromCurrency(unit);

    if (!formatted) {
      if (
        unit !== "string" &&
        unit !== "Str" &&
        unit !== "-" &&
        unit !== "integer"
      ) {
        formatted = unit;
      } else {
        formatted = "";
      }
    }
    return formatted;
  };

  if (typeof val === "string") {
    if (val.includes("from " | "up to ")) {
      return val + "_" + formatUnits(units);
    }

    // if val is an imaginary number
    if (val.includes("+")) {
      const splitVal = val.split("+");
      const realPart = parseFloat(splitVal[0]);
      const imaginaryPart = parseFloat(splitVal[1].split("j")[0]);

      // return convertToScientificNotation(realPart, null) + "+" + convertToScientificNotation(imaginaryPart, null) + "j " + formatUnits(units);
      return (
        realPart.toExponential(2) +
        "+" +
        imaginaryPart.toExponential(2) +
        "j_" +
        formatUnits(units)
      );
    }

    return `${String(val.replace(/_/g, " ")).charAt(0).toUpperCase()}${String(
      val.replace(/_/g, " ")
    ).slice(1)}_${formatUnits(units)}`;
  }
  return val.toFixed(2) + "_" + formatUnits(units);
};
