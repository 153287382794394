import React, { useEffect, useState } from "react";
import { Page } from "./ReportStyles";
import styled from "styled-components";
import section_url from "./Section.png";
import map from "./map.png";
import threeCore from "./three-core-cable.png";

const Front = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
const HeaderBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-height: 120px;
  //max-height: 120px;
  width: 100%;
  background-color: ${(props) => props.bgColor};

  box-sizing: border-box;
`;
const LogoBox = styled.div`
  width: 130px;
  height: 130px;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
`;
const Logo = styled.img`
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  object-fit: cover;

  /* aspect-ratio: 1; */
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 415px;
  padding: 18px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  font-size: 28px;
  padding-bottom: 8px;
  box-sizing: border-box;
  text-align: left;
  color: black;
  font-weight: 800;
`;
const HeaderName = styled.div`
  text-align: left;
  font-size: 16px;
  color: black;
`;
const TitleGap = styled.div`
  height: 3px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
  margin-left: 20px;
  margin-right: 20px;
  /* padding-right:20px; */
`;

const TopRightDetails = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;

  width: 270px;
  padding: 20px;
  /* max-height: 80px;
  min-height: 80px; */
  gap: 10px;
`;

const TitleBar = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
`;

const TitleName = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  display: flex; // Set the display to flex
  align-items: center; // Vertically center the content
  text-align: center;
  justify-content: center;
  color: white;
`;
const TitleRight = styled.div`
  width: 100%;
  opacity: 40%;
  background-color: ${(props) => props.bgColor};
`;

const Body = styled.div`
  /* height: calc(100% - 120px - 48px - 120px); */
  box-sizing: border-box;
  width: 731px;
  margin: 24px auto;

  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  position: relative;
`;

const Section = styled.img`
  position: absolute;
  top: 0;
  right: -200px;
  width: 700px;
  height: 95%;
`;

const DetailsColumn = styled.div`
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: right;
  padding: 20px;
`;
const Detail = styled.div`
  display: flex;
  /* width:200px */
`;
const DetailName = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: black;
`;
const DetailValue = styled.div`
  font-size: 13px;
  color: black;
  padding-left: 10px;
`;

const FooterBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 120px;
  min-height: 120px;
  box-sizing: border-box;
`;
const FooterLeftBox = styled.div`
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  display: flex;
  width: 200px;
`;
const FooterMiddleBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0; // Make sure no padding is interfering
`;

const FooterRightBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 0; // Make sure no padding is interfering
`;
const DocDetailName = styled.div`
  display: flex;
  flex-direction: column; // Stack the children vertically
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  height: 40px;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  padding-left: 8px;
  font-weight: 500;
  color: white;
`;
const DocDetailValue = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column; // Stack the children vertically
  align-items: flex-start; // Align children to the right: ;
  width: 100%; // Ensure it takes full width of parent
  box-sizing: border-box;
  text-align: left;
  justify-content: center;
  padding-left: 20px;
  border-radius: 0px 8px 8px 0;
  font-weight: 800;
  background-color: ${(props) => props.bgColor};
`;

const MiddleShape = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: ${(props) => props.bgColor};
`;

const MiddleShape2 = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(-100%, 0) translate(20px, 0) rotate(27.5deg) scaleY(2);
  transform-origin: bottom right;
  background-color: white;
`;

function SecondPage({ logo, settings }) {
  const [color1, setColor1] = useState(settings.primaryColor);
  const [color2, setColor2] = useState(settings.secondaryColor);
  const [color3, setColor3] = useState(settings.tertiaryColor);

  useEffect(() => {
    setColor1(settings.primaryColor);
    setColor2(settings.secondaryColor);
    setColor3(settings.tertiaryColor);
  }, [settings.primaryColor, settings.secondaryColor, settings.tertiaryColor]);

  useEffect(() => {
    // console.log(settings);
  }, [settings]);

  return (
    <Page>
      <Front>
        <HeaderBox bgColor={"white"}>
          <LogoBox>
            <Logo src={logo.data ? logo.data : ""} alt="Company Logo" />
          </LogoBox>
          <Header>
            <HeaderTitle>{settings["Project"].value}</HeaderTitle>
            {/* <HeaderName>Powered by VEKTA GROUP</HeaderName> */}
          </Header>
          <TopRightDetails>
            <Detail>
              <DetailName>Revision:</DetailName>
              <DetailValue>{settings["Revision"].value}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Document No:</DetailName>
              <DetailValue>{settings["Document Number"].value}</DetailValue>
            </Detail>
            <Detail>
              <DetailName>Issue Date:</DetailName>
              <DetailValue>{settings["Date"].value}</DetailValue>
            </Detail>
            {/* <Detail>
              <DetailName>Powered by VEKTA GROUP</DetailName>
            </Detail> */}
          </TopRightDetails>
        </HeaderBox>
        <TitleGap bgColor={settings.primaryColor} />
        <Body>
          <p style={{ color: "black" }}>
            Installation costs, programme sequence and vessel operational
            conditions are calculated for all cables using generic installation
            vessel specifications and cable mechanical data is validated using
            IEEE 1185. Testing and validation of the metocean data is done using
            the ECMWF ERA5 reanalysis and GIS information is cross checked
            against the Renewable UK global offshore wind project database.
            Unless otherwise stated the following defaults are used for the
            cable installation conditions:
          </p>

          <div style={{ marginLeft: "40px", display: "flex", gap: "16px" }}>
            <ul style={{ width: "50%" }}>
              <li style={{ listStyleType: "disc" }}>
                Maximum sidewall pressure: up to 4.38 kN/m
              </li>
              <li style={{ listStyleType: "disc" }}>
                Simultaneous cable lay and bury method
              </li>
              <li style={{ listStyleType: "disc" }}>
                Default weather downtime: P50
              </li>
              <li style={{ listStyleType: "disc" }}>
                ERA5 data points use 0.5 degrees grid
              </li>
            </ul>
            <ul style={{ width: "45%" }}>
              <li style={{ listStyleType: "disc" }}>
                Cable lengths are approximate
              </li>
              <li style={{ listStyleType: "disc" }}>
                Vessel operational constraints are subject to change and
                site-specific conditions
              </li>

              <li style={{ listStyleType: "disc" }}>
                Final cable sizes are subject to FEED
              </li>
            </ul>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                border: `2px solid ${settings.primaryColor}`,
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <img
                src={"./media/carousel_diagrams/2-Carousel_option2.png"}
                width={500}
              />
              <span>
                <i>Figure 1 - Example carousel system </i>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                border: `2px solid ${settings.primaryColor}`,
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <img src={map} width={500} />
              <span>
                <i>
                  Figure 2 - Example of GIS data used for installation project
                </i>
              </span>
            </div>
          </div>
        </Body>
      </Front>
    </Page>
  );
}

export default SecondPage;
