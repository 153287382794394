import React from "react";
import { Button } from "@mui/material";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  border-top: 1px solid lightgray;
`;

const LoadSaveButtons = ({ handleSave, handleLoad }) => {
  return (
    <ButtonContainer>
      {/* <Button variant="text" color="primary" onClick={handleLoad}>
        Library
      </Button>
      <Button variant="text" color="primary" onClick={handleSave}>
        Save
      </Button> */}
    </ButtonContainer>
  );
};

export default LoadSaveButtons;
