import React, { useState } from "react";
// import { Link } from "react-router-dom";
import logo from "../../media/vektacables_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faHome, faPhone } from "@fortawesome/free-solid-svg-icons";

function Drawer({ drawer, action, setPage }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      setItem(value);
    }
  };
  return (
    <>
      <>
        <div
          onClick={(e) => action(e)}
          className={`off_canvars_overlay ${drawer ? "active" : ""}`}
        ></div>
        <div className="offcanvas_menu">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div
                  className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
                >
                  <div className="canvas_close">
                    <a href="#" onClick={(e) => action(e)}>
                      <i className="fa fa-times"></i>
                    </a>
                  </div>
                  <div className="offcanvas-brand text-center mb-40">
                    <img src={logo} alt="" width={130} />
                  </div>
                  <div id="menu" className="text-left ">
                    <ul className="offcanvas_main_menu">
                      {/* <li
                        onClick={(e) => {
                          action(e);
                          setPage("insights");
                        }}
                        id="service"
                        className="menu-item-has-children active"
                      >
                        <a href="#">INSIGHTS</a>
                      </li> */}
                      <li
                        onClick={(e) => {
                          action(e);
                          setPage("product-updates");
                        }}
                        id="service"
                        className="menu-item-has-children active"
                      >
                        <a href="#">PRODUCT UPDATES</a>
                      </li>
                      <li
                        onClick={(e) => {
                          action(e);
                          setPage("contact");
                        }}
                        id="contact"
                        className="menu-item-has-children active"
                      >
                        <a href="#">CONTACT</a>
                      </li>
                    </ul>
                  </div>

                  <div className="offcanvas-social">
                    <ul className="text-center">
                      <li>
                        <a
                          className="lin"
                          href="https://www.linkedin.com/company/vekta-group/posts/?feedView=all"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="$">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="$">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="$">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="footer-widget-info">
                    <ul>
                      <li>
                        <p>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{ marginRight: "8px" }}
                          />
                          help@vektagroup.com
                        </p>
                      </li>
                      <li>
                        <p>
                          <FontAwesomeIcon
                            icon={faPhone}
                            style={{ marginRight: "8px" }}
                          />
                          Coming Soon
                        </p>
                      </li>
                      <li>
                        <p>
                          <FontAwesomeIcon
                            icon={faHome}
                            style={{ marginRight: "8px" }}
                          />
                          7 Dundas Street, Edinburgh,
                          <br /> EH3 6QG
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default Drawer;
