import React from "react";
import heroThumb from "../assets/images/hero-thumb-7.png";
import { PrimeButton, SecButton } from "../../../styles/styles";
import ComputerDemo from "../../../media/demo/computerDemo.png";

function HeroHomeSix({ setPage, setLoginSelected }) {
  return (
    <>
      <section className="appie-hero-area appie-hero-6-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-thumb-6">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="1000ms"
                  data-wow-delay="600ms"
                >
                  <img src={ComputerDemo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-content appie-hero-content-6">
                <span>Effortless cable sourcing awaits with</span>
                <h1 className="appie-title">Vekta Cables</h1>
                <p>
                  Unlock cable RFQ excellence: Intelligent software delivers
                  comprehensive pricing and technical management solutions.
                </p>
                <ul>
                  <li>
                    <PrimeButton
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "0px",
                        padding: "10px 28px",
                        width: "fit-content",
                        fontSize: "13px",
                      }}
                      onClick={() =>
                        document
                          .getElementById("pricing-cards")
                          .scrollIntoView()
                      }
                    >
                      Start your,
                      <span>
                        <strong>FREE TRIAL</strong>
                      </span>
                    </PrimeButton>
                  </li>
                  <li>
                    <SecButton
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "0px",
                        padding: "10px 28px",
                        width: "fit-content",
                        fontSize: "13px",
                      }}
                      onClick={() => {
                        setPage("contact");
                        document.getElementById("landing-page").scrollTop = 0;
                      }}
                    >
                      Have a question?<br></br>
                      <strong>Contact us</strong>
                    </SecButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHomeSix;
