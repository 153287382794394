import React, { useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

function BatchSummaryTable({ batchSummary, setBatchSummarySelect }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = (row) => {
    const isSelected = selectedRows.includes(row.id);
    // console.log(batchSummary);
    const selectedName = batchSummary.find(item => item.id === row.id)?.Name;
    if (isSelected) {
        
        setBatchSummarySelect(selectedName);
        setSelectedRows(selectedRows.filter(id => id !== row.id));
    } 
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'Name' },
      { Header: 'Vessel', accessor: 'Vessel' },
      { Header: 'Start', accessor: 'Start' },
      { Header: 'Length', accessor: 'Length' }
    ],
    []
  );

  const data = React.useMemo(() => batchSummary, [batchSummary]) || [];

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.id)}
            onChange={() => handleRowSelection(row)}
          />
        )
      },
      ...columns
    ]);
  });

  if (!batchSummary || !batchSummary || batchSummary.length === 0) {
    return null; // Return null if siteExportSections or siteExportSections.data is null or empty
  }

  return (
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default BatchSummaryTable;

