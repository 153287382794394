import React, { useState } from "react";
import styled from "styled-components";
import { Switch, FormControlLabel, Modal } from "@mui/material";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import PdfForm from "../../../common/PdfForm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PrimeButton, SecButton } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import ContextMenu from "../../../common/ContextMenu";
import { quantile } from "simple-statistics";

import { BeatLoader } from "react-spinners";

import * as XLSX from "xlsx";

const BottomBarBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 3px solid red; */
  //position: absolute;
  /* //width: 100%; */
  //bottom: 0px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Invisible = styled.div`
  display: None;
`;

function BottomBar({
  previewPDF,
  setPreviewPDF,
  settings,
  setSettings,
  logo,
  setLogo,
  data,
}) {
  const [openStyles, setOpenStyles] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [loading, setLoading] = useState({ csv: false, pdf: false });
  const [pdfReady, setPdfReady] = useState(false); // State to check if the PDF is ready for preview

  const handleClose = () => {
    setOpenSettings(false);
    setOpenStyles(false);
  };

  const downloadPDF = () => {
    const reportContainer = document.getElementById("reportContainer");
    const pages = reportContainer.getElementsByClassName("pageClassName"); // Ensure your <Page> components have this class
    const canvasWidth = 794;
    // const canvasWidth = 595;
    const canvasHeight = 1122;
    // const canvasHeight = 842;
    const pdf = new jsPDF({
      orientation: canvasWidth > canvasHeight ? "landscape" : "portrait",
      unit: "pt",
      format: [canvasWidth, canvasHeight],
      compress: true,
    });

    const renderPage = (index) => {
      if (index < pages.length) {
        html2canvas(pages[index], {
          scale: 2,
          onclone: (document) => {
            Array.from(document.querySelectorAll("*")).forEach((e) => {
              let existingStyle = e.getAttribute("style") || "";
              e.setAttribute(
                "style",
                existingStyle + "; font-family: Calibri, sans-serif !important"
              );
            });
          },
        }).then((canvas) => {
          // Add page if it's not the first one
          if (index > 0) {
            pdf.addPage();
          }
          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            canvasWidth,
            canvasHeight,
            undefined,
            "FAST"
          );
          renderPage(index + 1); // Render next page
        });
      } else {
        // Save the PDF after all pages have been rendered
        pdf.save("cable-datasheet.pdf");
        setLoading((loading) => ({ ...loading, pdf: false }));
      }
    };

    renderPage(0); // Start rendering from the first page
  };

  const downloadCSV = async () => {
    const filtered = [
      "cable_name",
      "cable_type",
      "conductor_material",
      "conductor_size",
      "insulation_material",
      "system_voltage",
      "mass",
      "axial_stiffness",
      "max_allowable_pulling_tension_basket_grip",
      "max_allowable_sidewall_pressure",
      "min_bend_radius_drum",
      "min_bend_radius_installation",
    ];

    const processDelay = (resultData, percentile) => {
      const toProcess = [];
      const process = [];

      // Process the result data to just get just an array numbers
      for (let i = 0; i < resultData.data.length; i++) {
        const element = resultData.data[i];

        const quartileArray = Object.keys(element).map((key) => element[key]);
        quartileArray.pop(); // Remove the last value as its a row name not a value

        toProcess.push(quartileArray);
      }

      // Obtaining the quartile based on the percentile selected by the user.
      for (let i = 0; i < resultData.names.length; i++) {
        let checkVal = quantile(toProcess[i], percentile.value / 100) / 24; // Converting to hrs
        if (checkVal < 0) checkVal = 0;

        process.push({
          Parameter: resultData.names[i],
          Value: checkVal,
          Units: "Days",
        });
      }

      return process;
    };

    const sheetNames = [
      {
        name: "Scenario Details",
        data: Object.keys(data.batchData.scenario).map((key) => {
          return {
            Parameter: key.split(":")[1],
            Value: data.batchData.scenario[key],
          };
        }),
      },
      {
        name: "Results - Duration",
        data: Object.keys(data.resultsData.scenarioSummary[0]).map((key) => {
          return {
            Parameters: key,
            Value: data.resultsData.scenarioSummary[0][key],
          };
        }),
      },
      {
        name: "Results - Cost",
        data: Object.keys(data.costData[0]).map((key) => {
          return {
            Parameters: key,
            Value: data.costData[0][key],
          };
        }),
      },
      {
        name: "Results - Cable Information",
        data: filtered.map((key) => {
          if (!data.cables[0][key]) return;
          return {
            Parameters: data.cables[0][key].screen_name,
            Value: data.cables[0][key].val,
            Units: data.cables[0][key].units,
          };
        }),
      },
      { name: "Results - Gantt Chart", data: data.resultsData.quantDF },
      {
        name: "Results - Install Sequence",
        data: data.batchData.listIn.site_exportSections[
          data.batchData.scenario["site:minimise_by"]
        ],
      },
      { name: "Results - Vessel Capacities", data: [] },
      ...(data.batchData.scenario["site:scenario_settings"] === "Weathered"
        ? [
            {
              name: "Results - Delays",
              data: processDelay(
                {
                  data: data.resultsData.recordDelays,
                  names: data.resultsData.delayNames,
                },
                data.percent
              ),
            },
            { name: "Results - Cable Lay", data: data.resultsData.quantInstDF },
          ]
        : []),
      {
        name: "Results - Vessel Activity",
        data: data.resultsData.ganttSummary,
      },
    ];

    try {
      const wb = XLSX.utils.book_new();

      sheetNames.map((sheetName) => {
        if (sheetName.data.filter((item) => item === "undefined").length === 0)
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.json_to_sheet(sheetName.data),
            sheetName.name
          );
      });

      XLSX.writeFile(wb, `${data.batchData.scenarioName}_rawData.xlsx`);
      setLoading((loading) => ({ ...loading, csv: false }));
    } catch (e) {
      console.log(e);
      setLoading((loading) => ({ ...loading, csv: false }));
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setPdfReady(true);
    } else {
      setPdfReady(false);
    }
  }, [data]);

  return (
    <BottomBarBox>
      <ButtonGroup>
        <div id="preview_pdf">
          <FormControlLabel
            control={
              <Switch
                checked={previewPDF}
                onChange={() => {
                  setPreviewPDF((previewPDF) => !previewPDF);
                }}
                name="previewPDF"
                size="small"
                // color="primary"
                disabled={!pdfReady}
                style={{ color: "#009ec6" }}
              />
            }
            label="PREVIEW"
            labelPlacement="start"
            style={{ fontWeight: "800" }}
          />
        </div>
        <div id="document_settings">
          <SecButton
            title="Edit Report Theme"
            disabled={!pdfReady}
            onClick={() => setOpenStyles(true)}
          >
            Edit Theme
          </SecButton>
        </div>
      </ButtonGroup>

      <ButtonGroup>
        <div id="download_csv">
          <PrimeButton
            title="Download CSV"
            disabled={!pdfReady}
            onClick={(e) => {
              setLoading((loading) => ({ ...loading, csv: true }));
              downloadCSV();
            }}
          >
            {!loading.csv ? (
              <>
                <FontAwesomeIcon icon={faFileArrowDown} /> Data
              </>
            ) : (
              <BeatLoader color="white" />
            )}
          </PrimeButton>
        </div>
        <div id="download_pdf">
          <PrimeButton
            title="Download PDF"
            disabled={!pdfReady}
            onClick={() => {
              setLoading((loading) => ({ ...loading, pdf: true }));
              downloadPDF();
            }}
          >
            {!loading.pdf ? (
              <>
                <FontAwesomeIcon icon={faFileArrowDown} /> Report
              </>
            ) : (
              <BeatLoader color="white" />
            )}
          </PrimeButton>
        </div>
      </ButtonGroup>

      {/* <Invisible>
        <CSVLink
          id="csv_link_datasheet"
          data={csvData}
          filename={"cable_datasheet.csv"}
          href={`blob:${window.location}/5f6bdc13-b01d-4fd3-ab27-9cf3d3d019a9`}
        ></CSVLink>
      </Invisible> */}

      <Modal
        open={openStyles}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PdfForm
          settings={settings}
          setSettings={setSettings}
          handleClose={handleClose}
          logo={logo}
          setLogo={setLogo}
        />
      </Modal>
    </BottomBarBox>
  );
}

export default BottomBar;
