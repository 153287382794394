'use client'
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import io from "socket.io-client";

import Cable2D from "./Cable2D";
import NameBar from "./components/NameBar";
// import SearchBar from "./components/SearchBar";
import LoadSaveButtons from "./components/LoadSaveButtons";

import CustomListComponent from "./components/CustomListComponent";

import LayerItem from "./components/LayerItem";
import CharacteristicsItem from "./components/CharacteristicsItem";
import Environment from "./components/Environment";

// import { auth, functions } from "../../../../firebase";
import { throttle } from 'lodash';

import {
  initCharacteristics,
  initEnvironment,
  initLayers,
  // searchableCharacteristics,
} from "./Data";
import Notes from "./components/Notes";
import NotesBar from "./components/NotesBar";

// import { searchableLayers, layerTypes } from "./Data";
// import { v4 as uuidv4 } from "uuid";

// // import { functions } from "../firebase";

// import { httpsCallable } from "firebase/functions";
// import LibraryModal from "./components/LibraryModal";
// import { onAuthStateChanged } from "firebase/auth";

// import SocketTest from "../../SocketTest";
const FullWidthContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DesignerContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  min-width: 1350px;
  padding-left:auto;
  padding-right:auto;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 1300px; */
  /* height: 800px; */
  gap: 20px;
  /* background-color: #f2f2f2; */
  /* border: 3px solid red; */
  border-radius: 8px;
  z-index: 3;
  margin: 20px;
  /* padding: 50px 50px 50px 50px; */
  height: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  background-color: white;
  /* border: 1px solid lightgray; */
  border-radius: 8px;
  width: 40px;
  flex: 1;
  z-index: 5;
  
  /* padding: 50px; */
`;

const RedBox = styled.div`
  /* border: 0.1px solid red; */
  box-sizing: border-box;
`;

const CustomBox = styled(RedBox)`
  height: ${({ height }) => height}px;
  flex-shrink: 0;
`;

const Designer = () => {

  // const [expression, setExpression] = useState("");
  // const [result, setResult] = useState(null);
  // const [error, setError] = useState(null);

  const [layersName, setLayersName] = useState("");
  const [layers, setLayers] = useState(initLayers);

  const [charsName, setCharsName] = useState("");
  const [characteristics, setCharacteristics] = useState(initCharacteristics);


  const [envName, setEnvName] = useState("");
  const [environments, setEnvironments] = useState(initEnvironment);

  const reset_cable = () =>{
    setLayers(initLayers)
    setEnvironments(initEnvironment)
  }

  const socket = useMemo(() => {
    // const sock = io("http://127.0.0.1:8000"); // local Test
    const sock = io("https://educational-designer-ws-405606309488.europe-west1.run.app"); // local Test
  
    sock.on("connect", () => {
      console.log("Connected to WebSocket server.");
    });
    sock.on("disconnect", () => {
      console.log("Disconnected from WebSocket server.");
    });
    sock.on("message", (data) => {
      // console.log("Received message from server:", data);
      // iterate through characteristics and update the value
      // id should match up to data
      setCharacteristics((prev_chars) => {
        const new_chars = []

        prev_chars.map((char) => {
          if (data[char.id]) {
            new_chars.push({ ...char, value: data[char.id] })
          } else {
            new_chars.push(char)
          }
        }
        )
        return new_chars
      })
    });
    return sock;
  }, []);

  // Create a throttled version of the emit function that only runs every 200ms.
  const throttledEmit = useMemo(
    () =>
      throttle((input) => {
        socket.emit("message", input);
      }, 300),
    [socket]
  );
  const generate_input = (layers, environments) => {
    // console.log({ layers })
    const input = {}
    layers.map((layer, index) => {
      if (layer.name === "Conductor") {
        input["conductor:material"] = layer.mode.toLowerCase()
        input["conductor:diameter"] = layer.thickness * 2 // Maybe here is causing Fault Current Issue? maybe no *2?
        input["conductor:strand_diameter"] = layer.strand_thickness * 2
      }
      if (layer.name === "Conductor Shield") {
        input["conductor_shield:material"] = layer.mode.toLowerCase()
        input["conductor_shield:thickness"] = layer.thickness
      }
      if (layer.name === "Insulation") {
        input["insulation:material"] = layer.mode.toLowerCase()
        input["insulation:thickness"] = layer.thickness
      }
      if (layer.name === "Insulation Shield") {
        input["insulation_shield:material"] = layer.mode.toLowerCase()
        input["insulation_shield:thickness"] = layer.thickness
      }
      if (layer.name === "Metallic Screen") {
        input["metallic_screen:material"] = layer.mode.toLowerCase()
        input["metallic_screen:thickness"] = layer.thickness
      }
      if (layer.name === "Protective Sheath") {
        input["protective_sheath:material"] = layer.mode.toLowerCase()
        input["protective_sheath:thickness"] = layer.thickness
      }
      if (layer.name === "Armour Bedding") {
        input["armour_bedding:material"] = layer.mode.toLowerCase()
        input["armour_bedding:thickness"] = layer.thickness
      }
      if (layer.name === "Armour") {
    
        input["armour:material"] = layer.mode.toLowerCase()
        input["armour:strand_diameter"] = layer.thickness
      }
      if (layer.name === "Outer Cover") {
        input["outer_cover:material"] = layer.mode.toLowerCase()
        input["outer_cover:thickness"] = layer.thickness
      }
    });
    Object.keys(environments).map((key) => {
      if (key === "ground_temperature") {
        input["settings:environment:ground_temperature"] = environments[key]
      }
      if (key === "ground_thermal_resistivity") {
        input["settings:environment:ground_thermal_resistivity"] = environments[key]
      }
      if (key === "burial_depth") {
        input["settings:environment:burial_depth"] = environments[key]
      }
      if (key === "system_voltage"){
        input["metadata:system_voltage"] = environments[key]
      }

    })
    // console.log({ input })
    return input

  }

  useEffect(() => {
    const input = generate_input(layers, environments)
    throttledEmit(input);
  }, [layers, environments, throttledEmit]);

  return (
    <FullWidthContainer>
      <DesignerContainer>
        <ColumnContainer>
          <Column>
            <NameBar
              dataToSave={{ data: layers }}
              type={"layers"}
              title={"Layers"}
              editableName={layersName}
              setName={setLayersName}
            // uid={user.uid}
            />
            {/* <SearchBar
            infoTitle={"Add a Layer"}
            placeholder={"Add Layers"}
            items={searchLayers}
            onItemSelected={handleLayerItemSelected}
          /> */}

            {/* Add a component to display the Layers list */}
            <CustomListComponent
              data={layers}
              setData={setLayers}
              ItemComponent={LayerItem}
              height={645}
            />

            <LoadSaveButtons
            // handleSave={() => {
            //   handleSave(layersName, layers, "layers");
            // }}
            // handleLoad={() => {
            //   getLibrary("layers");
            //   getTemplates("layers");
            // }}
            />
          </Column>
          <Column>
            <Cable2D layers={layers} />
            <CustomBox height={0} />
            <NotesBar
              title={"About"}
              reset_cable={reset_cable}
            />
            <Notes reset_cable={reset_cable}/>

            {/* <LoadSaveButtons
            // handleSave={() => {
            //   handleSave(envName, environments, "environments");
            // }}
            // handleLoad={() => {
            //   getLibrary("environments");
            //   getTemplates("environments");
            // }}
          /> */}
          </Column>
          <Column>
            <NameBar
              dataToSave={{ data: environments }}
              type={"environments"}
              setName={setEnvName}
              title={"Settings"}
              editableName={envName}
            />
            <Environment env={environments} setData={setEnvironments} />
            <CustomBox height={10} />
            <NameBar
              dataToSave={{ data: characteristics }}
              type={"characteristics"}
              title={"Specifications"}
              editableName={charsName}
              setName={setCharsName}
            />
            {/* <SearchBar
            infoTitle={"Add a Characteristic"}
            placeholder={"Add Characteristics"}
            items={searchCharacteristics}
            onItemSelected={handleCharacteristicItemSelected}
          /> */}

            {/* Add a component to display the Characteristics list */}
            <CustomListComponent
              data={characteristics}
              setData={setCharacteristics}
              ItemComponent={CharacteristicsItem}
              height={300}
            />

            <LoadSaveButtons
            // handleSave={() => {
            //   handleSave(charsName, characteristics, "characteristics");
            // }}
            // handleLoad={() => {
            //   getLibrary("characteristics");
            //   getTemplates("characteristics");
            // }}
            />
          </Column>
        </ColumnContainer>

        {/* <LibraryModal
        type={libraryType}
        library={library}
        libraryOpen={libraryOpen}
        loadData={loadData}
        deleteData={deleteData}
        templates={templates}
        handleClose={handleLibraryClose}
      /> */}

        {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 200 200"
      >
        <ellipse cx="100" cy="130" rx="50" ry="40" fill="pink" />

        <ellipse cx="150" cy="100" rx="25" ry="20" fill="pink" />

        <polygon points="155,75 165,75 160,50" fill="gold" />

        <rect x="70" y="160" width="10" height="30" fill="pink" />
        <rect x="110" y="160" width="10" height="30" fill="pink" />
        <rect x="130" y="160" width="10" height="30" fill="pink" />
        <rect x="90" y="160" width="10" height="30" fill="pink" />
      </svg> */}
      </DesignerContainer>
    </FullWidthContainer>
  );
};

export default Designer;
