import React, { useState } from "react";
import styled from "styled-components";
import { Modal, IconButton, Avatar } from "@mui/material";

import CableIcon from "../../media/CableIcon.png";
import CableIconActive from "../../icons/CableIconActive.svg";
import ConstructionIcon from "../../media/icons/ship-solid.svg";
import UserProfile from "../landing/modals/UserProfile";

// import Test from "../../media/icons/export/option5.svg";

import Tooltip from "@mui/material/Tooltip";

import Disclaimer from "./Disclaimer";
import Comment from "./comment/Comment";
import { auth } from "../../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextMenu from "../../common/ContextMenu";
import { faHome, faShip } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";

const Side = styled.div`
  box-shadow: 3px 1px 5px #00000020;
  //position: fixed;
  /* left: 0; */
  /* top: 0; */
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

const TopIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const BottomIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  margin: 10px 0;
  cursor: pointer;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const iconSize = `36px`;

const isNKT = true;

const SideBar = ({
  page,
  setPage,
  user,
  setHomePage,
  setActiveWidget,
  permissions,
  isLoading,
  setIsLoading,
}) => {
  const pages = [
    {
      name: "cables",
      icon: CableIcon,
      activeIcon: CableIconActive,
      title: "Vekta Cables",
    },
    ...(permissions.toolAccess.find(
      (tool) =>
        tool === "Installation Module - Annually" ||
        tool === "Installation Module (Into) - Annually" ||
        tool === "Installation Module - Quarterly" ||
        tool === "Installation Module (Into) - Quarterly"
    )
      ? [
          {
            name: "construction",
            icon: ConstructionIcon,
            activeIcon: CableIconActive,
            title: "Vekta Export",
          },
        ]
      : [
          {
            name: "construction_purchase",
            icon: ConstructionIcon,
            activeIcon: CableIconActive,
            title: "Purchase Installation Module",
          },
        ]),
  ];

  const [openProfile, setOpenProfile] = useState(false);
  const [demoSelected, setDemoSelected] = useState(false);
  const [profileImg, setProfileImg] = useState(null);

  const [disclaimer, setDisclaimer] = useState(false);
  const [comment, setComment] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState({
    save: null,
    help: null,
    profile: { open: false, element: null },
  });
  const [customerService, setCustomerService] = useState(false);

  const handleComment = () => {
    setComment(true);
  };

  const handleCloseComment = () => {
    setComment(false);
  };

  const handleDisclaimer = () => {
    setDisclaimer(true);
  };

  const handleCloseDisclaimer = () => {
    setDisclaimer(false);
  };

  const resetUseState = (item) => {
    setSettingsMenu((settingsMenu) => ({
      ...settingsMenu,
      [item]: { open: false, element: null },
    }));
  };

  const handleClose = () => {
    setCustomerService(false);
  };

  return (
    <Side className="sidebarButtons">
      <TopIconsContainer className="widgetBtns">
        {pages.map(({ name, icon, activeIcon, title }) => (
          <Tooltip
            title={title}
            className={page === name ? "active" : null}
            style={{ outline: "none" }}
          >
            <button
              onClick={() => {
                setActiveWidget(name);
                setPage(name);

                if (name === "construction_purchase") setIsLoading(true);
              }}
              style={{ outline: "none" }}
            >
              {isLoading && name === "construction_purchase" ? (
                <ClipLoader color="#ffffff" />
              ) : (
                <img
                  className={name}
                  src={icon}
                  style={{ width: iconSize, height: iconSize }}
                />
              )}
            </button>
          </Tooltip>
        ))}
      </TopIconsContainer>

      <Spacer />

      <BottomIconsContainer className="settingsBtns">
        {/* {user && !user.isAnonymous ? (
          <>
            <Tooltip title="Save/Load Workspace">
              <IconButton
                title="Save/Load"
                onClick={(e) =>
                  setSettingsMenu((settingsMenu) => ({
                    ...settingsMenu,
                    save: e.currentTarget,
                  }))
                }
              >
                <FontAwesomeIcon size="lg" color="white" icon={faFolderOpen} />
              </IconButton>
            </Tooltip>
            <ContextMenu
              status={settingsMenu.save}
              item={"save"}
              reset={resetUseState}
              sectionOne={[
                {
                  name: (
                    <div>
                      Open Recent
                    </div>
                  ),
                  callback: () => {
                    // setQuickSave({ open: true, type: "Open" });
                    resetUseState("save");
                  },
                },
                {
                  name: (
                    <div style={{ width: "100%" }}>
                      Save <span style={{ float: "right" }}>Ctrl+S</span>
                    </div>
                  ),
                  callback: () => {
                    // setQuickSave({ open: true, type: "Save" });
                    resetUseState("save");
                  },
                },
              ]}
              sectionTwo={[
                {
                  name: <div>All Workspaces</div>,
                  callback: () => {
                    // setSaveLoad({ ...saveLoad, open: true });
                    resetUseState("save");
                  },
                },
              ]}
            />
          </>
        ) : null} */}

        <Tooltip title="Home Page">
          <IconButton onClick={() => setHomePage(true)}>
            <FontAwesomeIcon color="white" size="lg" icon={faHome} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Profile">
          <IconButton
            onClick={(e) =>
              setSettingsMenu((settingsMenu) => ({
                ...settingsMenu,
                profile: { open: true, element: e.currentTarget },
              }))
            }
          >
            <Avatar
              sx={{ background: "white", color: "#009ec6" }}
              src={profileImg && profileImg}
            ></Avatar>
          </IconButton>
        </Tooltip>
        <ContextMenu
          status={settingsMenu.profile}
          item={"profile"}
          reset={resetUseState}
          sectionOne={[
            {
              name: <strong>View Profile</strong>,
              callback: () => {
                setOpenProfile(true);
                resetUseState("profile");
              },
            },
            {
              name: <strong>Sign Out</strong>,
              callback: () => {
                resetUseState("profile");
                auth.signOut();
              },
            },
          ]}
          sectionThree={[]}
          sectionTwo={[
            // {
            //   name: <strong>Help</strong>,
            //   callback: () => {
            //     setDemoSelected(true);
            //     resetUseState("profile");
            //   },
            // },
            {
              name: <strong>Disclaimer</strong>,
              callback: () => {
                handleDisclaimer();
                resetUseState("profile");
              },
            },
          ]}
          position={{
            vertical1: "right",
            vertical2: "right",
            horizontal1: "right",
            horizontal2: "right",
          }}
        />
      </BottomIconsContainer>

      {/* <Modal
        open={comment}
        onClose={handleCloseComment}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Comment handleCloseModal={handleCloseComment} />
      </Modal> */}

      <Modal
        open={disclaimer}
        onClose={handleCloseDisclaimer}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Disclaimer handleCloseModal={handleCloseDisclaimer} />
      </Modal>
      <UserProfile
        openProfile={openProfile}
        setOpenProfile={setOpenProfile}
        setDemoSelected={setDemoSelected}
      />

      {/* <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // classes="gis_popups customerService"
        open={customerService}
        onClose={handleClose}
      >
        <Help handleClose={handleClose} />
      </Modal> */}
    </Side>
  );
};

export default SideBar;
