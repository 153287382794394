import React, { useState } from "react";
import styled from "styled-components";
import { Edit } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

import NameModal from "./NameModal";

const NameBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  font-family: "Poppins", sans-serif;
  color: #5a5a5a;
  border-bottom: 1px solid lightgray;
  max-height: fit-content;
  padding: 8px;
  font-size: 1rem;
  margin-bottom: 8px;
`;

const DesignerTitle = styled.h1`
  /* font-size: 12px; */
  /* font-weight: 700; */
  /* margin: 8px; */
`;

// const EditableNameContainer = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `;

// const EditableNameText = styled.span`
//   font-size: 16px;
//   font-weight: 400;
//   margin: 8px;
//   max-width: 130px;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// `;

// const EditIcon = styled(Edit)`
//   margin-right: 8px;
// `;

const NameBar = ({ title, editableName, setName, type, dataToSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  return (
    <NameBarContainer>
      <DesignerTitle style={{
        fontSize: "1.5rem",
      }}>{title}</DesignerTitle>
      {/* <EditableNameContainer onClick={toggleEditing}>
        <EditableNameText title={editableName}>{editableName}</EditableNameText>
        <EditIcon fontSize="small" />
      </EditableNameContainer> */}
      <NameModal
        dataToSave={dataToSave}
        type={type}
        open={isEditing}
        onClose={handleClose}
        editableName={editableName}
        setName={setName}
        setError={setError}
        error={error}
      />
    </NameBarContainer>
  );
};

export default NameBar;
