import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Divider } from '@mui/material';
import { auth, db } from '../../../firebase';
import CommentTable from './CommentTable';
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { ApplyButton, PrimeButton, SecButton } from '../../../styles/styles';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { onAuthStateChanged } from '@firebase/auth';

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

function Comment({ handleCloseModal }) {
    const headers = ["Name", "Date", "Item", "Comment", "Response", "Status", "Delete"]
    const [data, setData] = useState([headers])
    const [newRows, setNewRows] = useState([]);
    const [deletedRows, setDeletedRows] = useState([]); // [rowIndex, rowIndex, ...
    const [pendingDelete, setPendingDelete] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
    }, []);

    useEffect(() => {
        const docRef = doc(db, "support", "nkt_table");
        const unsub = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const savedData = JSON.parse(docSnap.data().data);
                setData(savedData);
            } else {
                // Initialize the document if it doesn't exist
                setDoc(docRef, { data: JSON.stringify([headers]) });
            }
        });
        return unsub;
    }, []);

    const handleClose = () => {
        if (newRows.length > 0 || pendingDelete) {
            if (window.confirm("Unsaved Changes! Are you sure you want to close?")) {
                handleCloseModal();
            }
        }
        else {
            handleCloseModal();
        }
    }

    const saveData = () => {
        const docRef = doc(db, "support", "nkt_table");
        const combinedData = [...data, ...newRows]; // Merge existing data with new rows
        setDoc(docRef, { data: JSON.stringify(combinedData) })
            .then(() => {
                setNewRows(() => {
                    setData(combinedData); // Update the data state with the combined data
                    setPendingDelete(false); // Reset pendingDelete
                    return []
                }); // Reset newRows as they are now saved
            })
            .catch(error => console.error("Error writing document: ", error));
    };

    const handleAddRow = () => {
        const currentDate = new Date().toLocaleString();
        const newRow = headers.map(header => {
            if (header.toLowerCase() === 'name') return user ? user.displayName : 'Anonymous';
            if (header.toLowerCase() === 'date') return currentDate;
            if (header.toLowerCase() === 'status') return false;
            return '';
        });
        setNewRows([...newRows, newRow]);
    };

    const downloadCSV = () => {
        const csvRows = [headers.join(',')]; // CSV header
        const allData = data.slice(1).concat(newRows); // Combine old and new rows

        allData.forEach(row => {
            const csvRow = row.map(cell =>
                typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell // Handle quotes in data
            ).join(',');
            csvRows.push(csvRow);
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'comments.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className='commentSheet' style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            borderRadius: '12px',

        }}>
            <Box sx={{
                backgroundColor: 'background.paper',
                borderRadius: '12px',
                padding: 2,
                width: '97%',
                height: '97%',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                position: 'relative',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "16px",
            }}>
                <header>
                    <h2>Document Styles</h2>
                    <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
                    <CloseModal onClick={handleClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </CloseModal>
                </header>

                <CommentTable
                    data={data}
                    setData={setData}
                    newRows={newRows}
                    setNewRows={setNewRows}
                    pendingDelete={pendingDelete}
                    setPendingDelete={setPendingDelete}
                />

                <footer>
                    <PrimeButton onClick={handleAddRow}>Add Row</PrimeButton>
                    <div style={{ display: "flex" }}>
                        <SecButton onClick={downloadCSV}><FontAwesomeIcon icon={faFileDownload} /> CSV</SecButton>
                        <PrimeButton onClick={saveData}>Save</PrimeButton>
                    </div>
                </footer>

            </Box>
        </div>
    );
}

export default Comment;
