import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Analysis from "./components/cables/analysis/Analysis";
import Collection from "./components/cables/collection/Collection";
import Results from "./components/cables/results/Results";

import SideBar from "./suvc/sidebar/SideBar";

import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { downloadWorkspace, updateWorkspace } from "./functions/functions";
import HomeFour from "./suvc/landing";

import Scenarios from "./components/construction/scenarios/Scenarios";
import AnalysisCon from "./components/construction/analysis/Analysis";
import ResultsCon from "./components/construction/results/Results";
import { getCheckoutUrl } from "./suvc/landing/helperFunctions";

const topBarHeight = "50px";

const CenterStage = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.2fr repeat(3, 1fr);
  grid-template-rows: repeat(2, minmax(0, 1fr)) 0.5fr 0.2fr repeat(
      2,
      minmax(0, 1fr)
    );
  grid-column-gap: 32px;
  grid-row-gap: 8px;

  padding: 8px;

  width: 100%;
  height: 100vh;
`;
const FullStage = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  background-color: hsla(0, 0%, 83%, 0.329);
`;

function App() {
  const [page, setPage] = useState("cables");
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [permissions, setPermissions] = useState(null);

  const [toAnalyse, setToAnalyse] = useState([]);
  const [runSettings, setRunSettings] = useState([]);

  const [runAPI, setRunAPI] = useState(false);

  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [activeWidget, setActiveWidget] = useState("cables");
  const [homePage, setHomePage] = useState(false);

  const [batchData, setBatchData] = useState({});
  const [batchSummary, setBatchSummary] = useState([]);
  const [batchSummarySelect, setBatchSummarySelect] = useState("");
  const [resultsData, setResultsData] = useState({
    listIn: [],
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const loadWorkspace = async (user) => {
    const default_workspace = {
      output_settings: ["Geometric Price Prediction"],
      collection: {
        selectedFilters: [
          "Size",
          "Nominal voltage",
          "Cable type",
          "Core material",
          "organisation",
          "Last edited by",
        ],
      },
      analysis: {
        defaultSettings: {
          date: "2018-01",
          currency: "USD",
          profit_margin: 2,
          region: "Europe",
          single_core_cable_arrangement: "trefoil",
          single_core_cable_bounding_type: "cross_bounded",
          core_temp: 90,
          metallic_screen_temp: 80,
          bury_depth: 1.0,
          ground_thermal_resistivity: 0.9,
          dry_ground_thermal_resistivity: 1.1,
          system_voltage: "Cable Voltage", // changes based on cable
          ground_temp: 12,
          critical_ground_temperature: 25,
          air_temperature: 20,
          system_frequency: 50,
          cable_environment_type: "direct_in_ground",
          ground_environment_thermal_condition: "no_soil_drying",
          air_environment_thermal_condition: "no_solar_radiation",
          optical_fibre_cores_number: 60,
        },
      },
    };

    const user_workspace = await downloadWorkspace(user.uid);

    // console.log(user_workspace);

    if (user_workspace) {
      setActiveWorkspace({ ...default_workspace, ...user_workspace });
    } else {
      setActiveWorkspace(default_workspace);
    }
  };

  const checkIfAuthorized = async (user) => {
    const permissionRef = doc(db, "permissions", user.uid);
    const permissionsDoc = await getDoc(permissionRef);
    if (permissionsDoc.data()) {
      const permissions = permissionsDoc.data();
      // console.log({ permissions });

      if (
        permissions &&
        permissions.permissions[0] === "vekta" &&
        (permissions.toolAccess.includes("Vekta Cables - Annually") ||
          permissions.toolAccess.includes("Vekta Cables - Quarterly"))
      ) {
        setAuthorized(true);
        setPermissions(permissions);
        loadWorkspace(user);
      } else {
        setAuthorized(false);
        setPermissions(null);
        setUser(null);
        // alert("You are not authorized to use this application");
        // auth.signOut()
      }
    }
  };

  // const handleToolLaunch = () => {};

  useEffect(() => {
    if (user) {
      checkIfAuthorized(user);
    }
  }, [user]);

  useEffect(() => {
    // console.log({ activeWidget });

    if (activeWidget === "construction_purchase") {
      const redirect = async (id) => {
        const checkoutUrl = await getCheckoutUrl(id, false);
        // console.log(checkoutUrl);
        setIsLoading(false);
        window.location.href = checkoutUrl.url;
      };

      const timeframe = permissions.toolAccess.find(
        (tool) =>
          tool === "Vekta Cables - Annually" ||
          tool === "Vekta Cables - Quarterly"
      );

      // console.log({ timeframe });

      if (timeframe.includes("- Annually")) {
        redirect("price_1QhZxEIowKhyLz4fzDH5OyTG");
      } else if (timeframe.includes("- Quarterly")) {
        redirect("price_1QhZkiIowKhyLz4f8xwCXPQ2");
      }
    }
  }, [activeWidget]);

  useEffect(() => {
    if (user && authorized) {
      updateWorkspace(user, activeWorkspace);
      setHomePage(false);
      // console.log("active workspace changed");
      // console.log(activeWorkspace);
    }
  }, [activeWorkspace, user, authorized]);

  // Handle authentication state change
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // const getToken = async () => {
        setUser(user);
      } else {
        setUser(null);
        setAuthorized(false);
      }
    });

    // TODO - Load in workspace from the firestore
  }, []);

  return (
    <div style={{ height: "100%" }}>
      {authorized ? (
        <>
          {authorized && user && !homePage ? (
            <FullStage>
              <SideBar
                page={page}
                user={user}
                setPage={setPage}
                setHomePage={setHomePage}
                setActiveWidget={setActiveWidget}
                permissions={permissions}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              {(activeWidget === "cables" ||
                activeWidget === "construction_purchase") &&
                activeWorkspace &&
                permissions.toolAccess.find(
                  (tool) =>
                    tool === "Vekta Cables - Annually" ||
                    tool === "Vekta Cables - Quarterly"
                ) && (
                  <CenterStage>
                    <Collection
                      user={user}
                      toAnalyse={toAnalyse}
                      setToAnalyse={setToAnalyse}
                      activeWorkspace={activeWorkspace}
                      setActiveWorkspace={setActiveWorkspace}
                    />
                    <Analysis
                      user={user}
                      toAnalyse={toAnalyse}
                      setToAnalyse={setToAnalyse}
                      runSettings={runSettings}
                      setRunSettings={setRunSettings}
                      setRunAPI={setRunAPI}
                      activeWorkspace={activeWorkspace}
                      setActiveWorkspace={setActiveWorkspace}
                    />
                    <Results
                      runSettings={runSettings}
                      toAnalyse={toAnalyse}
                      runAPI={runAPI}
                      setRunAPI={setRunAPI}
                      workspace={activeWorkspace.output_settings}
                      setWorkspace={setActiveWorkspace}
                    />
                  </CenterStage>
                )}
              {activeWidget === "construction" &&
                permissions.toolAccess.find(
                  (tool) =>
                    tool === "Installation Module - Annually" ||
                    tool === "Installation Module (Into) - Annually" ||
                    tool === "Installation Module - Quarterly" ||
                    tool === "Installation Module (Into) - Quarterly"
                ) && (
                  <CenterStage id="construction_left">
                    <Scenarios
                      user={user}
                      batchData={batchData}
                      setBatchData={setBatchData}
                      batchSummary={batchSummary}
                      setBatchSummary={setBatchSummary}
                    />
                    <AnalysisCon
                      user={user}
                      batchData={batchData}
                      setBatchData={setBatchData}
                      batchSummary={batchSummary}
                      setBatchSummary={setBatchSummary}
                      batchSummarySelect={batchSummarySelect}
                      setBatchSummarySelect={setBatchSummarySelect}
                      setResultsData={setResultsData}
                    />
                    <ResultsCon
                      user={user}
                      resultsData={resultsData}
                      setBatchData={setBatchData}
                      setResultsData={setResultsData}
                      batchSummary={batchSummary}
                      batchSummarySelect={batchSummarySelect}
                      batchData={batchData}
                    />
                  </CenterStage>
                )}
              <span
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  bottom: "4px",
                  right: "16px",
                  color: "black",
                }}
              >
                Powered By &#169;{" "}
                <a href="https://vektagroup.com/" target="_blank">
                  Vekta Group
                </a>{" "}
                V2.0.0 | Contributions &#169;{" "}
                <a
                  href="https://webstore.iec.ch/publication/68120"
                  target="_blank"
                >
                  IEC
                </a>{" "}
                & &#169;{" "}
                <a
                  href="https://cigre-usnc.org/technical-brochure-ref-908/"
                  target="_blank"
                >
                  CIGRE
                </a>
              </span>
            </FullStage>
          ) : (
            <HomeFour homePage={homePage} setHomePage={setHomePage} />
          )}
        </>
      ) : (
        // <LandingPage handleToolLaunch={handleToolLaunch} />
        <HomeFour homePage={false} />
      )}
    </div>
  );
}

export default App;
