import React from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
// import { KeyboardArrowDown } from "@mui/icons-material";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  color: #5a5a5a;
  font-family: "Poppins", sans-serif;
`;

const OptionsTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const MenuButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #5a5a5a;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px;

  margin-left: 36px;
  margin-bottom: 16px;
  height: fit-content;
`;

const ParamText = styled.div`
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 500;
  width: 60px;
  margin: 8px;
  margin-bottom: 0px;
  white-space: nowrap;
`;

const UnitText = styled.div`
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 500;
  width: 100px;
  padding-left: 12px;
  margin: 8px;
  white-space: nowrap;
`;
const Column = styled.div`
  display: flex;
  width: 380px;
  flex-direction: column;
`;

const EnvironmentContainer = styled.div`
  border: 1px solid lightgray;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  height: fit-content;
`;

const LoadingBar = styled.div`
  width: ${({ width }) => width || "100%"};
  height: 16px;
  margin: 16px;
  border-radius: 8px;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
const LoadingAnimation = () => (
  <div>
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="90%" />
    <LoadingBar width="70%" />
    <LoadingBar width="50%" />
    <LoadingBar width="80%" />

    {/* <LoadingBar width="90%" />
    <LoadingBar width="80%" /> */}
  </div>
);
const Environment = ({ env, setData }) => {
  const handleSliderChange = (name) => (event, newValue) => {
    setData({ ...env, [name]: newValue });
  };

  const handleModeChange = (event) => {
    setData({ ...env, mode: event.target.value });
  };

  const [openMenu, setOpenMenu] = React.useState(null);

  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = (selectedMode) => {
    setOpenMenu(null);
    if (typeof selectedMode !== "string") return;
    setData({ ...env, mode: selectedMode });
  };

  return (
    <div style={{ height: "fit-content" }}>
      {env ? (
        <>
          <EnvironmentContainer>
            <Column>
              <Row>
                <Column>
                  <Column>
                    <ParamText>System Voltage</ParamText>
                    <Row>
                      <Slider
                        min={60}
                        max={72.5}
                        step={0.1}
                        value={env.system_voltage}
                        onChange={handleSliderChange("system_voltage")}
                        sx={{ color: "#5a5a5a" }}
                      />
                      <UnitText>{env.system_voltage}</UnitText>
                    </Row>
                  </Column>
                  <Column>
                    <ParamText>Burial Depth </ParamText>
                    <Row>
                      <Slider
                        min={0.1}
                        max={10}
                        step={0.1}
                        value={env.burial_depth}
                        onChange={handleSliderChange("burial_depth")}
                        sx={{ color: "#5a5a5a" }}
                      />
                      <UnitText>{env.burial_depth}</UnitText>
                    </Row>
                  </Column>

                  <Column>
                    <ParamText>Ground Thermal Resistivity</ParamText>
                    <Row>
                      <Slider
                        min={0.1}
                        max={10}
                        step={0.1}
                        value={env.ground_thermal_resistivity}
                        onChange={handleSliderChange("ground_thermal_resistivity")}
                        sx={{ color: "#5a5a5a" }}
                      />
                      <UnitText>{env.ground_thermal_resistivity}</UnitText>
                    </Row>
                  </Column>
                  <Column>
                    <ParamText>Ground Temperature:</ParamText>
                    <Row>
                      <Slider
                        min={5}
                        max={30}
                        step={0.5}
                        value={env.ground_temperature}
                        onChange={handleSliderChange("ground_temperature")}
                        sx={{ color: "#5a5a5a" }}
                      />
                      <UnitText>{env.ground_temperature}</UnitText>
                    </Row>
                  </Column>
                </Column>
              </Row>
            </Column>
          </EnvironmentContainer>
          {/* <OptionsContainer>
            <MenuButton onClick={handleClickMenu}>
              <OptionsTitle>{env.mode}</OptionsTitle>
              <KeyboardArrowDown
                style={{
                  color: "#5a5a5a",
                  cursor: "pointer",
                  margin: "4px",
                  marginLeft: "0px",
                }}
              />
            </MenuButton>
            <Menu
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
            >
              {env.mode_options.map((option) => (
                <StyledMenuItem
                  key={option}
                  onClick={() => handleCloseMenu(option)}
                >
                  {option}
                </StyledMenuItem>
              ))}
            </Menu>
          </OptionsContainer> */}
        </>
      ) : (
        <div>
          <LoadingAnimation />
        </div>
      )}
    </div>
  );
};

export default Environment;
