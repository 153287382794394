import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";
import { quantile } from "simple-statistics";

function DeplayBarChart({ resultData, percentile, title }) {
  const [delayData, setDelayData] = useState([
    {
      type: "bar",
      orientation: "h",
    },
  ]);

  const layout = {
    ...(title
      ? {
          title: {
            text: "Breakdown of Weather Delays",
            font: {
              family: "Montserrat",
            },
          },
        }
      : {}),
    autosize: true,
    margin: { l: 250, r: 0, t: 25 },
    xaxis: {
      autotick: false,
      ticks: "outside",
      tick0: 0,
      dtick: 0.25,
      ticklen: 8,
      tickwidth: 2,
      tickcolor: "#000",
      title: {
        text: "Delay (Hrs)",
        standoff: 20,
      },
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    yaxis: {
      autotick: false,
      ticks: "outside",
      tick0: 0,
      dtick: 0.25,
      ticklen: 8,
      tickwidth: 2,
      tickcolor: "#000",
      title: {
        text: "Operation",
        standoff: 20,
      },
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    height: 500,
  };

  const config = {
    responsive: true,
    toImageButtonOptions: {
      format: "png", // one of png, svg, jpeg, webp
      filename: "DelayBarChart",
      height: 500,
      width: 1000,
      scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
    },
  };

  useEffect(() => {
    if (resultData.data) {
      const toProcess = [];
      const process = [];

      // Process the result data to just get just an array numbers
      for (let i = 0; i < resultData.data.length; i++) {
        const element = resultData.data[i];

        const quartileArray = Object.keys(element).map((key) => element[key]);
        quartileArray.pop(); // Remove the last value as its a row name not a value

        toProcess.push(quartileArray);
      }

      // Obtaining the quartile based on the percentile selected by the user.
      for (let i = 0; i < resultData.names.length; i++) {
        let checkVal = quantile(toProcess[i], percentile.value / 100) / 24; // Converting to hrs
        if (checkVal < 0) checkVal = 0;

        process.push(checkVal);
      }

      // console.log(resultData.names);
      

      setDelayData([
        {
          type: "bar",
          orientation: "h",
          // x: process.reverse(),
          x: process,
          y: resultData.names,
        },
      ]);
    }
  }, [resultData.data, percentile]);

  return (
    <>
      <Plot
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        data={delayData}
        layout={layout}
        config={config}
      />
    </>
  );
}

export default DeplayBarChart;
