import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";

function Sunburst({ resultData, title }) {
  const [sunburstData, setSunburstData] = useState([
    {
      type: "sunburst",
      outsidetextfont: { size: 20, color: "#377eb8" },
      leaf: { opacity: 0.4 },
      marker: { line: { width: 2 } },
      branchvalues: "total",
    },
  ]);

  const layout = {
    margin: { l: 0, r: 0, b: 0, t: 25 },
    ...(title
      ? {
          title: {
            text: "Breakdown of Vessel Activity",
            font: {
              family: "Montserrat",
            },
          },
        }
      : {}),
    autosize: true,
    xaxis: {
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    yaxis: {
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    height: 500,
  };

  const config = {
    responsive: true,
    toImageButtonOptions: {
      format: "png", // one of png, svg, jpeg, webp
      filename: "VesselActivity",
      height: 500,
      width: 1000,
      scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
    },
  };

  useEffect(() => {
    if (resultData) {
      const total = resultData
        .map((item) => item.Total)
        .reduce((total, current) => total + current, 0);
      const labels = [
        "Total <br>" + Math.round(total) + " days",
        ...resultData.map((item) => item._row + "<br>" + item.Total + " days"),
        ...resultData.map(
          (item) => item._row + " Working" + "<br>" + item.Working + " days"
        ),
        ...resultData.map(
          (item) => item._row + " Weather" + "<br>" + item.Weather + " days"
        ),
      ];
      const parents = [
        "",
        "Total <br>" + Math.round(total) + " days",
        "Total <br>" + Math.round(total) + " days",
        "Total <br>" + Math.round(total) + " days",
        "Total <br>" + Math.round(total) + " days",
        "Total <br>" + Math.round(total) + " days",
        ...resultData.map((item) => item._row + "<br>" + item.Total + " days"),
        ...resultData.map((item) => item._row + "<br>" + item.Total + " days"),
      ];
      const values = [
        total,
        ...resultData.map((item) => item.Total),
        ...resultData.map((item) => item.Working),
        ...resultData.map((item) => item.Weather),
      ];

      // console.log({ total, labels, parents, values });

      setSunburstData([
        {
          type: "sunburst",
          labels: labels,
          parents: parents,
          values: values,
          outsidetextfont: { size: 20, color: "#377eb8" },
          leaf: { opacity: 0.4 },
          marker: { line: { width: 2 } },
          branchvalues: "total",
        },
      ]);
    }
  }, [resultData]);

  return (
    <>
      <Plot
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        data={sunburstData}
        layout={layout}
        config={config}
      />
    </>
  );
}

export default Sunburst;
