import React, { useEffect } from "react";
import TopToBottom from "./lib/TopToBottom.js";

function BackToTop({ className }) {
  useEffect(() => {
    const result = document.querySelector(".back-to-top");
    console.log({ result });

    if (result) {
      document.getElementById("landing-page").addEventListener("scroll", () => {
        if (
          document.getElementById("landing-page").scrollTop >
            window.innerHeight ||
          document.getElementById("landing-page").scrollTop > window.innerHeight
        ) {
          result.style.display = "block";
        } else {
          result.style.display = "none";
        }
      });
    }
  });

  return (
    <>
      <div className={`back-to-top ${className || ""}`}>
        <a href="#nav" onClick={() => document.getElementById("landing-page").scrollTop = 0}>
          <i class="fa-solid fa-angles-up fa-xl"></i>
        </a>
      </div>
    </>
  );
}

export default BackToTop;
