import React, { useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown } from "@mui/icons-material";
import { color_map } from "../Data";

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)`
  color: lightgray;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #5a5a5a;
  }
`;

const LayerItemContainer = styled.div`
  width: 340px;
  margin-left: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  color: #5a5a5a;
  font-family: "Poppins", sans-serif;
`;

const MenuButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #5a5a5a;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
`;
const Column = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
`;

const LayerBox = styled.div`
  width: 270px;
  border: 1px solid lightgray;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 8px;
  cursor: pointer;
`;

const LayerBoxHidden = styled.div`
  width: 280px;
  /* border: 1px solid lightgray; */
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  padding: 8px;
  padding-top: 8px;
  padding-bottom: 4px;
  cursor: pointer;
`;

const LayerName = styled.span`
  font-size: 16px;
  user-select: none;
  color: #5a5a5a;
  font-weight: 500;
  user-select: none;
`;

const DragHandle = styled.div`
  cursor: grab;
  /* border: 3px solid red; */

  &:active {
    cursor: grabbing;
  }
`;

const Index = styled.div`
  margin-right: 0px;
  font-weight: bold;
  /* border: 3px solid green; */
  width: 16px;
  text-align: center;
  color: #5a5a5a;
  user-select: none;
`;

const ColorCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  /* margin-left: 8px; */
  border: 1px solid #5a5a5a;
  box-sizing: border-box;
`;

const ParamText = styled.div`
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 500;
  width: 60px;
  margin: 8px;
  margin-bottom: 0px;
  white-space: nowrap;
`;

const UnitText = styled.div`
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 500;
  width: 100px;
  padding-left: 12px;
  margin: 8px;
  white-space: nowrap;
`;

const LayerItem = ({ item, setData, provided }) => {
  const [toggle, setToggle] = useState(item.toggle);
  const [openMenu, setOpenMenu] = useState(null);

  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = (selectedMode) => {
    setOpenMenu(null);
    if (typeof selectedMode !== "string") return;

    setData((prevData) => {
      return prevData.map((currentItem) => {
        if (currentItem.name === item.name) {
          return { ...currentItem, mode: selectedMode };
        }
        return currentItem;
      });
    });
  };

  const handleSliderChange = (event, newValue, param_name) => {
    setData((prevData) => {
      return prevData.map((currentItem) => {
        if (currentItem.name === item.name) {
          const newItem = { ...currentItem };
          newItem[param_name] = newValue;
          return newItem;
        }
        return currentItem;
      });
    });
  };
  const handleDelete = () => {
    setData((prevData) => {
      const filtered = prevData.filter(
        (currentItem) => currentItem.id !== item.id
      );
      const updatedLayers = filtered.map((item, index) => {
        return { ...item, position: index };
      });
      return updatedLayers;
    });
  };

  const handleToggle = (event) => {
    console.log(item);
    event.stopPropagation();
    setToggle(!toggle);
  };
  const handleModeOptionChange = (mode,index) => {
    setData((prevData) => {
      return prevData.map((currentItem) => {
        if (currentItem.name === item.name) {
          const newItem = { ...currentItem };
          newItem.mode = mode;
          newItem.mode_index = index;
          return newItem;
        }
        return currentItem;
      });
    });

  };

  return (
    <LayerItemContainer>
      {toggle ? (
        <Row>
          {/* <DragHandle {...provided.dragHandleProps}> */}
            <Index>{item.position + 1}</Index>
          {/* </DragHandle> */}
          <Column>
            {/* <DragHandle {...provided.dragHandleProps}> */}
              <LayerBoxHidden>
                <Row>
                  <LayerName onClick={handleToggle}>{item.name}</LayerName>

                  
                </Row>
              </LayerBoxHidden>
            {/* </DragHandle> */}
            <LayerBox>
              {item.type === "basic" || item.type === "array" ? (
                <>
                  <ParamText>Thickness</ParamText>
                  <Row>
                    <Slider
                      value={item.thickness}
                      onChange={(event, newValue) =>
                        handleSliderChange(event, newValue, "thickness")
                      }
                      aria-label="Thickness"
                      min={item.min}
                      max={item.max}
                      step={item.step}
                      valueLabelDisplay="off"
                      sx={{
                        color: color_map[item.mode.toLowerCase()],
                      }}
                    />
                    <UnitText>{item.thickness} mm</UnitText>
                  </Row>
                </>
              ) : null}
              {item.type === "packed" ? (
                <>
                  <ParamText>Area</ParamText>
                  <Row>
                    <Slider
                      value={item.thickness * item.thickness * Math.PI}
                      onChange={(event, newValue) =>
                        handleSliderChange(
                          event,
                          Math.sqrt(newValue / Math.PI),
                          "thickness"
                        )
                      }
                      aria-label="Area"
                      min={item.min}
                      max={item.max}
                      step={item.step}
                      valueLabelDisplay="off"
                      sx={{
                        color: color_map[item.mode.toLowerCase()],
                      }}
                    />
                    <UnitText>
                      {Math.floor(item.thickness * item.thickness * Math.PI*0.904)}{" "}
                      mm²
                    </UnitText>
                  </Row>
                  <ParamText>Strand Diameter</ParamText>
                  <Row>
                    <Slider
                      value={item.strand_thickness}
                      onChange={(event, newValue) =>
                        handleSliderChange(event, newValue, "strand_thickness")
                      }
                      aria-label="strand_diameter"
                      min={item.min_2}
                      max={item.max_2}
                      step={item.step_2}
                      valueLabelDisplay="off"
                      sx={{
                        color: color_map[item.mode.toLowerCase()],
                      }}
                    />
                    <UnitText>{item.strand_thickness*2} mm</UnitText>
                  </Row>
                </>
              ) : null}
              {item.type === "triple" ? (
                <>
                  <ParamText>Optic Thickness</ParamText>
                  <Row>
                    <Slider
                      value={item.optic_thickness}
                      onChange={(event, newValue) =>
                        handleSliderChange(event, newValue, "optic_thickness")
                      }
                      aria-label="optic_thickness"
                      min={item.min}
                      max={item.max}
                      step={item.step}
                      valueLabelDisplay="off"
                      sx={{
                        color: color_map[item.mode.toLowerCase()],
                      }}
                    />
                    <UnitText>{item.optic_thickness} mm</UnitText>
                  </Row>
                  <ParamText>Optic Armour Thickness</ParamText>
                  <Row>
                    <Slider
                      value={item.optic_armour_thickness}
                      onChange={(event, newValue) =>
                        handleSliderChange(
                          event,
                          newValue,
                          "optic_armour_thickness"
                        )
                      }
                      aria-label="optic_armour_thickness"
                      min={item.min}
                      max={item.max}
                      step={item.step}
                      valueLabelDisplay="off"
                      sx={{
                        color: color_map[item.mode.toLowerCase()],
                      }}
                    />
                    <UnitText>{item.optic_armour_thickness} mm</UnitText>
                  </Row>
                </>
              ) : null}
            </LayerBox>
            <LayerBoxHidden>
              <Row>
                <MenuButton onClick={handleClickMenu}>
                  {item.mode}
                  <KeyboardArrowDown
                    style={{ color: "#5a5a5a", cursor: "pointer" }}
                  />
                </MenuButton>
                <Menu
                  anchorEl={openMenu}
                  open={Boolean(openMenu)}
                  onClose={handleCloseMenu}
                >
                  {item.mode_options.map((mode, index) => (
                    <StyledMenuItem
                      key={index}
                      onClick={() => {
                        handleModeOptionChange(mode,index)
                        handleCloseMenu(mode)
                      }
                      }
                    >
                      {mode}
                    </StyledMenuItem>
                  ))}
                </Menu>
              </Row>
            </LayerBoxHidden>
          </Column>
          {/* <DragHandle {...provided.dragHandleProps}> */}
            <ColorCircle style={{ backgroundColor: color_map[item.mode.toLowerCase()]}} />
          {/* </DragHandle> */}
        </Row>
      ) : (
        // <DragHandle {...provided.dragHandleProps}>
          <Row>
            <Index>{item.position + 1}</Index>
            <Column>
              <LayerBox onClick={handleToggle}>
                <Row>
                  <LayerName>{item.name}</LayerName>

                  {/* <StyledCloseRoundedIcon onClick={handleDelete} /> */}
                </Row>
              </LayerBox>
            </Column>
            <ColorCircle style={{ backgroundColor: color_map[item.mode.toLowerCase()]}} />
          </Row>
        // </DragHandle>
      )}
    </LayerItemContainer>
  );
};

export default LayerItem;
