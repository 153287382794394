import React, { useEffect, useState } from "react";
import { db, storage } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { getDownloadURL, ref } from "firebase/storage";
import { Divider } from "@mui/material";

function Blogs() {
  const [insights, setInsights] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  //// fetch the insights data from firebase
  useEffect(() => {
    const fetchData = async () => {
      const dataInsightsCollection = collection(db, "dataInsights");
      const dataInsightsSnapshot = await getDocs(dataInsightsCollection);
      const insightsEntries = dataInsightsSnapshot.docs.map((doc) =>
        doc.data()
      );
      setInsights(insightsEntries);
    };

    fetchData();
  }, []);

  useEffect(() => {
    insights.forEach((insight) => {
      const imageRef = ref(
        storage,
        `appInsightsThumbnails/${insight.name}.png`
      );
      getDownloadURL(imageRef)
        .then((url) => {
          setImageUrls((prevState) => ({ ...prevState, [insight.name]: url }));
        })
        .catch((error) => {
          console.error("Error getting image URL:", error);
        });
    });
  }, [insights]);

  const formattedData = (date) => {
    const today = date.split("-");
    const yyyy = today[0];
    let mm = today[1]; // Months start at 0!
    let dd = today[2];

    return `${dd}/${mm}/${yyyy}`;
  };

  return (
    <>
      <div className="row">
        {insights.map((item) => {
          console.log(item);

          return (
            item.visibility && (
              <div className="col-lg-4 col-md-6">
                <div
                  className="post-item-1"
                  style={{
                    height: "480px",
                    maxHeight: "480px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {/* <img
                    src={imageUrls[insight.name]}
                    alt={insight.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      marginBottom: "10px",
                    }}
                  /> */}
                  <img
                    src={imageUrls[item.name]}
                    alt={item.name}
                    style={{ borderRadius: "0px" }}
                  />
                  {/* <Divider /> */}
                  <div className="b-post-details">
                    <div className="bp-meta align-items-center">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faClock}
                          style={{ marginRight: "8px" }}
                        />
                        {formattedData(item.publishDate)}
                      </a>
                      {/* <a href="#">{formattedData(item.publishDate)}</a> */}
                      <a href="#">
                        Access on:{" "}
                        {item.premium ? (
                          //   <img
                          //     src={VDPlus}
                          //     style={{ width: "50px" }}
                          //     title="Vekta Digital +"
                          //     alt="Vekta Digital +"
                          //   />
                          <label
                            style={{ marginRight: "8px", marginBottom: "0px" }}
                          >
                            VD+
                          </label>
                        ) : (
                          <>
                            {/* <img
                              src={VD}
                              style={{ width: "50px" }}
                              title="Vekta Digital"
                              alt="Vekta Digital"
                            />
                            <img
                              src={VDPlus}
                              style={{ width: "50px" }}
                              title="Vekta Digital +"
                              alt="Vekta Digital +"
                            /> */}
                            <label
                              style={{
                                marginRight: "8px",
                                marginBottom: "0px",
                              }}
                            >
                              VD
                            </label>
                            <label
                              style={{
                                marginRight: "8px",
                                marginBottom: "0px",
                              }}
                            >
                              VD+
                            </label>
                          </>
                        )}
                      </a>
                    </div>
                    <h3
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        lineClamp: 2,
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {item.name}
                    </h3>
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "3",
                        lineClamp: 3,
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {item.shortIntroText}
                    </p>
                  </div>
                </div>
              </div>
            )
          );
        })}
        {/* <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg2} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                    Insights on How to Improve Your Teaching.
                                </a>
                            </h3>
                            <a className="read-more" href="/news/single-news">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg3} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                    Brush Strokes Energize Trees In Paintings
                                </a>
                            </h3>
                            <a className="read-more" href="/news/single-news">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg4} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">Learning Python for Data Analysis.</a>
                            </h3>
                            <a className="read-more" href="/news/single-news">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg5} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="single-post.html">
                                    Logotype Masterclass with Jessica Hische
                                </a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg6} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                    Build A Full Web Chat App From Scratch.
                                </a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg7} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="single-post.html">Learning Python for Data Analysis.</a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg8} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="#">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="#">
                                    <i className="fal fa-comments"></i>6 Comments
                                </a>
                            </div>
                            <h3>
                                <a href="single-post.html">Learning Python for Data Analysis.</a>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div> */}
      </div>
      {/* <div className="row">
                <div className="col-lg-12">
                    <div className="bisylms-pagination">
                        <span className="current">01</span>
                        <a href="#">02</a>
                        <a className="next" href="#">
                            next<i className="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default Blogs;
