import React, { useEffect } from "react";
import { Box, Typography, Button, Divider, IconButton } from "@mui/material";
import { ApplyButton, PrimeButton } from "../../styles/styles";
import styled from "styled-components";

import CloseIcon from '@mui/icons-material/Close';

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
        props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

function Disclaimer({ handleCloseModal }) {
  return (
    <div
      className='cableModal'
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          position: "fixed",
          inset: "auto 20%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          gap: "24px",
        }}
      >
        <header>
          <h2>Disclaimer</h2>
          <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />

          <CloseModal onClick={handleCloseModal}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>

          <Typography
            variant="body1"
            gutterBottom
            sx={{ lineHeight: 1.6, fontFamily: "Montserrat" }}
          >
            This statement was last updated on October 09, 2023
          </Typography>
        </header>

        <Typography
          variant="body1"
          gutterBottom
          sx={{ lineHeight: 1.6, fontFamily: "Montserrat" }}
        >
          The prices generated by VEKTA CABLES are for the purpose of budget
          stage tender estimation only. While we have made every effort to
          ensure that the data and formulae used to calculate cable prices on
          VEKTA CABLES have been obtained from reliable sources, Vekta Group is
          not responsible for any errors or omissions, or for the results
          obtained from the use of VEKTA CABLES. <br></br><br></br> All the prices generated by
          VEKTA CABLES are provided on an "as is", with no guarantee of complete
          accuracy, timeliness or of the results obtained from the use of this
          data, and without warranty of any kind, express or implied, including,
          but not limited to warranties of performance, merchantability and
          fitness for a particular purpose. In no event will Vekta Group, or its
          agents or employees be liable to the user or anyone else for any
          decision made or action taken in reliance on the information in VEKTA
          CABLES or for any consequential, special or similar damages, even if
          advised of the possibility of such damages.
        </Typography>
        <footer>
          <PrimeButton
            style={{ float: "right" }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
            sx={{ mt: 2, alignSelf: "flex-end" }} // Align the button to the right
          >
            Close
          </PrimeButton>
        </footer>
      </Box>
    </div>
  );
}

export default Disclaimer;