/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";

const ToolBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 50%;
  /* transform: translate(0, -50%); */
  border-radius: 5px;
  border: 1px solid lightgray;
  /* width:47px; */
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const StyledIconButton = styled(IconButton)`
  /* width:35px;
  height:35px; */
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;
  outline: none;
  bottom: none;

  &:hover {
    background-color: #f5f5f5 !important;
    transform: scale(1.1);
  }
`;

function Markers({ mode, setMode }) {
  return (
    // <div>
    //   <label>Weather Selection : </label>
    <ToolBox className="drawControls">
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <StyledIconButton
          active={mode === "hdd"}
          onClick={() => setMode("hdd")}
          title="HDD Weather"
        >
          {/* <FontAwesomeIcon icon={LocationIcon} /> */}
          HDD
        </StyledIconButton>

        <StyledIconButton
          active={mode === "osp"}
          onClick={() => setMode("osp")}
          title="OSP Weather"
        >
          {/* <FontAwesomeIcon icon={DeleteIcon} /> */}
          OSP
        </StyledIconButton>

        <StyledIconButton
          active={mode === "export"}
          onClick={() => setMode("export")}
          title="Export Cable Weather"
        >
          {/* <FontAwesomeIcon icon={MinimiseIcon} /> */}
          Export Cables
        </StyledIconButton>
      </div>
    </ToolBox>
    // </div>
  );
}

export default Markers;

// function DrawControlPanel({ setMode, mode, configRef }) {
//   const [minimised, setMinimised] = useState(false)

//   const handleMinimise = () => {
//     setMode("minimise")
//     setMinimised(true)
//   }

//   const handleModeChange = (selection) => {
//     // const measure = document.getElementById("polyline-measure-control")

//     // if (measure && measure.classList.contains("polyline-measure-controlOnBgColor")) {
//     //   measure.click()
//     // }
//     // console.log(document.getElementById("draw_site").getBoundingClientRect());
//     setMode(selection)
//   }

//   return (

//   );
// }

// export default DrawControlPanel;
