import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton } from "../styles/styles";
import {
  faInfoCircle,
  faMapLocationDot,
  faShip,
} from "@fortawesome/free-solid-svg-icons";

import cableNamesDB from "../components/construction/data/cableNamesDB.json";
import { duration } from "@mui/material";

export class BpGraph_Export {
  constructor(buttonFunction, disableFunction) {
    this.bp_graph = {
      "vessels:selected_vessel": {
        requires: {},
        default: "Generic_9000t_2-Carousel",
        options: ["Generic_9000t_2-Carousel"],
        unit: {
          value: (
            <StyledIconButton
              title="Vessel Carousel Information"
              style={{ width: "97%", height: "100%", borderRadius: "6px" }}
              onClick={() => buttonFunction.carousel(true)}
            >
              <FontAwesomeIcon color="white" icon={faInfoCircle} />
            </StyledIconButton>
          ),
        },
        usage: ["scenario"],
      },
      "vessels:primary_carousel_cable": {
        requires: {},
        default: "*Three-core 220kV 1600sqmm Al VEKTA",
        options: ["*Three-core 220kV 1600sqmm Al VEKTA"],
        name: "Main Carousel Cable",
        usage: ["scenario"],
      },
      "vessels:secondary_carousel_cable": {
        requires: {
          "vessels:selected_vessel": [
            "Generic_4000t_2-Carousel",
            "Generic_6500t_2-Carousel",
            "Generic_8500t_2-Carousel",
            "Generic_9000t_2-Carousel",
            "Generic_11000t_2-Carousel",
            "Generic_13000t_2-Carousel",
          ],
        },
        default: "*Three-core 220kV 1600sqmm Al VEKTA",
        options: ["*Three-core 220kV 1600sqmm Al VEKTA"],
        // disable: disableFunction("cable"),
        name: "Below Carousel Cable",
        usage: ["scenario"],
      },
      "vessels:lay_rate": {
        requires: {},
        default: 350,
        min: 0,
        unit: {
          value: "m/h",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:load_rate": {
        requires: {},
        default: 300,
        min: 0,
        unit: {
          value: "m/h",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:laden_speed": {
        requires: {},
        default: 10,
        min: 0,
        unit: {
          value: "knots",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:unladen_speed": {
        requires: {},
        default: 12,
        min: 0,
        unit: {
          value: "knots",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:idle_cost": {
        requires: {},
        default: 135000,
        min: 0,
        unit: {
          value: "£/Day",
        },
        type: "number",
        usage: ["setting", "scenario"],

        name: "Wait on Weather day rate",
      },
      "vessels:work_cost": {
        requires: {},
        default: 140000,
        min: 0,
        unit: {
          value: "£/Day",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:transit_cost": {
        requires: {},
        default: 140000,
        min: 0,
        unit: {
          value: "£/Day",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessels:mob_and_demob_cost": {
        requires: {},
        default: 122000,
        min: 0,
        unit: {
          value: "£/Day",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "vessel_ops:mobDemob": {
        requires: {},
        default: [
          {
            title: { value: "Mobilisation", options: { editable: false } },
            duration: { value: 96, options: { editable: true } },
            window: { value: 6, options: { editable: true } },
            hs: { value: 4, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
          {
            title: { value: "Demobilisation", options: { editable: false } },
            duration: { value: 48, options: { editable: true } },
            window: { value: 6, options: { editable: true } },
            hs: { value: 4, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        name: "Mobilisation & Demobilisation",
        usage: ["scenario"],
      },
      "vessel_ops:preLoadout": {
        requires: {},
        default: [
          {
            title: {
              value: "Prepare vessel for load out",
              options: { editable: false },
            },
            duration: { value: 4, options: { editable: true } },
            window: { value: 6, options: { editable: true } },
            hs: { value: 2.5, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        name: "Pre Loadout",
        usage: ["scenario"],
      },
      "vessel_ops:loadout": {
        requires: {},
        default: [
          {
            title: { value: "Load cable", options: { editable: false } },
            duration: { value: null, options: { editable: false } },
            window: { value: 6, options: { editable: true } },
            hs: { value: 2.5, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        usage: ["scenario"],
      },
      "vessel_ops:postLoadout": {
        requires: {},
        default: [
          {
            title: {
              value: "Test & make Ready for transit",
              options: { editable: false },
            },
            duration: { value: 8, options: { editable: true } },
            window: { value: 8, options: { editable: true } },
            hs: { value: 2.5, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        name: "Post Loadout",
        usage: ["scenario"],
      },
      "vessel_ops:onsite": {
        requires: {},
        default: [
          {
            title: {
              value: "Launch burial tool",
              options: { editable: false },
            },
            duration: { value: 6, options: { editable: true } },
            window: { value: 8, options: { editable: true } },
            hs: { value: 2, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
          {
            title: { value: "Lay & bury cable", options: { editable: false } },
            duration: { value: null, options: { editable: false } },
            window: { value: 8, options: { editable: true } },
            hs: { value: 1.5, options: { editable: true } },
            ws10: { value: 10, options: { editable: true } },
          },
          {
            title: {
              value: "Recover burial tool",
              options: { editable: false },
            },
            duration: { value: 4, options: { editable: true } },
            window: { value: 6, options: { editable: true } },
            hs: { value: 2, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        usage: ["scenario"],
      },
      "vessel_ops:transit": {
        requires: {},
        default: [
          {
            title: { value: "Transit to site", options: { editable: false } },
            duration: { value: null, options: { editable: false } },
            window: { value: 8, options: { editable: true } },
            hs: { value: 4, options: { editable: true } },
            ws10: { value: 20, options: { editable: true } },
          },
          // {
          //   title: { value: "Infield transfer", options: { editable: false } },
          //   duration: { value: 0, options: { editable: true } },
          //   window: { value: 0, options: { editable: true } },
          //   hs: { value: 0, options: { editable: true } },
          //   ws10: { value: 0, options: { editable: true } },
          // },
          // {
          //   title: { value: "Transit to MH", options: { editable: false } },
          //   duration: { value: "", options: { editable: false } },
          //   window: { value: 8, options: { editable: true } },
          //   hs: { value: 4, options: { editable: true } },
          //   ws10: { value: 20, options: { editable: true } },
          // },
        ],
        type: "table",
        usage: ["scenario"],
      },
      "vessel_ops:hddOps": {
        requires: {},
        default: [
          {
            title: {
              value: "Set up at beach & HDD pull in",
              options: { editable: false },
            },
            duration: { value: 48, options: { editable: true } },
            window: { value: 48, options: { editable: true } },
            hs: { value: 2, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        name: "HDD Operations",
        usage: ["scenario"],
      },
      "vessel_ops:ospOps": {
        requires: {},
        default: [
          {
            title: {
              value: "Pull in to platform",
              options: { editable: false },
            },
            duration: { value: 10, options: { editable: true } },
            window: { value: 12, options: { editable: true } },
            hs: { value: 1.5, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
            TpMin: { value: 9, options: { editable: true } },
            TpMax: { value: 1000, options: { editable: true } },
          },
          {
            title: {
              value: "Prepare to leave location",
              options: { editable: false },
            },
            duration: { value: 2, options: { editable: true } },
            window: { value: 2, options: { editable: true } },
            hs: { value: 2, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
            TpMin: { value: null, options: { editable: false } },
            TpMax: { value: null, options: { editable: false } },
          },
        ],
        type: "table",
        name: "OSP Operations",
        usage: ["scenario"],
      },
      "vessel_ops:joint": {
        requires: {},
        default: [
          {
            title: { value: "Joint operation", options: { editable: false } },
            duration: { value: 48, options: { editable: true } },
            window: { value: 48, options: { editable: true } },
            hs: { value: 2, options: { editable: true } },
            ws10: { value: 15, options: { editable: true } },
          },
        ],
        type: "table",
        name: "Joint Operations",
        usage: ["scenario"],
      },
      "cable:carousel": {
        disabled: true,
        requires: {},
        default: {
          main: {
            Name: "Three-core AR 10kV 70sqmm Al VEKTA",
            "Core Cross-Section (mm2)": 72.38,
            "Overall Diameter (m)": 83.0,
            "Weight in Air (kg/m)": 9,
            "Minimum Coiling Diameter (m)": 0.83 * 4,
            "Maximum Crush Load (kN/m)": 30,
            "Minimum Bend Radius (kN/m)": 30,
            "Minimum Bend Radius (Installation) (kN/m)": 30,
            "Maximum Allowable Sidewall Pressure (kN/m)": 30,
          },
          below: {
            Name: "Three-core AR 10kV 70sqmm Al VEKTA",
            "Core Cross-Section (mm2)": 72.38,
            "Overall Diameter (m)": 83.0,
            "Weight in Air (kg/m)": 9,
            "Minimum Coiling Diameter (m)": 0.83 * 4,
            "Maximum Sidewall Pressure (kN/m)": 30,
            "Minimum Bend Radius (kN/m)": 30,
            "Minimum Bend Radius (Installation) (kN/m)": 30,
            "Maximum Allowable Sidewall Pressure (kN/m)": 30,
          },
        },
        usage: ["internal"],
      },
      // "site:name": {
      //   requires: {},
      //   default: "Default Site Name",
      //   type: "text",
      // },
      "site:name": {
        requires: {},
        default: "Default Scenario Name",
        type: "text",
        usage: ["scenario"],
      },
      "site:start_date": {
        requires: {},
        default: "today",
        type: "date",
        usage: ["scenario"],
      },
      "site:minimise_by": {
        requires: {},
        default: "loadOut",
        options: ["loadOut", "connector"],
        usage: ["setting", "scenario"],
        // setting: true,
      },
      "site:minimum_cable_section_length": {
        requires: {},
        default: 10,
        min: 0,
        unit: {
          value: "km",
        },
        type: "number",
        usage: ["setting", "scenario"],
      },
      "site:scenario_setting": {
        requires: {},
        default: "weathered",
        options: ["weathered", "unweathered"],
        usage: ["setting", "scenario"],
        unit: {
          value: (
            <StyledIconButton
              title="Vessel Operation Table Definition"
              style={{ width: "97%", height: "100%", borderRadius: "6px" }}
              onClick={() => buttonFunction.vessel(true)}
            >
              <FontAwesomeIcon color="white" icon={faShip} />
            </StyledIconButton>
          ),
        },
      },
      "site:number_of_HDD": {
        requires: {},
        default: 1,
        min: 0,
        max: 10,
        type: "number",
        name: "Number of Landfalls",
        unit: {
          value: (
            <StyledIconButton
              title="Weather Selection for Landfalls"
              style={{
                border: "2px solid red",
                borderRadius: "8px",
                width: "97%",
                height: "100%",
                // borderRadius: "6px",
              }}
              onClick={() => buttonFunction.weather("HDD_weather_point")}
            >
              <FontAwesomeIcon color="white" icon={faMapLocationDot} />
            </StyledIconButton>
          ),
          condition: { "site:scenario_setting": ["weathered"] },
        },
        usage: ["scenario"],
      },
      "site:HDD_weather_point": {
        disabled: true,
        requires: {},
        default: { lat: 55, lon: 1 },
        type: "map",
        usage: ["internal"],
      },
      "site:number_of_OSP": {
        requires: {},
        default: 2,
        min: 0,
        max: 10,
        type: "number",
        name: "Number of OSP(s)",
        unit: {
          value: (
            <StyledIconButton
              title="Weather Selection for OSP"
              style={{
                border: "2px solid green",
                borderRadius: "8px",
                width: "97%",
                height: "100%",
                // borderRadius: "6px",
              }}
              onClick={() => buttonFunction.weather("OSP_weather_point")}
            >
              <FontAwesomeIcon color="white" icon={faMapLocationDot} />
            </StyledIconButton>
          ),
          condition: { "site:scenario_setting": "weathered" },
        },
        usage: ["scenario"],
      },
      "site:OSP_weather_point": {
        disabled: true,
        requires: {},
        default: { lat: 55, lon: 1 },
        type: "map",
        usage: ["internal"],
      },
      "site:number_of_export_cables": {
        requires: {},
        default: 6,
        min: 0,
        max: 10,
        type: "number",
        name: "Number of Export Cables(s)",
        unit: {
          value: (
            <StyledIconButton
              title="Weather Selection for Export Cable(s)"
              style={{
                border: "2px solid blue",

                borderRadius: "8px",
                width: "97%",
                height: "100%",
                // borderRadius: "6px",
              }}
              onClick={() =>
                buttonFunction.weather("export_cable_lay_weather_point")
              }
            >
              <FontAwesomeIcon color="white" icon={faMapLocationDot} />
            </StyledIconButton>
          ),
          condition: { "site:scenario_setting": "weathered" },
        },
        usage: ["scenario"],
      },
      "site:export_cable_lay_weather_point": {
        disabled: true,
        requires: {},
        default: { lat: 55, lon: 1 },
        type: "map",
        usage: ["internal"],
      },
      "site:distance_from_HDD": {
        requires: {},
        default: 80,
        min: 0,
        unit: {
          value: "km",
        },
        type: "number",
        name: "Distance from Loadout to Landfall",
        usage: ["scenario"],
        guidance: "Time = Distance/Time",
      },
      "site:distance_from_OSP": {
        requires: {},
        default: 100,
        min: 0,
        unit: {
          value: "km",
        },
        type: "number",
        name: "Distance from Loadout to OSP",
        usage: ["scenario"],
        guidance: "Time = Distance/Time",
      },
      "site:exports": {
        requires: {},
        default: Array.from({ length: 6 }, (_, i) => ({
          ids: {
            value: `EXP${i + 1}`,
            options: { flex: 1, minWidth: 100, editable: false },
          },
          carousel: {
            value: "*Three-core 220kV 1600sqmm Al VEKTA",
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: [
                  "*Three-core 220kV 1600sqmm Al VEKTA",
                  "*Three-core 220kV 1600sqmm Al VEKTA",
                ],
              },
            },
            label: "Cable",
            minWidth: 300,
          },
          Length: {
            value: 40,
            options: { flex: 1, minWidth: 100, editable: true },
          },
          "End 1": {
            value: "Landfall 1",
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: ["Landfall 1", "OSP 1", "OSP 2"],
              },
              flex: 1,
              minWidth: 100,
            },
            label: "Start Point",
            // minWidth: 70,
          },
          "End 2": {
            value: "OSP 1",
            options: {
              editable: true,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: ["Landfall 1", "OSP 1", "OSP 2"],
              },
              flex: 1,
              minWidth: 100,
            },
            label: "End Point",
            // minWidth: 70,
          },
        })),
        type: "table",
        name: "Cable Connections",
        order: ["ids", "carousel", "Length", "End 1", "End 2"],
        usage: ["scenario"],
      },
    };
    this.prefixes = ["vessels", "site"];
  }

  get_defaults() {
    const defaults = {};
    Object.keys(this.bp_graph).map((key) => {
      defaults[key] = this.bp_graph[key].default;
    });

    return defaults;
  }

  get_settings() {
    const settings = {};
    Object.keys(this.bp_graph).map((key) => {
      if (this.bp_graph[key]?.usage.includes("setting")) {
        settings[key] = this.bp_graph[key];
      }
    });

    return settings;
  }

  get_scenario() {
    const settings = {};
    Object.keys(this.bp_graph).map((key) => {
      if (this.bp_graph[key]?.usage.includes("scenario")) {
        settings[key] = this.bp_graph[key];
      }
    });

    return settings;
  }

  get_next_steps(bp_partial) {
    const next_steps = {};
    Object.keys(this.bp_graph).map((key) => {
      const all_requirements = this.bp_graph[key].requires;
      // // console.log(all_requirements)
      if (Object.keys(all_requirements).length === 0) {
        next_steps[key] = { ...this.bp_graph[key], required: true, new: false };
      } else {
        let includes_all = true;
        Object.keys(all_requirements).map((param) => {
          const requirements = all_requirements[param];
          // // console.log(param, requirements)
          if (bp_partial && bp_partial[param]) {
            const current_value = bp_partial[param];
            if (!requirements.includes(current_value)) {
              // // console.log("included")
              includes_all = false;
            }
          } else {
            includes_all = false;
          }
        });
        if (includes_all) {
          next_steps[key] = {
            ...this.bp_graph[key],
            required: false,
            new: true,
          };
        }
      }
    });

    // console.log({ next_steps });
    return next_steps;
  }

  fill_blueprint(bp_partial) {
    const bp = { ...bp_partial };
    const bp_graph = { ...this.bp_graph };
    const resolved = {};

    const resolve = (target_key) => {
      // bp = {...bp, ...bp_partial}
      // skip if already resolved
      if (resolved[target_key]) {
        // // console.log("already resolved:", target_key, " skipping")
        return true;
      }

      // handle missing target_key
      if (!Object.keys(bp_graph).includes(target_key)) {
        bp[target_key] = "TARGET DOES NOT EXIST";
        resolved[target_key] = false;
        return;
      }

      const requirements = bp_graph[target_key].requires;
      const requirement_keys = Object.keys(requirements);

      // resolve if no requirements or if already in bp
      if (requirement_keys.length === 0 && bp[target_key]) {
        // // console.log("already resolved: ", target_key, bp[target_key])
        resolved[target_key] = true;
        return true;
      }

      // // console.log("trying to resolve:", target_key)

      let requirements_resolved = true;

      // check if sub requirements are resolved
      requirement_keys.map((req_key) => {
        if (!resolved[req_key]) {
          requirements_resolved = false;
          resolve(req_key);
        }
      });

      if (requirements_resolved) {
        resolved[target_key] = true;

        let all_requirements_met = true;
        let unaccepted_value;
        let missing_key;
        requirement_keys.map((req_key) => {
          const acceptable = requirements[req_key]; // list
          const bp_val = bp[req_key];

          if (!acceptable.includes(bp_val)) {
            // // console.log(`at ${target_key}... ${bp_val} missing from ${req_key}`)
            all_requirements_met = false;
            unaccepted_value = `${req_key} == ${bp_val}`;
            missing_key = req_key;
            if (bp[target_key]) {
              bp[target_key] = acceptable;
            }
          }
        });

        if (all_requirements_met) {
          // // console.log(`All requirements met ${target_key}`)
          const default_value = bp_graph[target_key].default;
          if (!bp[target_key]) {
            bp[target_key] = default_value;
          }
        } else {
          if (bp[target_key]) {
            const flag = `FLAGGED, IMPOSSIBLE!! ${target_key} cannot exist with ${unaccepted_value}`;

            if (!bp_partial[missing_key]) {
              // // console.log(`bp_partial does NOT contain: ${missing_key} and can be populated`)
              bp[missing_key] = bp[target_key][0];
              resolved[target_key] = false;
              // bp_partial[missing_key] = requirements[missing_key][0]
              // resolve(target_key)
              // // console.log(missing_key, "->", bp[target_key][0])
              delete bp[target_key];
              resolve(target_key);
            } else {
              // // console.log(`bp_partial contains ${missing_key}`)
              bp[target_key] = flag;
            }
          }
        }
      }
    };

    for (const graph_key in bp_graph) {
      resolve(graph_key);
    }

    // return bp
    const undefined_template = {};
    for (const key in this.bp_graph) {
      undefined_template[key] = "undefined";
    }

    // console.log({ ...undefined_template, ...bp });

    return { ...undefined_template, ...bp };
  }
}

// const partial_blueprint = {
//     "metadata:name": "hello",
//     // "metadata:system_voltage":"0",
//     // "conductor:area_calculation_method":"use_number_of_strands",
//     // 'conductor:strand_diameter': 10,
//     // "design:shielding": "without_shielding",
//     "design:cable_type": "single_core_armoured",
//     // "settings:environment:critical_ground_temperature": 2,
//     "filler:density": 1,
//     // "metallic_screen:type": "sheath",
//     // "conductor:area": 200,
//     // "design:shielding": "without_shielding",
//     // "conductor_shield:material": "xlpe",
//     // "metallic_screen:strand_diameter": 20,
// }

// const bp_graph = new BpGraph()

// const bp_filled = bp_graph.fill_blueprint(partial_blueprint)
// // console.log(bp_filled)
