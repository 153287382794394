import React from "react";
import styled from "styled-components";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ScrollableContainer = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  gap: 8px;
  /* padding-top: 0px; */
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const DraggableItem = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;

const LoadingBar = styled.div`
  width: ${({ width }) => width || "100%"};
  height: 16px;
  margin: 16px;
  border-radius: 8px;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
const LoadingAnimation = () => (
  <div>
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="90%" />
    <LoadingBar width="70%" />
    <LoadingBar width="50%" />
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="90%" />
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="90%" />
    <LoadingBar width="80%" />
    <LoadingBar width="60%" />
    <LoadingBar width="90%" />
    <LoadingBar width="60%" />
    <LoadingBar width="70%" />
    <LoadingBar width="80%" />
  </div>
);

const CustomListComponent = ({ data, setData, ItemComponent, height }) => {
  const [designWarning, setDesignWarning] = React.useState(false);
  function handleOnDragEnd(result) {
    if (!result.destination) return;
  
    // Only proceed if the dragged item moved to a new index.
    if (result.source.index === result.destination.index) return;
  
    const items = Array.from(data); // Create a new array from the data prop
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    const updatedData = items.map((item, index) => ({
      ...item,
      position: index,
    }));
    
    setData(updatedData);
    
    // Warn only if the order has actually changed and we haven't already warned.
    if (!designWarning) {
      alert("You have changed the design of the cable, calculations may not accurately reflect the new design.");
      setDesignWarning(true);
    }
  }
  

  return (
    <>
      <ScrollableContainer height={`${height}px`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="data">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <DraggableItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {
                              !item.disabled && (
                                <ItemComponent
                                  item={item}
                                  setData={setData}
                                  provided={provided}
                                />
                              )
                            }
                          </DraggableItem>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <LoadingAnimation />
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ScrollableContainer>
    </>
  );
};

export default CustomListComponent;
