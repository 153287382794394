import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { proxy_url } from "../../../../../firebase";
import { getCableByName } from "../../../../../functions/functions";
import { BpGraph } from "../../../../../schema/BpGraph_Cables";
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import { StyledIconButton } from "../../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "@mui/material";
import BpModal from "../../../../cables/bp/BpModal";

const Container = styled.div`
  text-align: left;
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #00a0c6;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

function CableSummary({ loadedScenario, user, setLoadedScenario }) {
  const [loading, setLoading] = useState(true);
  const bpGraph = new BpGraph();

  const [cableCara, setCableCara] = useState(
    loadedScenario["cable:carousel"]
      ? loadedScenario["cable:carousel"]
      : {
          Name: "Three-core AR 10kV 70sqmm Al VEKTA",
          "Core Cross-Section (mm2)": 72.38,
          "Overall Diameter (m)": 83.0,
          "Weight in Air (kg/m)": 9,
          "Minimum Coiling Diameter (m)": 0.83 * 4,
          "Maximum Sidewall Pressure (kN/m)": 30,
          "Maximum Crush Load (kN/m)": 30,
          "Minimum Bend Radius (kN/m)": 30,
          "Minimum Bend Radius (Installation) (kN/m)": 30,
          "Maximum Allowable Sidewall Pressure (kN/m)": 30,
        }
  );

  const [cableInfo, setCableInfo] = useState({ open: false, cable: null });

  const handleGetCable = async (cableName) => {
    // console.log({ cableName });

    const cableData = await getCableByName("vekta", cableName);

    setCableInfo({ open: true, cable: cableData });
  };

  useEffect(() => {
    // console.log({ cableCara });
  }, [cableCara]);

  useEffect(() => {
    const updateCara = async (user) => {
      const selectedCable = [
        loadedScenario["vessels:primary_carousel_cable"],
        loadedScenario["vessels:secondary_carousel_cable"],
      ];

      // console.log({ selectedCable });

      for (let i = 0; i < Object.keys(selectedCable).length; i++) {
        const idToken = await user.getIdToken();
        const type = i === 0 ? "main" : "below";
        // const type = "main";
        let cableData = await getCableByName("vekta", selectedCable[i]);
        cableData = bpGraph.fill_blueprint(cableData);

        try {
          const outputs_res = await fetch(proxy_url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify([cableData]),
          });
          const outputs = await outputs_res.json();

          // console.log({ outputs });

          // const newCara = {};
          const newCara = {
            Name: selectedCable[i],
            "Core Cross-Section (mm2)": parseFloat(
              String(outputs[0]["conductor_area"].val).replace(/,/g, "")
            ),
            "Overall Diameter (m)":
              parseFloat(
                String(outputs[0]["external_cable_diameter"].val).replace(
                  /,/g,
                  ""
                )
              ) / 1000,
            "Weight in Air (kg/m)": parseFloat(
              String(outputs[0]["mass"].val).replace(/,/g, "")
            ),
            "Minimum Coiling Diameter (m)": parseFloat(
              String(outputs[0]["min_coiling_diameter"].val).replace(/,/g, "")
            ),
            "Maximum Crush Load (kN/m)": 30,
            "Minimum Bend Radius (m)": parseFloat(
              String(outputs[0]["min_bend_radius"].val).replace(/,/g, "")
            ),
            "Minimum Bend Radius (Installation) (m)": parseFloat(
              String(outputs[0]["min_bend_radius_installation"].val).replace(
                /,/g,
                ""
              )
            ),
            "Maximum Allowable Sidewall Pressure (kN/m)":
              outputs[0]["max_allowable_sidewall_pressure"].val,
          };

          // console.log(type);

          setCableCara((cableCara) => ({ ...cableCara, [type]: newCara }));
          setLoadedScenario((loadedScenario) => ({
            ...loadedScenario,
            ["cable:carousel"]: {
              ...loadedScenario["cable:carousel"],
              [type]: newCara,
            },
          }));
        } catch (error) {
          // console.log(error);
          setLoading(false);
        }
      }
      setLoading(false);
    };

    // console.log(cableCara);

    // // console.log(selectedCable);
    setLoading(true);
    updateCara(user);
  }, [
    loadedScenario["vessels:primary_carousel_cable"],
    loadedScenario["vessels:secondary_carousel_cable"],
  ]);

  // Render the table
  return (
    <div
    // style={{
    //   display: "flex",
    //   alignContent: "center",
    //   justifyContent: "center",
    //   height: "100%",
    //   width: "100%",
    // }}
    >
      {!loading ? (
        <Container>
          <Table>
            <tbody>
              <tr>
                <Th style={{ color: "white" }}>Cable(s) Summary</Th>
                <Th style={{ textAlign: "center" }}>
                  <StyledIconButton
                    onClick={() => handleGetCable(cableCara.main.Name)}
                  >
                    <FontAwesomeIcon color="white" icon={faInfoCircle} />
                  </StyledIconButton>
                </Th>
                {cableCara.main.Name !== cableCara.below.Name &&
                  loadedScenario["vessels:selected_vessel"].split("_")[2] ===
                    "2-Carousel" && (
                    <Th style={{ textAlign: "center" }}>
                      <StyledIconButton
                        onClick={() => handleGetCable(cableCara.below.Name)}
                      >
                        <FontAwesomeIcon color="white" icon={faInfoCircle} />
                      </StyledIconButton>
                    </Th>
                  )}
              </tr>
              {Object.keys(cableCara.main).map((row) => {
                return (
                  <tr>
                    <Td style={{ padding: "6px" }}>{row}</Td>
                    <Td
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        width: "250px",
                        maxWidth: "250px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {typeof cableCara.main[row] === "string" ||
                      cableCara.main[row] % 1 === 0
                        ? cableCara.main[row]
                        : parseFloat(cableCara.main[row]).toFixed(2)}
                    </Td>
                    {cableCara.main.Name !== cableCara.below.Name &&
                      loadedScenario["vessels:selected_vessel"].split(
                        "_"
                      )[2] === "2-Carousel" && (
                        <Td
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            width: "250px",
                            maxWidth: "250px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {typeof cableCara.below[row] === "string" ||
                          cableCara.below[row] % 1 === 0
                            ? cableCara.below[row]
                            : parseFloat(cableCara.below[row]).toFixed(2)}
                        </Td>
                      )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <ScaleLoader color="#00a0c6" />
          <label>Loading Cable Data . . .</label>
        </div>
      )}

      <Modal
        open={cableInfo.open}
        onClose={() => setCableInfo({ open: false, cable: null })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BpModal
          handleClose={() => setCableInfo({ open: false, cable: null })}
          type={"Close"}
          modalName={"Cable Details"}
          cable={cableInfo.cable}
          user={user}
          update={() => {}}
          source="collection"
        />
      </Modal>
    </div>
  );
}

export default CableSummary;
