import React, { useEffect, useState } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import DataTable from "react-data-table-component";
// import { customStyles } from "../helperFunctions";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import styled from "styled-components";
import { PillButton, SecButton } from "../../../styles/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const StyledTableCell = styled(TableCell)`
  background-color: ${(props) => {
    // Priority to header color
    if (props.header) return props.headerColor || "#e0e0e0";
    // Next, check for firstCol and secondCol
    if (props.firstCol) return props.firstColor || "none";
    if (props.secondCol) return props.secondColor || "none";
    return "none";
  }};
  color: ${(props) =>
    props.header
      ? props.black
        ? "black !important"
        : "#ffffff !important"
      : "black !important"};
  font-weight: ${(props) =>
    props.firstCol || props.header || props.secondCol
      ? "600 !important"
      : "normal !important"};
  font-size: 12px !important;
  border: 1px solid black;

  padding: ${(props) => props.compact && "8px !important"};

  width: 45px;
  max-width: 45px;
  min-width: 45px;

  // Set max-width and text wrapping for the first column cells
  ${(props) =>
    props.firstCol &&
    `
    width: 80px; // or any other fixed width
    max-width: 80px; // Ensure it doesn't exceed the specified width
    min-width: 80px; // Ensure it doesn't go below the specified width
    max-height: 40px;
    white-space: wrap;
    // word-wrap: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
  `}

  ${(props) =>
    props.secondCol &&
    `
    width: 30px; // Adjust the width as needed
    max-width: 30px;
    min-width: 30px;
  `}

  // Add this for header cells to wrap text and have a max width
  ${(props) =>
    props.header &&
    `
    white-space: normal;
    word-wrap: break-word;
  `}
`;

const StyledTableRow = styled(TableRow)`
  /* &:nth-of-type(odd) {
    background-color: ${(props) =>
    !props.header ? "#f7f7f7" : "none"}; // Light grey color for odd rows
  } */
  /* &:not(:last-child) { */
  border-bottom: 1px solid black; // Optionally remove the bottom border from the rows to avoid double borders
  /* } */
`;

const Th = styled.th`
  padding: 8px;
  background-color: #00a0c6;
  text-align: left; /* Center the column headings */
  color: white;
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

function VesselSummary({ title, cables, capacities, vessel, style }) {
  const cara = [
    "Main",
    ...(vessel && vessel.split("_")[2] === "2-Carousel"
      ? ["Below", "Combined"]
      : []),
  ];

  const [vesselCap, setVesselCap] = useState([]);

  useEffect(() => {
    if (capacities.caps) {
      // console.log(capacities);
      // console.log(cables);
      // // console.log(vessel.split("_"));

      setVesselCap([
        {
          cable: cables[0],
          main_cap: capacities.caps[0].Main,
          main_weight: capacities.weight[0].Main,
          main_limit: capacities.limits[0].Main,
          ...(vessel && vessel.split("_")[2] === "2-Carousel"
            ? {
                below_cap: capacities.caps[0].Below,
                below_weight: capacities.weight[0].Below,
                below_limit: capacities.limits[0].Below,
                combo_cap: capacities.caps[0].Combined,
                combo_weight: capacities.weight[0].Combined,
                combo_limit: capacities.limits[0].Combined,
              }
            : {}),
        },
        ...(cables[0] !== cables[1]
          ? [
              {
                cable: cables[1],
                main_cap: capacities.caps[1].Main,
                main_weight: capacities.weight[1].Main,
                main_limit: capacities.limits[1].Main,
                ...(vessel && vessel.split("_")[2] === "2-Carousel"
                  ? {
                      below_cap: capacities.caps[1].Below,
                      below_weight: capacities.weight[1].Below,
                      below_limit: capacities.limits[1].Below,
                      combo_cap: capacities.caps[1].Combined,
                      combo_weight: capacities.weight[1].Combined,
                      combo_limit: capacities.limits[1].Combined,
                    }
                  : {}),
              },
            ]
          : []),
      ]);
    }
  }, [capacities]);

  return (
    <div>
      {/* <div className="ag-theme-quartz">
        <AgGridReact
          rowData={vesselCap}
          columnDefs={colDefs}
          pagination={false}
          domLayout="autoHeight"
          autoSizeStrategy={{
            type: "fitGridWidth",
            defaultMinWidth: 100,
          }}
          // onCellValueChanged={handleEditCell}
          overlayNoRowsTemplate="No Operations"
        />
      </div> */}
      {title}

      <Container>
        <TableContainer
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            height: style.height,
          }}
          component={Paper}
        >
          <Table
          // border="1"
          // style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell header headerColor={style.colour}>
                  Carousel Limits
                </StyledTableCell>
                <StyledTableCell header headerColor={style.colour}>
                  Main Carousel Cable
                </StyledTableCell>
                {cables[0] !== cables[1] && vessel.split("_")[2] === "2-Carousel" && (
                  <StyledTableCell header headerColor={style.colour}>
                    Below Cable
                  </StyledTableCell>
                )}
              </StyledTableRow>
            </TableHead>

            <TableBody>
              <StyledTableRow style={{ background: "hsla(0,0%,83%,.329)" }}>
                <StyledTableCell header black colSpan={3}>
                  Max cable length (km)
                </StyledTableCell>
              </StyledTableRow>
              {capacities?.caps &&
                cara.map((key, index) => {
                  return (
                    key !== "Cable" && (
                      <StyledTableRow>
                        <StyledTableCell>{key}</StyledTableCell>
                        <StyledTableCell>
                          {capacities?.caps[0][key]}
                        </StyledTableCell>
                        {cables[0] !== cables[1] && vessel.split("_")[2] === "2-Carousel" && (
                          <StyledTableCell>
                            {capacities?.caps[1][key]}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  );
                })}
              <StyledTableRow style={{ background: "hsla(0,0%,83%,.329)" }}>
                <StyledTableCell header black colSpan={3}>
                  Max cable weight (t)
                </StyledTableCell>
              </StyledTableRow>
              {capacities?.weight &&
                cara.map((key, index) => {
                  return (
                    key !== "Cable" && (
                      <StyledTableRow>
                        <StyledTableCell>{key}</StyledTableCell>
                        <StyledTableCell>
                          {capacities?.weight[0][key]}
                        </StyledTableCell>
                        {cables[0] !== cables[1] && vessel.split("_")[2] === "2-Carousel" && (
                          <StyledTableCell>
                            {capacities?.weight[1][key]}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  );
                })}
              <StyledTableRow style={{ background: "hsla(0,0%,83%,.329)" }}>
                <StyledTableCell header black colSpan={3}>
                  Load limit factor
                </StyledTableCell>
              </StyledTableRow>
              {capacities?.limits &&
                cara.map((key, index) => {
                  return (
                    key !== "Cable" && (
                      <StyledTableRow>
                        <StyledTableCell>{key}</StyledTableCell>
                        <StyledTableCell>
                          {capacities?.limits[0][key]}
                        </StyledTableCell>
                        {cables[0] !== cables[1] && vessel.split("_")[2] === "2-Carousel" && (
                          <StyledTableCell>
                            {capacities?.limits[1][key]}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}

export default VesselSummary;
