// Import necessary modules and components
import { Box, Divider, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CloseIcon from "@mui/icons-material/Close";
import { InputText } from "primereact/inputtext";
import SearchBar from "../../../common/SearchBar";

import Select from "react-select";
import {
  getCompanyIds,
  getUserPermissions,
  uploadCable,
} from "../../../functions/functions";
import { onAuthStateChanged } from "@firebase/auth";
import { analytics, auth, storage } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import { PrimeButton, SecButton } from "../../../styles/styles";
import { BpGraph } from "../../../schema/BpGraph_Cables";

import { prettierName } from "../../../common/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getDownloadURL, ref } from "firebase/storage";

import { InputNumber } from "primereact/inputnumber";
import { logEvent } from "firebase/analytics";

// Styled components for various UI elements
const CableRow = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 8px;
  background-color: white;
  color: black;
`;

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const SpanTitle = styled("span")`
  width: 35%;
  height: 3rem;
  background-color: ${(props) =>
    props.missing
      ? "rgb(206, 69, 69)"
      : props.required
      ? "#009ec6"
      : "#f1f1f1"} !important;
  color: ${(props) =>
    props.missing ? "white" : props.required ? "white" : "black"} !important;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 55%;

  input {
    width: 100%;
    height: 3rem;
    font-family: "Montserrat";
    font-weight: medium;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 4px;
    color: hsl(0, 0%, 20%);
  }
`;

const inputStyle = {
  width: "55%",
  height: "3rem",
  fontFamily: "Montserrat",
  fontWeight: "medium",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "10px",
  borderRadius: "4px",
  color: "hsl(0, 0%, 20%)",
};

const CableSpecHeader = styled("header")`
  background: #009ec6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 8px;

  h4 {
    margin: 0;
  }
`;

// Main component function
function BpModal({
  handleClose,
  type,
  modalName,
  cable,
  user,
  update,
  source,
  update_global_settings = null,
  update_cable_settings = null,
}) {
  
  // State variables
  const [cableAction, setCableAction] = useState(type);
  const [editCable, setEditCable] = useState(
    type === "Save" || type === "Close"
  );
  const [cableSpecs, setCableSpecs] = useState({});
  const [partial, setPartial] = useState({});
  const [filtered, setFiltered] = useState(partial);
  const [nextSteps, setNextSteps] = useState(partial);
  const [cableDiagram, setCableDiagram] = useState({
    src: "./media/cable_diagrams/three_core_ar_1_hollow.png",
    alt: "Three-Core-Armoured Cable",
  });
  const [expanded, setExpanded] = useState({ group: [null] });

  const bpGraph = new BpGraph();
  const filled_cable = bpGraph.fill_blueprint(cable);

  // Load initial data
  useEffect(() => {
    const loaded_partial = {};
    const bp_graph = { ...bpGraph.bp_graph };
    if (!cable) {
      setEditCable(false);
      return;
    }
    Object.keys(bp_graph).map((key) => {
      if (cable[key]) {
        loaded_partial[key] = cable[key];
      }
    });
    if (
      source === "cable_settings" &&
      Object.keys(loaded_partial).length === 0
    ) {
      setPartial({ "settings:environment:type": "direct_in_ground" });
    } else {
      setPartial(loaded_partial);
    }
  }, []);

  // Update cable specifications
  useEffect(() => {
    const bp_graph = { ...bpGraph.bp_graph };
    const loaded_specs = {};
    if (filtered) {
      Object.keys(filtered).map((key) => {
        loaded_specs[key] = { ...bp_graph[key], value: partial[key] };
      });
    }
    setCableSpecs(loaded_specs);
  }, [filtered]);

  // Update next steps and filtered data
  useEffect(() => {
    const next_steps = bpGraph.get_next_steps(partial);
    setNextSteps(next_steps);
    setFiltered(partial);

    if (partial["design:cable_type"])
      handleImageChange(
        partial["design:cable_type"],
        partial["armour:number_of_layers"],
        partial["filler:shape"]
      );
  }, [partial]);

  // Update filtered data based on edit mode
  useEffect(() => {
    if (!editCable) {
      setFiltered(nextSteps);
    } else {
      setFiltered(partial);
    }
  }, [editCable, nextSteps, partial]);

  // Disable edit mode for cable settings
  useEffect(() => {
    if (source === "cable_settings") {
      setEditCable(false);
    }
  }, []);

  // Handle filter changes
  const handleFilter = (filters) => {
    const temp = {};

    if (filters.length === 0) {
      setFiltered(nextSteps);
      return;
    }

    for (let i = 0; i < filters.length; i++) {
      temp[filters[i].value] = cableSpecs[filters[i].value];
    }

    setFiltered(temp);
  };

  // Format input for select options
  const formatCreateInput = (names, spec = null) => {
    const formatted = [];
    for (let i = 0; i < names.length; i++) {
      formatted.push({
        value: names[i],
        label: prettierName(names[i]),
        spec: spec,
      });
    }

    return formatted;
  };

  // Handle different actions (Close, Save)
  const handleAction = (action, partial) => {
    switch (action) {
      case "Close":
        handleClose();
        break;
      case "Save":
        handleSave(partial);
        break;
      default:
        console.log(action);
        break;
    }
  };

  // Save cable data
  const handleSave = async (partial) => {
    if (user) {
      const blueprint = { ...cable, ...partial }; // with id
      uploadCable(blueprint, user, update);
      logEvent(analytics, "select_content", {
        content_type: "Cable Creation",
      });
      handleClose();
    }
  };

  // Handle expand/collapse of groups
  const handleExpand = (group, e) => {
    if (expanded.group.indexOf(group) === -1) {
      setExpanded((expanded) => ({ group: [...expanded.group, group] }));
    } else {
      const temp = expanded.group;
      temp.splice(expanded.group.indexOf(group), 1);
      setExpanded({ group: temp });
    }
  };

  // Handle image change based on cable type
  const handleImageChange = (type, n_armour_layers, filler_shape) => {
    let prefix = "";

    if (
      type === "single_core" ||
      type === "dc_cable" ||
      type === "dc_cable_with_metallic_screen"
    ) {
      prefix = "single_core_ar_0";
    } else if (
      type === "single_core_armoured" ||
      type === "dc_cable_armoured" ||
      type === "dc_cable_armoured_with_metallic_screen"
    ) {
      prefix = `single_core_ar_${n_armour_layers || 1}`;
    } else if (type === "three_core") {
      prefix = `three_core_ar_0`;
    } else if (type === "three_core_armoured") {
      prefix = `three_core_ar_${n_armour_layers || 1}`;
    }
    let shape = "";
    let name = `${prefix}.png`;
    if (type.includes("three_core")) {
      shape = "hollow";
      if (filler_shape === "circle_packed") {
        shape = "packed";
      } else if (filler_shape === "solid_fit") {
        shape = "solid";
      } else if (filler_shape === "hollow_fit") {
        shape = "hollow";
      }
      name = `${prefix}_${shape}.png`;
    }

    setCableDiagram({ src: "./media/cable_diagrams/" + name, alt: type });
  };

  // Handle cable value change
  const handleCableChange = (e, min, max, input) => {
    let value = e.target.value;
    let newPartial;

    if (value > max) {
      value = max;
    } else if (value < min) {
      value = min;
    }
    newPartial = { ...partial, [input]: value };

    setPartial(newPartial);
  };

  // Render a single cable row
  const renderCableRow = (input) => {
    const handleCableSelectChange = (e) => {
      const value = e.value;
      const newPartial = { ...partial, [input]: value };
      setPartial(newPartial);
    };

    const isSetting = input.includes("settings");

    if (source === "cable_settings") {
      if (!isSetting) {
        return;
      }
    } else {
      if (isSetting) {
        return;
      }
    }

    const required = cableSpecs[input]?.required || false;
    const filter_required = filtered[input].required || false;
    const filtered_new = filtered[input].new || false;
    const unit = bpGraph.bp_graph[input]?.unit || false;
    if (source === "cable_settings") {
      cable = filled_cable;
    }
    let defaulted =
      cable &&
      partial &&
      cable[input] &&
      partial[input] &&
      !bpGraph.bp_graph[input].required_default;

    let border = "";
    if (filtered_new) {
      border = "3px solid #E91E63";
    }
    if (defaulted) {
      border = "3px solid #4CAF50";
    }

    const placeholder = partial[input]
      ? partial[input]
      : `Default - ${prettierName(bpGraph.bp_graph[input].default)}`;
    const default_value = partial[input]
      ? partial[input]
      : bpGraph.bp_graph[input].default;

    if (bpGraph.bp_graph[input].required_default) {
      if (bpGraph.bp_graph[input].options && partial[input] === undefined) {
        const value = bpGraph.bp_graph[input].required_default;
        const newPartial = { ...partial, [input]: value };
        setPartial(newPartial);
      }
    }

    const min_max = {
      min: bpGraph.bp_graph[input].min,
      max: bpGraph.bp_graph[input].max,
    };

    const has_min_max = min_max.min !== undefined && min_max.max !== undefined;
    const text_input_default = has_min_max
      ? `min-${min_max.min} max-${min_max.max}`
      : "Enter Value . . .";
    const options = bpGraph.bp_graph[input].options || [];
    let customStyles = {};

    return (
      <CableRow className="cableRow">
        <SpanTitle
          style={{
            border: border,
          }}
          required={required}
          missing={required && cableSpecs[input].value === null}
        >
          {prettierName(input)} {filter_required && ""} {filtered_new ? "" : ""}
        </SpanTitle>
        {editCable && source !== "cable_settings" ? (
          <InputText
            readOnly
            id={input}
            disabled={false}
            value={prettierName(partial[input]) && prettierName(partial[input])} // Needs to be value to get the right value ???
            defaultValue={default_value}
            placeholder={prettierName(placeholder)}
            style={inputStyle}
          />
        ) : options.length > 0 ? (
          <Select
            className="cableSelect"
            classNamePrefix="select"
            placeholder={prettierName(placeholder)}
            isSearchable
            styles={customStyles}
            isDisabled={false} // used to be editCable handled
            value={prettierName(default_value)}
            defaultValue={default_value}
            onChange={(e) => handleCableSelectChange(e)}
            options={formatCreateInput(options, input)}
          />
        ) : !bpGraph.bp_graph[input].type ? (
          <InputText
            id={input}
            disabled={false}
            defaultValue={prettierName(default_value)}
            placeholder={prettierName(text_input_default)}
            onChange={(e) =>
              handleCableChange(
                e,
                bpGraph.bp_graph[input].min,
                bpGraph.bp_graph[input].max,
                input
              )
            }
            style={inputStyle}
          />
        ) : bpGraph.bp_graph[input].type === "number" ? (
          <StyledInputNumber
            id={input}
            disabled={false}
            defaultValue={default_value}
            value={partial[input] && partial[input]}
            placeholder={
              text_input_default + " (Default " + default_value + ")"
            }
            title={text_input_default}
            min={min_max.min}
            max={min_max.max}
            minFractionDigits={unit === "qty" ? 0 : 1}
            maxFractionDigits={unit === "qty" ? 0 : 5}
            onValueChange={(e) =>
              handleCableChange(
                { target: e },
                bpGraph.bp_graph[input].min,
                bpGraph.bp_graph[input].max,
                input
              )
            }
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "55%", fontFamily: "Montserrat !important" }}
              inputFormat="dd-MM-yyyy"
              minDate={dayjs("2018-01-01")}
              maxDate={dayjs("2024-12-01")}
              defaultValue={partial[input] && dayjs(partial[input])}
              onChange={(value) => {
                const month = String(Number(value.$M) + 1).padStart(2, "0");
                const year = value.$y;
                const dateString = { value: `${year}-${month}` };

                handleCableSelectChange(dateString);
              }}
              views={["month", "year"]}
            />
          </LocalizationProvider>
        )}
        {unit ? (
          <SpanTitle style={{ width: "10%" }}>{unit}</SpanTitle>
        ) : (
          <div style={{ width: "10%" }}></div>
        )}
      </CableRow>
    );
  };

  // Create a list of cable rows
  const createCableList = (filtered) => {
    return Object.keys(filtered).map((input) => {
      return renderCableRow(input);
    });
  };

  // Create a grouped list of cable rows
  const createGroupedCableList = (filtered) => {
    const groups = {};
    Object.keys(filtered).map((input) => {
      if (!input.includes("settings")) {
        return;
      }

      const delimiters = [":", "_"];
      let input_split = input.split(delimiters[0]);

      // remove group prefix
      const prefixes = ["settings", "design", "metadata"];
      input_split.map((split) => {
        if (prefixes.includes(split)) {
          input_split.shift();
        }
      });
      const group = input_split[0];

      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(input);
    });

    return Object.keys(groups).map((group) => {
      return (
        <div
          style={{
            marginBottom: "8px",
            marginRight: "8px",
            border: "2px solid #009ec6",
            borderRadius: "8px",
          }}
        >
          <CableSpecHeader>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledIconButton onClick={(e) => handleExpand(group)}>
                <FontAwesomeIcon
                  color="white"
                  icon={
                    expanded.group.indexOf(group) !== -1
                      ? faChevronDown
                      : faChevronUp
                  }
                />
              </StyledIconButton>
              <h4 style={{ color: "white" }}>
                {group.charAt(0).toUpperCase() + group.slice(1)}
              </h4>
            </div>
          </CableSpecHeader>
          <div
            style={
              expanded.group.indexOf(group) !== -1
                ? { display: "none" }
                : { padding: "8px" }
            }
          >
            <CableRow
              key={uuidv4()}
              style={{ backgroundColor: "white", fill: "black" }}
            >
              <label
                key={uuidv4()}
                style={{ width: "35%", textAlign: "left", fontWeight: "600" }}
              >
                Specification Name
              </label>
              <label
                key={uuidv4()}
                style={{ width: "55%", textAlign: "left", fontWeight: "600" }}
              >
                Value
              </label>
              <label
                key={uuidv4()}
                style={{ width: "10%", textAlign: "left", fontWeight: "600" }}
              >
                Unit
              </label>
            </CableRow>
            {groups[group].map((input) => {
              return renderCableRow(input);
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="cableModal"
      id="cableModal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          maxHeight: "90vh",
          position: "fixed",
          inset: source !== "cable_settings" ? "10% 10%" : "10% 20%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <header>
          <h2>{modalName}</h2>
          <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
          {source !== "cable_settings" && (
            <SearchBar
              placeholder={"Search for Details e.g Name, Voltage . . ."}
              infoTitle={"Cable Collection"}
              items={formatCreateInput(Object.keys(cableSpecs || {}))}
              handleFilter={handleFilter}
            />
          )}
          <CloseModal onClick={handleClose}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>
        </header>

        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            minHeight: "300px",
            height: "78%",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              // minHeight: "300px",
              // height: "78%",
              marginBottom: "16px",
              marginTop: "16px",
              width: source !== "cable_settings" ? "55%" : "100%",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                height: "100%",
                paddingBottom: "100px",
              }}
            >
              {source.includes("settings")
                ? createGroupedCableList(filtered)
                : [
                    <CableRow
                      key={uuidv4()}
                      style={{
                        backgroundColor: "white",
                        fill: "black",
                        position: "sticky",
                        top: "0px",
                        zIndex: "1",
                      }}
                    >
                      <label
                        key={uuidv4()}
                        style={{
                          width: "35%",
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        Specification Name
                      </label>
                      <label
                        key={uuidv4()}
                        style={{
                          width: "55%",
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        Value
                      </label>
                      <label
                        key={uuidv4()}
                        style={{
                          width: "10%",
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        Unit
                      </label>
                    </CableRow>,
                    createCableList(filtered),
                  ]}
            </div>
          </div>

          {source !== "cable_settings" && (
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
              }}
            >
              <img
                width={"100%"}
                src={cableDiagram.src}
                alt={cableDiagram.alt}
              />
            </div>
          )}
        </div>

        <footer>
          {type === "Save" && (
            <SecButton
              className="editAction"
              onClick={() => {
                setEditCable(!editCable);
              }}
            >
              {editCable ? "Edit" : "View"}
            </SecButton>
          )}
          <PrimeButton
            onClick={() => {
              handleClose();
              handleAction(cableAction, partial);
              if (update_global_settings) {
                const partial_settings = {};
                Object.keys(partial).map((key) => {
                  if (key.includes("settings")) {
                    partial_settings[key] = partial[key];
                  }
                });
                update_global_settings(partial_settings);
              }

              if (update_cable_settings) {
                const partial_settings = {};
                Object.keys(partial).map((key) => {
                  if (key.includes("settings")) {
                    partial_settings[key] = partial[key];
                  }
                });
                update_cable_settings(partial_settings);
              }
            }}
          >
            {cableAction}
          </PrimeButton>
        </footer>
      </Box>
    </div>
  );
}

export default BpModal;
