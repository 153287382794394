import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { TbRefresh, TbInfoCircle } from "react-icons/tb";
import { MdClose } from "react-icons/md"; // import the close icon
import CableLablePNG from "../images/cable_label.png";

const NameBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: #5a5a5a;
  border-bottom: 1px solid lightgray;
  max-height: fit-content;
  padding: 8px;
  font-size: 1rem;
  margin-bottom: 8px;
`;

const RightIcons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const DesignerTitle = styled.h1``;

const NoteIcon = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;

// Full-screen modal overlay with the image container positioned over the canvas
const Modal = ({ children, onClose }) => {
  const [canvasRect, setCanvasRect] = useState(null);

  useEffect(() => {
    const canvas = document.getElementById("cable2d");
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      setCanvasRect({
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  if (!canvasRect) return null;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed", // full screen overlay
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999999,
        background: "rgba(0, 0, 0, 0.32)", // dark overlay
      }}
      onClick={onClose} // clicking anywhere on the overlay closes the modal
    >
      <div
        style={{
          position: "absolute",
          margin: 16,
          top: canvasRect.top - 16,
          left: canvasRect.left - 16,
        }}
        onClick={(e) => e.stopPropagation()} // prevent clicks inside from closing the modal
      >
        {/* Image container */}
        <div
          style={{
            width: "fit-content",
            height: canvasRect.height,
            borderRadius: `${canvasRect.height / 2}px 8px 8px ${canvasRect.height / 2}px`, // semi-circular on left side
            backgroundColor: "white",
            outline: "32px solid white", // white border outward using outline
            overflow: "hidden",
          }}
        >
          {children}
        </div>
        {/* Circular close button, centered below the image container */}
        <div
          style={{
            marginTop: 34,
            width: "40px",
            transform: "translateX(-20px)", // center the button horizontally
            height: "40px",
            borderRadius: "50%", // makes it a circle
            background: "#2b2b2b", // white background
            cursor: "pointer",
            fontSize: "1.5rem",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "32px auto 0", // centers the button horizontally
          }}
          onClick={onClose}
        >
          <MdClose /> {/* Black close icon */}
        </div>
      </div>
    </div>,
    document.body
  );
};

const NotesBar = ({ title, reset_cable }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <NameBarContainer>
        <DesignerTitle style={{ fontSize: "1.5rem" }}>
          {title}
        </DesignerTitle>
        <RightIcons>
          <NoteIcon onClick={() => setShowModal(true)}>
            <TbInfoCircle />
          </NoteIcon>
          <NoteIcon onClick={reset_cable}>
            <TbRefresh />
          </NoteIcon>
        </RightIcons>
      </NameBarContainer>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <img
            src={CableLablePNG}
            alt="Cable Label"
            style={{
              height: "100%", // same height as canvas (minus outline)
              width: "auto",  // preserve aspect ratio
              objectFit: "contain",
              objectPosition: "left", // align image's left edge with container
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default NotesBar;
