import React from "react";
import counterIconOne from "../assets/images/icon/counter-icon-1.svg";
import counterIconTwo from "../assets/images/icon/counter-icon-2.svg";
import counterIconThree from "../assets/images/icon/counter-icon-3.svg";
import counterIconFour from "../assets/images/icon/counter-icon-4.svg";
import CounterUpCom from "../lib/CounterUpCom.jsx";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase.js";

function CounterArea({ style }) {
  const handleDownloadValidation = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "validation_docs/VCAB-VGED-TEC-001.pdf"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "CIGRE-Validation.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };
  return (
    <>
      <section
        className="appie-counter-area pt-90 pb-190"
        id="counter-vd"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title">
                <h3 className="appie-title">Technical validation</h3>
                <p>
                  We validate the outputs against IEC 60287, CIGRE TB 908 and TB
                  880, for more details please{" "}
                  <a href="#" onClick={handleDownloadValidation}>
                    download
                  </a>{" "}
                  our validation report
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconOne} alt="" />
                  </div> */}
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom
                        endValue={1000}
                        sectionSelect="counter-vd"
                      />
                    </span>
                    +
                  </h3>
                  <p>Access trusted cable data</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconTwo} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Define project parameters</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconThree} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Run custom analysis</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconFour} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Export multiple formats</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CounterArea;
