import { getVesselByName } from "../../functions/functions";
import cablecrousel from "./data/cableCrousel.json";
import { v4 as uuidv4 } from "uuid";
import { parse } from "date-fns";

const apiHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const formatTable = (tableInfo) => {
  const formatted = tableInfo.map((row) => {
    const temp = {};

    Object.keys(row).map((item) => {
      temp[item] = row[item].value;
    });

    return temp;
  });

  return formatted;
};

export const produceSchedule = async (
  assignedCable,
  exportCables,
  vesselSelected,
  caraCable,
  minLength
) => {
  if (assignedCable.main === null) return null;
  if (exportCables.length === 0) return null;

  const hostURL = "https://cabcon-hiibq2h6dq-ew.a.run.app";
  const capacity_out = await getCapacity(vesselSelected.data, caraCable);
  capacity_out.caps[0].Cable = assignedCable.main;
  capacity_out.caps[1].Cable = assignedCable.below;

  if (capacity_out.caps[0].Below === 0 || capacity_out.caps[1].Below === 0) {
    capacity_out.caps[0].Below = 1;
    capacity_out.caps[1].Below = 1;
  }

  // setCapacities(capacity_out);

  const listIn2 = {
    exportCables: formatTable(exportCables),
    capacities: capacity_out.caps,
    cablesSelected: assignedCable,
    caraCable: [caraCable.main, caraCable.below],
    minLength: minLength,
  };

  // console.log({ listIn2 });

  const installProg_res = await fetch(`${hostURL}/getInstallationProgram`, {
    method: "POST",
    headers: apiHeaders,
    body: JSON.stringify(listIn2),
  }).catch((error) => {
    // console.log(error);
    return 0;
  });
  const installProg_out = await installProg_res.json();

  // setSiteExportSections(installProg_out);
  return { export: installProg_out, capacity: capacity_out };
  // return 0;
};

export const getCapacity = async (vesselSelected, caraCable) => {
  const hostURL = "https://cabcon-hiibq2h6dq-ew.a.run.app";
  const vessel = await getVesselByName(
    "vekta",
    vesselSelected.replaceAll(" ", "_")
  );

  const listIn = {
    ves: vessel.weight,
    caro: vessel.carousel.top,
    cable: caraCable.main,
    caro2: vessel?.carousel?.bottom ? vessel.carousel.bottom : [0, 0, 0, 0],
    cable2: caraCable.below,
  };

  const capacity_res = await fetch(`${hostURL}/getCapacity`, {
    method: "POST",
    headers: apiHeaders,
    body: JSON.stringify(listIn),
  }).catch((error) => {
    // console.log(error);
    return 0;
  });

  const capacity_out = await capacity_res.json();
  // console.log(capacity_out);

  return capacity_out;
};

export const handleAddScenario = async (
  scenario,
  setBatchData,
  setBatchSummary,
  addToBatch = false
) => {
  const scenarioName = scenario["site:name"];
  // console.log(scenarioName);

  if (scenarioName) {
    const cables = {
      main: scenario["vessels:primary_carousel_cable"],
      below: scenario["vessels:secondary_carousel_cable"],
    };

    const exports = scenario["site:exports"];

    // console.log(exports);

    const vessel = {
      data: scenario["vessels:selected_vessel"],
      speed_out: scenario["vessels:laden_speed"],
      speed_back: scenario["vessels:unladen_speed"],
      layRate: scenario["vessels:lay_rate"],
      loadRate: scenario["vessels:load_rate"],
    };

    const weather = {
      HDD: scenario["site:HDD_weather_point"],
      OSP: scenario["site:OSP_weather_point"],
      lay: scenario["site:export_cable_lay_weather_point"],
    };

    const hdds = Array.from({ length: 1 }, (_, i) => ({
      End: `Landfall ${i + 1}`,
    }));
    const osps = Array.from({ length: 2 }, (_, i) => ({
      End: `OSP ${i + 1}`,
    }));
    const endPoints = [...hdds, ...osps];

    const schedule = await produceSchedule(
      cables,
      exports,
      vessel,
      scenario["cable:carousel"],
      scenario["site:minimum_cable_section_length"]
    );

    if (schedule === 0) return;

    // console.log({ schedule });

    const date = parse(
      scenario["site:start_date"].replaceAll("/", "-"),
      "dd-MM-yyyy",
      new Date()
    );

    const listIn = {
      startDate: date,
      weatherPoints: { ...weather },
      site_endPoints: endPoints,
      site_exportSections: {
        loadOut: schedule.export["loadOut"].exportSummary,
        connector: schedule.export["connector"].exportSummary,
      },
      site_loadOutSummary: {
        loadOut: schedule.export["loadOut"].loadOutSummary,
        connector: schedule.export["connector"].loadOutSummary,
      },
      cablesSelected: { ...cables },
      vesselName: vessel.data,
      speed_out: vessel.speed_out,
      speed_back: vessel.speed_back,
      layRate: vessel.layRate,
      loadRate: vessel.loadRate,
      distance: {
        hdd: scenario["site:distance_from_HDD"],
        osp: scenario["site:distance_from_OSP"],
      },
      capacities: schedule.capacity,
      loads: {
        loadOut: schedule.export["loadOut"].noLoads,
        connector: schedule.export["connector"].noLoads,
      },
      connector: {
        loadOut: schedule.export["loadOut"].connector,
        connector: schedule.export["connector"].connector,
      },
    };

    const id = uuidv4();
    const newData = {
      Name: scenarioName,
      Vessel: vessel.data,
      Start: date,
      Length: schedule.export[
        scenario["site:minimise_by"]
      ].exportSummary.reduce((total, section) => total + section.Length, 0),
      weather: scenario["site:scenario_setting"],
      id: id,
    };

    if (addToBatch) {
      setBatchData((prevBatchData) => ({
        ...prevBatchData,
        [id]: { listIn, results: [], scenario, scenarioName: scenarioName },
      }));

      setBatchSummary((prevBatchSummary) => {
        if (prevBatchSummary.length < 5) {
          return [...prevBatchSummary, newData];
        } else {
          return [...prevBatchSummary];
        }
      });
    }

    return { listIn, scenario, loaded: false };
  }

  return { loaded: false };
};

export const customStyles = {
  table: {
    style: {
      height: "inherit",
      border: "2px solid black",
    },
  },
  rows: {
    style: {
      minHeight: "72px", // override the row height
      // backgroundColor: "#f2fcff",
      borderRadius: "0 0 8px 8px",
    },
  },
  header: {
    style: {
      whiteSpace: "pre-line !important",
    },
  },
  head: {
    style: {
      whiteSpace: "pre-line !important",
    },
  },
  headCells: {
    style: {
      // paddingLeft: "8px", // override the cell padding for head cells
      // paddingRight: "8px",
      color: "white",
      fontWeight: "600",
      fontSize: "small",
      justifyContent: "center",
      whiteSpace: "pre-line !important",
    },
  },
  headRow: {
    style: {
      // borderRadius: "8px 8px 0 0",
      background: "#00A0C6",
      whiteSpace: "pre-line !important",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      justifyContent: "center",
      whiteSpace: "pre-line !important",
    },
  },
};

export const handleDateFormat = (before) => {
  const date = new Date(before);

  if (date.getFullYear() !== 1970) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  }

  return before;
};
