import { Box, Divider } from "@mui/material";
import React, { useRef, useState } from "react";
import { StyledIconButton } from "../../../../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { prettierName } from "../../../../../common/helperFunctions";
import GenericTable from "../../../common/GenericTable";
import EditTable from "../../../../../media/tutorials/edit-tables-vessel.gif";

import Joyride, { STATUS } from "react-joyride";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";

const CloseModal = styled("div")`
  position: absolute;
  right: 16px;
  top: 16px;
`;

function VesselOperations({
  loadedScenario,
  bpGraph,
  setVesselOperations,
  handleUpdateScenario,
}) {
  const editTables = useRef(null);
  const [runJoyride, setRunJoyride] = useState(false);
  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Edited Table</h2>
            <img width={"100%"} src={EditTable} />
            <p style={{ marginTop: "16px" }}>
              Edit tables the same way they can be editing in Excel - Double
              click the cell you wish to edit
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "center",
        target: "#table-edits-vessel",
        disableBeacon: true,
        styles: {
          options: {
            width: 500,
          },
        },
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const handleTutorial = (name) => {
    setRunJoyride(true);
  };

  return (
    <div
      className="cableModal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#122F76",
            zIndex: 10000,
          },
        }}
      />
      <Box
        id="scenarioModal"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          padding: 2,
          // maxHeight: "90vh",
          position: "fixed",
          inset: "10% 20%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <header style={{ gridArea: "1 / 1 / 2 / 7" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <h2>Vessel Operations</h2>
            {/* <StyledIconButton onClick={handleTutorial}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </StyledIconButton> */}
            <StyledIconButton onClick={(e) => editTables.current.toggle(e)}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </StyledIconButton>
            <OverlayPanel
              ref={editTables}
              id="editTables"
              style={{
                padding: "8px",
                fontFamily: "Montserrat",
                zIndex: "2000 !important",
              }}
              className="edit-table-overlay"
            >
              <div>
                <h3>Edited Table</h3>
                <img width={"80%"} src={EditTable} />
                <p style={{ marginTop: "16px" }}>
                  Edit tables the same way they can be editing in Excel -
                  <br></br>{" "}
                  <strong>Double click the cell you wish to edit</strong>
                </p>
              </div>
            </OverlayPanel>
          </div>
          <Divider style={{ marginBottom: "16px" }} sx={{ bgcolor: "black" }} />
          <CloseModal onClick={() => setVesselOperations(false)}>
            <StyledIconButton>
              <CloseIcon />
            </StyledIconButton>
          </CloseModal>
        </header>

        <div
          id="table-edits-vessel"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
            height: "90%",
            overflowY: "scroll",
          }}
        >
          {Object.keys(loadedScenario).map((input) => {
            if (input.includes("vessel_ops")) {
              // console.log(loadedScenario[input]);

              return (
                <div>
                  <h4>
                    {bpGraph[input].name
                      ? bpGraph[input].name
                      : prettierName(input)}
                  </h4>
                  <GenericTable
                    tableInfo={loadedScenario[input]}
                    input={input}
                    order={[
                      "title",
                      "duration",
                      "window",
                      "hs",
                      "ws10",
                      ...(Object.keys(loadedScenario[input][0]).includes(
                        "TpMin"
                      ) &&
                      Object.keys(loadedScenario[input][0]).includes("TpMax")
                        ? ["TpMin", "TpMax"]
                        : []),
                    ]}
                    pagination={false}
                    handleUpdateScenario={handleUpdateScenario}
                  />
                </div>
              );
            }
          })}
        </div>
      </Box>
    </div>
  );
}

export default VesselOperations;
