import React, { useEffect, useState } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";

import DataTable from "react-data-table-component";
import { customStyles } from "../helperFunctions";

function SiteExportSectionsTable({ siteExportSections = [] }) {
  const [installProg, setInstallProg] = useState([]);

  useEffect(() => {
    if (siteExportSections.length > 0) {
      // console.log("__________________________");
      
      // console.log({siteExportSections});
    }
  }, [siteExportSections]);

  const columns = [
    {
      name: <div style={{ padding: "8px" }}>Cable ID</div>,
      grow: 0.5,
      selector: (row) => row.ids,
    },
    {
      name: <div style={{ padding: "8px" }}>End Point 1</div>,
      grow: 0.5,
      selector: (row) => row["End 1"],
    },
    {
      name: <div style={{ padding: "8px" }}>End Point 2</div>,
      grow: 0.5,
      selector: (row) => row["End 2"],
    },
    {
      name: <div style={{ padding: "8px" }}>Cable</div>,
      grow: 3,
      selector: (row) => row["carousel"],
    },
    {
      name: <div style={{ padding: "8px" }}>Length (km)</div>,
      grow: 0.5,
      selector: (row) => row["Length"],
    },
    {
      name: <div style={{ padding: "8px" }}>Load</div>,
      grow: 0.5,
      selector: (row) => row["Load"],
    },
  ];

  return (
    <div>
      <DataTable
        title={"Installation Sequence"}
        style={{ width: "100%", whiteSpace: "unset", height: "100%" }}
        columns={columns}
        data={siteExportSections}
        customStyles={customStyles}
        responsive
        // subHeader
        subHeaderAlign="right"
        subHeaderWrap
        dense
      />
    </div>
  );
}

export default SiteExportSectionsTable;
