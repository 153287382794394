import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Skeleton } from "primereact/skeleton";

const TableBox = styled.div`
  display: flex;
  padding: 20px;
`;
// Styled components using styled-components
const StyledTableCell = styled(TableCell)`
  background-color: ${(props) => {
    // Priority to header color
    if (props.header) return props.headerColor || "#e0e0e0";
    // Next, check for firstCol and secondCol
    if (props.firstCol) return props.firstColor || "none";
    if (props.secondCol) return props.secondColor || "none";
    return "none";
  }};
  color: ${(props) =>
    props.header ? "#ffffff !important" : "black !important"};
  font-weight: ${(props) =>
    props.firstCol || props.header || props.secondCol
      ? "600 !important"
      : "normal !important"};
  font-size: 12px !important;
  border: 1px solid black;

  width: 45px;
  max-width: 45px;
  min-width: 45px;

  // Set max-width and text wrapping for the first column cells
  ${(props) =>
    props.firstCol &&
    `
    width: 80px; // or any other fixed width
    max-width: 80px; // Ensure it doesn't exceed the specified width
    min-width: 80px; // Ensure it doesn't go below the specified width
    max-height: 40px;
    white-space: wrap;
    // word-wrap: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
  `}

  ${(props) =>
    props.secondCol &&
    `
    width: 30px; // Adjust the width as needed
    max-width: 30px;
    min-width: 30px;
  `}

  // Add this for header cells to wrap text and have a max width
  ${(props) =>
    props.header &&
    `
    white-space: normal;
    word-wrap: break-word;
  `}
`;

const StyledTableRow = styled(TableRow)`
  /* &:nth-of-type(odd) {
    background-color: ${(props) =>
    !props.header ? "#f7f7f7" : "none"}; // Light grey color for odd rows
  } */
  /* &:not(:last-child) { */
  border-bottom: 1px solid black; // Optionally remove the bottom border from the rows to avoid double borders
  /* } */
`;

function DatasheetTable({ title, data, filtered, style }) {
  // const [settings, setSettings] = useState({
  //   Project: { value: "Enter Project Name", max: 30 },
  //   Client: { value: "Enter Client Name" },
  //   "Additional Information": {
  //     value:
  //       "This installation note is prepared and issued for the sole use of the client. The information is based on generic vessel specifications and ERA5 metocean data and is considered budgetary only. Neither the pricing information or installation data shall form the basis of a formal offer or contract. The data may be subject to change depending on future requirements.",
  //     max: 300,
  //   },
  //   Date: { value: " " },
  //   "Document Number": { value: " " },
  //   Revision: { value: " " },
  //   primaryColor: "#00a0c6",
  //   secondaryColor: "hsla(0,0%,83%,.329)",
  //   // primaryColor:"#009ec6", // Vekta Blue
  //   // secondaryColor:"#4FCFF0",
  //   tertiaryColor: "hsla(0,0%,83%,.329)",
  // });

  const createTableBody = (pageHeaders, sortOrder) => {
    // return Object.keys(data[0]).map((key) => {
    return filtered.map((key) => {
      if (!data[0][key]) return;

      return (
        <StyledTableRow key={key}>
          <StyledTableCell
            component="th"
            scope="row"
            firstCol
            firstColor={style.secondaryColor}
            secondColor={style.tertiaryColor}
          >
            {data[0][key].screen_name}
            {/* {key} */}
          </StyledTableCell>
          <StyledTableCell
            // key={valueIndex}
            secondCol
            firstColor={style.secondaryColor}
            secondColor={style.tertiaryColor}
          >
            {data[0][key].units}
          </StyledTableCell>
          {data.map((cable, valueIndex) => {
            if (cable[key] !== undefined) {
              return (
                <StyledTableCell key={valueIndex}>
                  {/* {
                      toNormalDecimal(
                        cable[key].val,
                        key,
                        cable[key].val.units
                      ).split("_")[0]
                    } */}
                  {cable[key].val}
                </StyledTableCell>
              );
            } else {
              return <StyledTableCell key={valueIndex}>N/A</StyledTableCell>;
            }
          })}
        </StyledTableRow>
      );
      //   } else {
      //     // console.log(data[0][key]);
      //   }
    });
  };

  useEffect(() => {
    console.log({ data });
  }, [data]);

  return (
    <div>
      <h4>{title}</h4>

      {Object.keys(data[0]).length === 0 ? (
        <Skeleton width="100%" height="150px"></Skeleton>
      ) : (
        <TableBox>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow header>
                  <StyledTableCell header headerColor={style.primaryColor}>
                    Parameters
                  </StyledTableCell>{" "}
                  {/* First column header changed to "Units" */}
                  <StyledTableCell
                    key={"unit cell"}
                    header
                    secondCol
                    headerColor={style.primaryColor}
                  >
                    Units
                  </StyledTableCell>
                  {Array.from({ length: data.length }, (_, index) => (
                    <StyledTableCell
                      key={index}
                      header
                      headerColor={style.primaryColor}
                    >{`Cable ${index + 1}`}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>{createTableBody()}</TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      )}
    </div>
  );
}

export default DatasheetTable;
