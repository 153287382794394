import React, { useState } from "react";
import { storage } from "../../../firebase";
import { getDownloadURL, ref } from "firebase/storage";

function FaqHomeOne({ className, setPage }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };

  const handleDownloadValidation = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "validation_docs/VCAB-VGED-TEC-001.pdf"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "CIGRE-Validation.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <>
      <section
        id="faqs"
        className={`appie-faq-area pb-95 pt-35 ${className || ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Frequently asked questions</h3>
                {/* <p>Different layouts and styles for team sections.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How many cables do you have, and can I add my own to
                          the database?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            We have over 620 cables which are based on the ABB
                            datasheets and include both onshore and offshore
                            cables. You can easily add your own cables with very
                            few steps using our default values or you can enter
                            detailed cable geometry parameters and save it to
                            you personal database.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(5)}
                    className={`accrodion ${showQues === 5 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          Do I need to download any software and are there
                          minimum system requirements?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 5 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            No you don't need to download anything, the software
                            is cloud based and will run on any browser on almost
                            any PC or MAC. We recommend that you only run the
                            tool on a laptop or desktop with the browser /
                            system zoom set to 100% or less as it's not yet
                            optimised for mobile use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(2)}
                    className={`accrodion ${showQues === 2 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          What standards do you use and what happens if they
                          change?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 2 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            We use IEC 60287, IEC 60949 and CIGRE TB 908 to
                            calculate ampacity and armour losses then we
                            validate our cables using CIGRE TB 880 which has
                            comprehensive case studies, you can download our
                            validation report{" "}
                            <a href="#" onClick={handleDownloadValidation}>
                              here
                            </a>{" "}
                            or on the main page. We review standards regularly
                            and update the software within 6 months.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(8)}
                    className={`accrodion ${showQues === 8 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How does the pricing element work and how accurate is
                          it?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 8 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            The pricing calculations are produced using two
                            different methods, the first is by using the
                            geometry, materials and weights for each cable and
                            linking all this data with trusted sources like LME
                            for materials we can then apply a multiplier which
                            accounts for profit margin and other costs more
                            local to a region (e.g. labour, interest rates,
                            inflation etc.). The second method uses an AI neural
                            network to continuously train the tool to generate
                            an accurate price based on historical prices for
                            similar cables as well as certain external market
                            fluctuations. The end result is that we benchmark
                            pricing accuracy against the requirements of Class 3
                            or above which is the typical standard used for
                            budget quotations industry wide. The more anonymous
                            data we get from the industry regarding historical
                            cable prices then the more accurate the AI engine
                            gets for all users. You can find out more in our
                            validation report{" "}
                            <a href="#" onClick={handleDownloadValidation}>
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do secure my data and what happens when I delete a
                          cable?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Our software is built using google cloud platform
                            and all the user data, login credentials and account
                            management are handled by google firebase which is a
                            widely trusted secure cloud platform. All the cable
                            data and APIs we use are encrypted and its not
                            possible for other users to see your cable data so
                            you can create and delete cables securely and
                            privately. You can't delete our standard database of
                            cables as that is available to all users.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(6)}
                    className={`accrodion ${showQues === 6 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do I customise the cable calculations to be site
                          specific?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 6 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            By clicking on the settings icon next to each cable
                            in the batch window you are able to tailor the cable
                            to match your project requirements and change things
                            like financial constraints, burial depth, tr values,
                            thermal conditions and other environmental settings.
                            You can easily compare the same cable with two
                            different site conditions and its all displayed on
                            the datasheet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(7)}
                    className={`accrodion ${showQues === 7 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do you handle bugs and feature requests?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 7 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            You can contact us either on our website or from
                            directly within the tool by using the help icon. You
                            are able to report a bug, submit a feature idea or
                            request a meeting. We welcome your feedback as we
                            work on a very open and collaborative basis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(4)}
                    className={`accrodion ${showQues === 4 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do I manage my license and can I buy new upgrades
                          when they are available?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 4 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Click on the my account icon within the tool to see
                            your account details and what subscription you have.
                            We offer a discount if you pay annually and your
                            license will renew automatically at the end of the
                            year. We are developing new modules for our software
                            regularly and you will be able to buy those as an
                            upgrade within the tool or part of a bundle. You can
                            cancel your license at any time and it will remain
                            active until the expiry date but we don't offer
                            partial refunds at this stage as per the license
                            T&C's which are available <a href="#">here</a>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Can't find an answer?{" "}
                  <a
                    href="#"
                    style={{ color: "#00a0c6" }}
                    onClick={() => {
                      setPage("contact");
                      document.getElementById("landing-page").scrollTop = 0;
                    }}
                  >
                    Contact us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeOne;
