import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const CharacteristicItemContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 360px;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;

  .MuiSvgIcon-root {
    color: lightgray;
    /* transition: color 0.3s; */
  }

  &:hover .MuiSvgIcon-root {
    color: #5a5a5a;
  }
`;

const CharacteristicValue = styled.span`
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
  margin-left: 8px;
`;
const CharacteristicName = styled.span`
  color: #5a5a5a;
  font-size: 12px;
  margin-left: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const DragHandle = styled.div`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;
const CharacteristicItem = ({ item, setData, provided }) => {
  const handleClose = () => {
    setData((prevData) => {
      const index = prevData.findIndex((char) => char.id === item.id);
      if (index !== -1) {
        return [...prevData.slice(0, index), ...prevData.slice(index + 1)];
      }
      return prevData;
    });
  };
  // useEffect(()=>{console.log(item)},[item])

  return (
    // <DragHandle {...provided.dragHandleProps}>
      <CharacteristicItemContainer>
        <Column>
          <CharacteristicValue>{item.value} {item.units} </CharacteristicValue>
          <CharacteristicName>{item.name}</CharacteristicName>
        </Column>
        {/* <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton> */}
      </CharacteristicItemContainer>
    // </DragHandle>
  );
};

export default CharacteristicItem;
