import { useState, useEffect } from "react";

import styled from "styled-components";

import CableList from "./CableList";
// import { CSVLink } from "react-csv";

import BottomBar from "./BottomBar";
import ReportRender from "./reportRenderer/ReportRender";
import { getCurrentDate } from "./helperFunctions";
import { SecretButton } from "../../../styles/styles";
// import { to } from "@react-spring/core";

import { ScaleLoader } from "react-spinners";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../../firebase";
import { BpGraph } from "../../../schema/BpGraph_Cables";
import { proxy_url } from "../../../firebase";
import { group_to_titles } from "./reportRenderer/helperFunctions";

const RightPanel = styled.div`
  /* display:flex; */
  position: relative;
  width: 100%;
  max-width: 100%;
  height: calc(100% - 16px);

  border-radius: 4px;
  background-color: white;

  border: 1px solid lightgray;
`;
const MainPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* padding: 16px; */
`;

const Stage = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const isNKT = false;

function Datasheets({
  workspace,
  setWorkspace,
  toAnalyse,
  runAPI,
  includedSpecs,
  setIncludedSpecs,
  proxyData,
  setProxyData,
}) {
  const [settings, setSettings] = useState({
    "Project": { value: "Enter Project Name", max: 30 },
    "Client": { value: "Enter Client Name" },
    "Additional Information": {
      value:
        "This datasheet is prepared and issued for the sole use of the client.  The information is based on compliance with IEC and CIGRE standards and is considered budgetary only.  Neither the pricing information or technical data shall form the basis of a formal offer or contract.  The data may be subject to change depending on future requirements.",
      max: 300,
    },
    Date: { value: " " },
    "Document Number": { value: " " },
    "Revision": { value: " " },
    primaryColor: isNKT ? "#082F68" : "#00A0C6", // NKT: Vekta Blue
    secondaryColor: isNKT ? "#1F5199" : "hsla(0,0%,83%,.329)", // NKT
    // primaryColor:"#009ec6", // Vekta Blue
    // secondaryColor:"#4FCFF0",
    tertiaryColor: "hsla(0,0%,83%,.329)",
  });

  useEffect(() => {
    setSettings((settings) => ({
      ...settings,
      ["Date"]: { value: getCurrentDate() },
    }));
  }, []);

  const [logo, setLogo] = useState({ data: null, extension: null });

  const [cables, setCables] = useState([]);
  const [loading, setLoading] = useState(false);

  const [previewPDF, setPreviewPDF] = useState(false);

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      const filename = isNKT ? "/nktlogo.jpg" : "/vektaLogo.png";
      // const filename = "/vektaLogo.png";

      const response = await fetch(filename);
      const extension = filename.split(".").pop();

      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setLogo({ data: base64data, extension });
      };
      reader.readAsDataURL(blob);
    };

    fetchImage();
  }, []);

  const deleteCable = (index) => {
    setCables((prevCables) => [...prevCables.filter((_, i) => i !== index)]);
  };

  const callProxyAPI = async (callables, user) => {
    // console.log(callables);
    if (callables.length === 0) return console.log("No cables to call");

    setLoading(true);
    console.log(" calling proxy API");
    console.log({ callables });
    console.log({ user });
    const idToken = await user.getIdToken();
    try {
      const outputs_res = await fetch(proxy_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(callables),
      });
      const outputs = await outputs_res.json();
      console.log({ outputs });
      console.log("done calling proxy API");
      // TODO: fix NN predictions
      for (let i = 0; i < outputs.length; i++) {
        // delete outputs[i]["Neural Network Price Prediction"]
      }

      let temp = group_to_titles([outputs[0]]);
      let orderedBy = [
        ...Object.keys(temp["Financial"]),
        ...Object.keys(temp["input data"]),
      ];

      Object.keys(temp).map((group) => {
        if (
          group !== "Financial" &&
          group !== "input data" &&
          group !== "Internal" &&
          group !== "Helper"
        )
          orderedBy.push(...Object.keys(temp[group]));
      });

      setIncludedSpecs(orderedBy);

      return outputs;
    } catch (e) {
      console.log(e);
    }
  };

  const final_formatting = (callables) => {
    const formatted_callables = [];
    callables.forEach((callable) => {
      const cable = callable.cable;
      // // things in cable that can be number should be number
      for (const [key, value] of Object.entries(cable)) {
        if (typeof value === "string" && !isNaN(value)) {
          cable[key] = Number(value);
        }
      }

      const settings = callable.settings;
      const bpg = new BpGraph(cable);
      // turn date from 2020-01-01 to 2020-01
      const bp_filled = bpg.fill_blueprint(cable);
      // settings.date = settings.date.slice(0, 7)
      console.log(settings);
      const formatted_cable = {
        settings: settings,
        cable: cable,
      };
      console.log(settings);
      formatted_callables.push(bp_filled);
    });

    console.log(formatted_callables);
    return formatted_callables;
  };

  return (
    <MainPanel>
      <SecretButton
        id="datasheet_secret_button"
        onClick={async () => {
          // console.log("secret button clicked")
          // console.log(toAnalyse)
          // const data = await callDatasheetAPI(toAnalyse)
          // console.log({ data })
          const formatted_callables = final_formatting(toAnalyse);
          // callProxyAPI(sample_input)

          const not_working_input = formatted_callables;
          setProxyData([]);
          // const proxyData = await callProxyAPI(working_input)
          const proxyData = await callProxyAPI(not_working_input, user);
          setLoading(false);
          if (proxyData) setProxyData(proxyData);
          console.log({ proxyData });
        }}
      />

      <Stage>
        {loading && (
          <RightPanel
            style={{
              position: "absolute",
              zIndex: "100",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ScaleLoader color="#009ec6" />
            <label>Loading Cable Information . . .</label>
          </RightPanel>
        )}
        {proxyData[0] ? (
          <RightPanel>
            <ReportRender
              cables={proxyData}
              settings={settings}
              logo={logo}
              style={{ display: previewPDF ? "block" : "none" }}
              previewPDF={previewPDF}
              includedSpecs={includedSpecs}
            />

            <CableList
              cables={proxyData}
              deleteCable={deleteCable}
              // previewCable={}
              loading={loading}
              previewPDF={previewPDF}
              includedSpecs={includedSpecs}
              setIncludedSpecs={setIncludedSpecs}
            />
          </RightPanel>
        ) : (
          <RightPanel
            style={
              loading
                ? { display: "none" }
                : {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
          >
            No Cables Present . . .
          </RightPanel>
        )}
      </Stage>

      <BottomBar
        cables={proxyData}
        previewPDF={previewPDF}
        settings={settings}
        setSettings={setSettings}
        logo={logo}
        setLogo={setLogo}
        setPreviewPDF={setPreviewPDF}
        includedSpecs={includedSpecs}
        setIncludedSpecs={setIncludedSpecs}
        workspace={workspace}
        setWorkspace={setWorkspace}
      />
    </MainPanel>
  );
}

export default Datasheets;

// Client
// Project
// Notes
// Contact Details
// Copyright on last page
// look at chat with charles
// check if you can make variable color

// Expand Environmental Conditions to encompass Storage Conditions
// keep the first two

// Add User Input table into first page
// Add 2 images sections per cable
// fix cols widths and general styling
// in pricing make single core cable armoured and three core armoured

///////////////////////////////////////////
// Bring in new First table with properties
// Add color picker to settings
// move text in front page into grey area
// Bold the Titles in grey bit
// remove underscore

// Style The Tables
