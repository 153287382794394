import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";

function Steams({ resultsData, title }) {
  const [fullInstRateData, setFullInstRateData] = useState([]);

  const [layout, setLayout] = useState({
    ...(title
      ? {
          title: {
            text: "Cable Layed by Date",
            font: {
              family: "Montserrat",
            },
          },
        }
      : {}),
    // autosize: true,
    xaxis: {
      ticklen: 8,
      tickwidth: 2,
      tickcolor: "#000",
      title: "Date",
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    yaxis: {
      ticklen: 8,
      tickwidth: 2,
      tickcolor: "#000",
      title: "Length Installed (km)",
      titlefont: {
        family: "Montserrat",
        size: 18,
      },
      tickfont: {
        family: "Montserrat",
        size: 14,
      },
    },
    height: 500,
  });

  const config = {
    // responsive: true,
    toImageButtonOptions: {
      format: "png", // one of png, svg, jpeg, webp
      filename: "CableLayStream",
      height: 500,
      width: 1000,
      scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
    },
  };

  const handleDateFormat = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    if (
      !resultsData ||
      !resultsData.quantInstDF ||
      !Array.isArray(resultsData.quantInstDF)
      // !resultsData.hasOwnProperty("exportSummary")
    ) {
      // console.log("Missing or invalid data for plotting");
      return;
    }

    // console.log("Generate data for plotting");
    let { quantInstDF, startDate, exportSummary } = resultsData;
    // console.log({ quantInstDF, startDate, exportSummary });

    if (exportSummary === undefined) exportSummary = [];

    const cumLengths = exportSummary
      .map((item) => item.Length)
      .reduce((acc, curr, index) => [...acc, curr + (acc[index - 1] || 0)], []);

    const traces = [1, 3, 5, 7, 9].map((i) => {
      const streamDates = [];
      for (let j = 0; j < quantInstDF.length; j++) {
        const element = quantInstDF[j];
        const newDate = new Date(startDate);
        newDate.setDate(newDate.getDay() + element[i * 10 + "%"]);
        streamDates.push(newDate);
      }

      return {
        x: streamDates,
        y: cumLengths,
        name: `P${i}0`,
        mode: "lines",
        line: {
          color: i !== 5 ? getRandomColor() : "black",
          dash: i !== 5 && "dash",
        },
      };
    });

    setFullInstRateData(traces);
    // console.log(traces);

    let oneWeekAfterEndDate =
      traces[traces.length - 1].x[traces[traces.length - 1].x.length - 1];
    oneWeekAfterEndDate.setDate(
      traces[traces.length - 1].x[
        traces[traces.length - 1].x.length - 1
      ].getDate() + 7
    );

    setLayout((layout) => ({
      ...layout,
      xaxis: {
        ...layout.xaxis,
        range: [new Date(startDate), oneWeekAfterEndDate],
        tickformat: "%d-%m-%Y", // Format ticks as 'YYYY-MM-DD'
        dtick: 7 * 24 * 60 * 60 * 1000, // Set tick interval to 7 days (in milliseconds)
      },
    }));
  }, [resultsData]);

  return (
    <>
      <Plot
        // useResizeHandler
        style={{ width: "100%", height: "500px" }}
        data={fullInstRateData}
        layout={layout}
        config={config}
      />
    </>
  );
}

export default Steams;
