import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Skeleton } from "primereact/skeleton";

const TableBox = styled.div`
  display: flex;
  padding: 20px;
`;
// Styled components using styled-components
const StyledTableCell = styled(TableCell)`
  background-color: ${(props) => {
    // Priority to header color
    if (props.header) return props.headerColor || "#e0e0e0";
    // Next, check for firstCol and secondCol
    if (props.firstCol) return props.firstColor || "none";
    if (props.secondCol) return props.secondColor || "none";
    return "none";
  }};
  color: ${(props) =>
    props.header ? "#ffffff !important" : "black !important"};
  font-weight: ${(props) =>
    props.firstCol || props.header || props.secondCol
      ? "600 !important"
      : "normal !important"};
  font-size: 12px !important;
  border: 1px solid black;

  padding: ${(props) => props.compact && "8px !important"};

  width: 45px;
  max-width: 45px;
  min-width: 45px;

  // Set max-width and text wrapping for the first column cells
  ${(props) =>
    props.firstCol &&
    `
    width: 80px; // or any other fixed width
    max-width: 80px; // Ensure it doesn't exceed the specified width
    min-width: 80px; // Ensure it doesn't go below the specified width
    max-height: 40px;
    white-space: wrap;
    // word-wrap: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
  `}

  ${(props) =>
    props.secondCol &&
    `
    width: 30px; // Adjust the width as needed
    max-width: 30px;
    min-width: 30px;
  `}

  // Add this for header cells to wrap text and have a max width
  ${(props) =>
    props.header &&
    `
    white-space: normal;
    word-wrap: break-word;
  `}
`;

const StyledTableRow = styled(TableRow)`
  /* &:nth-of-type(odd) {
    background-color: ${(props) =>
    !props.header ? "#f7f7f7" : "none"}; // Light grey color for odd rows
  } */
  /* &:not(:last-child) { */
  border-bottom: 1px solid black; // Optionally remove the bottom border from the rows to avoid double borders
  /* } */
`;

function StyledTable({ header, data, order, style }) {
  // useEffect(() => {
  //   console.log({ data });
  // }, [data]);

  return (
    <div>
      <header style={{ display: "flex", gap: "8px" }}>{header}</header>
      {Object.keys(data[0]).length === 0 ? (
        <Skeleton width="100%" height="150px"></Skeleton>
      ) : (
        <TableBox>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow header>
                  {order
                    ? order.map((key) => {
                        return (
                          <StyledTableCell header headerColor={style.colour}>
                            {key}
                          </StyledTableCell>
                        );
                      })
                    : Object.keys(data[0]).map((key) => {
                        return (
                          <StyledTableCell header headerColor={style.colour}>
                            {key}
                          </StyledTableCell>
                        );
                      })}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => {
                  return (
                    <StyledTableRow header>
                      {order
                        ? order.map((key) => {
                            return (
                              <StyledTableCell compact={style.compact}>
                                {item[key]}
                              </StyledTableCell>
                            );
                          })
                        : Object.keys(item).map((key) => {
                            return (
                              <StyledTableCell compact={style.compact}>
                                {item[key]}
                              </StyledTableCell>
                            );
                          })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      )}
    </div>
  );
}

export default StyledTable;
