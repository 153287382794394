import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";
import Joyride, { STATUS } from "react-joyride";
import styled from "styled-components";
import {
  PrimeButton,
  Section,
  SectionFooter,
  SectionFooterLeft,
  SectionFooterRight,
  StandardContainerRow,
} from "../../../styles/styles";

import Select from "react-select";

import SiteExportSectionsTable from "../common/SiteExportSectionsTable";
// import CableSummaryTable from "../../../delete/CableSummaryTable";
import VesselSummary from "../common/VesselSummaryTable";
// import FullOutputs from "./outputs/FullOutputs";
import { ScaleLoader } from "react-spinners";
import BottomBar from "./BottomBar";
import ReportRender from "./reportRenderer/ReportRender";
import GanttChart from "./outputs/GanttChart";
import Sunburst from "./outputs/Sunburst";
import DeplayBarChart from "./outputs/DeplayBarChart";
import Steams from "./outputs/Steams";
// import Costs from "./outputs/Costs";
import DatasheetTable from "./outputs/DatasheetTable";
import { getCableByName } from "../../../functions/functions";
import TableRender from "./reportRenderer/TableRender";
import { group_to_titles } from "./reportRenderer/helperFunctions";
import { proxy_url } from "../../../firebase";
import { BpGraph } from "../../../schema/BpGraph_Cables";
import StyledTable from "./outputs/StyledTable";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";

import DocumentStyles from "../../../media/tutorials/Styles.gif";
import PDFPreview from "../../../media/tutorials/ScenarioPDF.gif";
import ScenarioResults from "../../../media/tutorials/ScenarioResults.gif";

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

function Results({
  user,
  resultsData,
  setBatchData,
  setResultsData,
  batchSummary,
  batchSummarySelect,
  batchData,
}) {
  const percentile = [
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
    { value: 30, label: "30%" },
    { value: 40, label: "40%" },
    { value: 50, label: "50%" },
    { value: 60, label: "60%" },
    { value: 70, label: "70%" },
    { value: 80, label: "80%" },
    { value: 90, label: "90%" },
  ];

  const [previewPDF, setPreviewPDF] = useState(false);
  const [settings, setSettings] = useState({
    Project: { value: "Enter Project Name", max: 30 },
    Client: { value: "Enter Client Name" },
    "Additional Information": {
      value:
        "This installation note is prepared and issued for the sole use of the client. The information is based on generic vessel specifications and ERA5 metocean data and is considered budgetary only. Neither the pricing information or installation data shall form the basis of a formal offer or contract. The data may be subject to change depending on future requirements.",
      max: 300,
    },
    Date: { value: " " },
    "Document Number": { value: " " },
    Revision: { value: " " },
    primaryColor: "#009ec6",
    secondaryColor: "hsla(0,0%,83%,.329)",
    // primaryColor:"#009ec6", // Vekta Blue
    // secondaryColor:"#4FCFF0",
    tertiaryColor: "hsla(0,0%,83%,.329)",
  });
  const [logo, setLogo] = useState({ data: null, extension: null });

  const [runSettings, setRunSettings] = useState("weathered");
  const [cables, setCables] = useState([{}]);
  const bpGraph = new BpGraph();

  const [scenarioSelected, setScenarioSelected] = useState({
    value: null,
    label: " ",
  });
  const [currentScenarios, setCurrentScenarios] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [costData, setCostData] = useState([{}]);
  const cost_breakdown = useRef(null);
  let pounds = Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Scenario Results</h2>
            <img width={"100%"} src={ScenarioResults} />
            <p>
              View the full scenario results one by one. Use the dropdown in the
              top left to select what scenario results to view. <br></br>
              <br></br>
              If a scenario is weathered, change the percentile percentage in
              the top right.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "left",
        target: ".section_results",
        disableBeacon: true,
      },
      {
        title: "Download Raw Data",
        content: (
          <div>
            <p>
              Download some of the above data as a .csv file to export and
              analyse outside the platform.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "top",
        target: "#download_csv",
      },
      {
        title: "Download Report",
        content: (
          <div>
            <p>Download the shown scenario report as a PDF file.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "top",
        target: "#download_pdf",
      },
      {
        title: "Report Theme",
        content: (
          <div>
            <img width={"100%"} src={DocumentStyles} />
            <p>
              Easily customise the PDF document with basic metadata and company
              colours and logos.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "top-bottom",
        target: "#document_settings",
      },
      {
        title: "PDF Preview",
        content: (
          <div>
            <img width={"100%"} src={PDFPreview} />
            <p>Get the best view of your scenario data with our PDF preview.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "top-bottom",
        target: "#preview_pdf",
      },
    ],
  });

  const [runJoyride, setRunJoyride] = useState(false);
  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };
  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleOptions = (options) => {
    if (options) {
      const formatted = [];

      for (let i = 0; i < options.length; i++) {
        const element = options[i];
        formatted.push({ label: element, value: element });
      }

      return formatted;
    }
  };

  const updateCosts = (results) => {
    console.log({ results });
    if (results.scenarioSummary) {
      const cost_key_map = {
        "Demob* (days)": "vessels:mob_and_demob_cost",
        "Duration (days)": 0,
        "Mob* (days)": "vessels:mob_and_demob_cost",
        "Transit (days)": "vessels:transit_cost",
        "Weather Downtime (days)": "vessels:idle_cost",
        "Site Work (days)": "vessels:work_cost",
        "Port Work* (days)": "vessels:work_cost",
        "Demobilisation (days)": "vessels:mob_and_demob_cost",
        "Mobilisation(days)": "vessels:mob_and_demob_cost",
        "Work (days)": "vessels:work_cost",
      };

      const temp = { ...results.scenarioSummary[0] };
      if (
        batchData[
          scenarioSelected.value
            ? scenarioSelected.value
            : Object.keys(batchData)[0]
        ].scenario["site:scenario_setting"] === "unweathered"
      ) {
        temp["Work (days)"] =
          results.scenarioSummary[0]["Installing (days)"] +
          results.scenarioSummary[0]["Loading Out (days)"];

        delete temp["Installing (days)"];
        delete temp["Loading Out (days)"];
      }

      const data = {};
      let total = 0;
      Object.keys(temp).map((title) => {
        if (title.includes("(days)")) {
          const math =
            temp[title] *
            batchData[
              scenarioSelected.value
                ? scenarioSelected.value
                : Object.keys(batchData)[0]
            ].scenario[cost_key_map[title]];
          total += isNaN(math) ? 0 : math;

          data[title.split("(")[0].trim() + " Cost"] = pounds.format(math);
        }
      });

      data["Duration Cost"] = pounds.format(total);

      // console.log({ data });

      setCostData([data]);
    }
  };

  const handleRun = async () => {
    setLoading(true);

    const formatData = (tableInfo) => {
      return tableInfo.map((row) => {
        const temp = {};

        Object.keys(row).map((item) => {
          temp[item] = row[item].value;
        });

        return temp;
      });
    };

    let first = true;
    for (const key in batchData) {
      const listIn = {
        ...batchData[key].listIn,
        unweathered:
          batchData[key].scenario["site:scenario_setting"] === "unweathered",
        connector:
          batchData[key].listIn.connector[
            batchData[key].scenario["site:minimise_by"]
          ],
        loads:
          batchData[key].listIn.loads[
            batchData[key].scenario["site:minimise_by"]
          ],
        site_exportSections:
          batchData[key].listIn.site_exportSections[
            batchData[key].scenario["site:minimise_by"]
          ],
        capacities: batchData[key].listIn.capacities.caps,
      };

      const siteOps = {};
      Object.keys(batchData[key].scenario).map((item) => {
        if (
          item.includes("vessel_ops") &&
          !(item.includes("hddOps") || item.includes("ospOps"))
        ) {
          siteOps[item.split(":")[1]] = formatData(
            batchData[key].scenario[item]
          );
        } else if (item === "vessel_ops:hddOps") {
          siteOps[item.split(":")[1]] = {};
          for (
            let i = 0;
            i < batchData[key].scenario["site:number_of_HDD"];
            i++
          ) {
            siteOps[item.split(":")[1]]["Landfall " + (i + 1)] = formatData(
              batchData[key].scenario[item]
            );
          }
        } else if (item === "vessel_ops:ospOps") {
          siteOps[item.split(":")[1]] = {};
          for (
            let j = 0;
            j < batchData[key].scenario["site:number_of_OSP"];
            j++
          ) {
            siteOps[item.split(":")[1]]["OSP " + (j + 1)] = formatData(
              batchData[key].scenario[item]
            );
          }
        }
      });

      // console.log({ siteOps });

      listIn.siteOps = siteOps;

      // console.log({ listIn });

      const hostURL = "https://cabcon-hiibq2h6dq-ew.a.run.app";
      const URL = `${hostURL}/installCable`;

      try {
        const response = await fetch(URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(listIn),
        });

        const results = await response.json();
        if (first) {
          setResultsData(results);
        }
        setBatchData((batchData) => ({
          ...batchData,
          [key]: {
            ...batchData[key],
            results: [results],
          },
        }));

        // console.log({ batchData });

        updateCosts(results);
      } catch (error) {
        console.error("Error:", error);
      }

      first = false;
    }

    setLoading(false);
  };

  const getCableData = async (scenario) => {
    const idToken = await user.getIdToken();
    const cableData = [];

    let primeCable = await getCableByName(
      "vekta",
      batchData[scenario].scenario["vessels:primary_carousel_cable"]
    );
    cableData.push(bpGraph.fill_blueprint(primeCable));

    if (
      batchData[scenario].scenario["vessels:primary_carousel_cable"] !==
        batchData[scenario].scenario["vessels:secondary_carousel_cable"] &&
      batchData[scenario].scenario["vessels:selected_vessel"].split("_")[2] ===
        "2-Carousel"
    ) {
      let secCable = await getCableByName(
        "vekta",
        batchData[scenario].scenario["vessels:secondary_carousel_cable"]
      );
      cableData.push(bpGraph.fill_blueprint(secCable));
    }

    try {
      const outputs_res = await fetch(proxy_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(cableData),
      });
      const outputs = await outputs_res.json();

      // console.log({ outputs });

      setCables(outputs);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const fetchData = async (scenario) => {
    if (batchData[scenario]) {
      // console.log("FETCH DATA");
      getCableData(scenario);

      if (Object.keys(batchData[scenario].results).length > 0) {
        try {
          const listIn = {
            obj: batchData[scenario].results[0],
            site_precent: percent.value,
            // unweathered:
          };

          // console.log({ listIn });

          const response = await fetch(
            "https://cabcon-hiibq2h6dq-ew.a.run.app/updateResults",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(listIn),
            }
          );
          const data = await response.json();

          setResultsData(data);
          updateCosts(data);
          setCurrentData(batchData[scenario]);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    // Check if batchSummarySelect is a valid index
    // console.log({ batchData });
    // console.log(findResults(batchData));
    // console.log(loading);

    if (Object.keys(batchData).length > 0) {
      const ind = scenarioSelected.value
        ? scenarioSelected.value
        : Object.keys(batchData)[0];

      // Ensure batchData contains data at the given index
      if (batchData[ind] && batchData[ind].results.length > 0) {
        setSettings((settings) => ({
          ...settings,
          Project: {
            ...settings.Project,
            value: scenarioSelected.label
              ? scenarioSelected.label
              : batchData[Object.keys(batchData)[0]].scenarioName,
          },
        }));
        // updateCosts(batchData[ind].results);
        if (batchData[ind].scenario["site:scenario_setting"] === "weathered") {
          fetchData(ind);
          return;
        }

        // console.log(batchData[ind]);

        getCableData(ind);
        updateCosts(batchData[ind].results[0]);
        setResultsData(batchData[ind].results[0]);
        setCurrentData(batchData[ind]);
      }
    }
    // }
  }, [scenarioSelected, batchData]);

  useEffect(() => {
    if (batchSummary.length > 0) {
      // console.log({ scenarioSelected });
      // console.log(
      //   batchSummary.filter((item) => item.id === scenarioSelected.value)
      // );

      setCurrentScenarios(() => {
        // const temp = [...batchSummary];
        if (
          batchSummary.filter((item) => item.id === scenarioSelected.value)
            .length === 0
        ) {
          //   console.log(
          //     temp.filter((item) => item.id === scenarioSelected.value)
          //   );

          setScenarioSelected({
            value: null,
            label: " ",
          });
        }

        return batchSummary.map((item) => ({
          label: item.Name,
          value: item.id,
        }));
      });
    }
  }, [batchSummary]);

  useEffect(() => {
    const fetchImage = async () => {
      const filename = "/vektaLogo.png";
      // const filename = "/vektaLogo.png";

      const response = await fetch(filename);
      const extension = filename.split(".").pop();

      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setLogo({ data: base64data, extension });
      };
      reader.readAsDataURL(blob);
    };

    fetchImage();
  }, []);

  const [percent, setPercent] = useState({ value: 50, label: "50%" });
  useEffect(() => {
    if (Object.keys(resultsData).length === 0) return null;

    const ind = scenarioSelected.value
      ? scenarioSelected.value
      : Object.keys(batchData)[0];

    fetchData(ind);
  }, [percent]);

  const findResults = (batchData) => {
    let found = false,
      total = 0;

    Object.keys(batchData).map((scenario) => {
      if (
        batchData[scenario] &&
        (batchData[scenario].results.length > 0 ||
          Object.keys(batchData[scenario]?.results).length > 0)
      ) {
        console.log(batchData[scenario]);

        found = true;
        total++;
      }
    });

    return { found, total };
  };

  return (
    <>
      <button
        id="hidden_runButton"
        onClick={() => {
          setRunSettings("unweathered");
          handleRun();
        }}
        style={{ display: "none" }}
      ></button>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
            // width: "fit-content",
          },
        }}
      />
      <div
        id="section_results"
        className="section_results"
        style={{
          gridArea: "1 / 4 / 7 / 7",
          padding: "16px 16px 16px 0",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ margin: "0" }}>Results</h1>
              <StyledIconButton
                title="Results Guidance"
                onClick={handleTutorial}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </StyledIconButton>
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "16px" }} />
        </header>

        {!previewPDF && (
          <div
            style={{
              backgroundColor: "#ffffff",
              border: "1.5px solid lightgrey",
              borderRadius: "8px",
              padding: "16px",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {Object.keys(batchData).length > 0 &&
            Object.keys(batchData).length === findResults(batchData).total &&
            !loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div>
                  <StandardContainerRow>
                    <div>
                      <label>Selected Scenario</label>
                      <Select
                        defaultValue={
                          //handleOptions(
                          batchSummary.length > 0 && [
                            {
                              label: batchSummary[0].Name,
                              value: batchSummary[0].id,
                            },
                          ]
                          //)
                        }
                        value={
                          scenarioSelected.value
                            ? scenarioSelected
                            : {
                                label: batchSummary[0].Name,
                                value: batchSummary[0].id,
                              }
                        }
                        options={
                          //handleOptions(
                          currentScenarios
                          //)
                        }
                        onChange={(e) => setScenarioSelected(e)}
                        menuPortalTarget={document.getElementById(
                          "export_outputs"
                        )}
                        menuPosition="fixed"
                        isSearchable
                        style={{ width: "50%" }}
                      />
                    </div>
                    {currentData &&
                      currentData.scenario["site:scenario_setting"] ===
                        "weathered" && (
                        <div>
                          <label>Percentile (%)</label>
                          <Select
                            options={percentile}
                            defaultValue={{ value: 50, label: "50%" }}
                            onChange={(e) => setPercent(e)}
                            menuPortalTarget={document.getElementById(
                              "export_outputs"
                            )}
                            menuPosition="fixed"
                            isSearchable
                          />
                        </div>
                      )}
                  </StandardContainerRow>

                  <Divider
                    sx={{ borderBottomWidth: 3, bgcolor: "#00a0c6" }}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      margin: "16px 0",
                    }}
                  />
                </div>

                <div
                  style={{
                    overflowY: "scroll",
                    padding: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                  id="export_outputs"
                >
                  <StyledTable
                    header={<h4>Installation Costs and Duration</h4>}
                    data={
                      resultsData?.scenarioSummary
                        ? resultsData.scenarioSummary
                        : [{}]
                    }
                    order={false}
                    style={{ compact: true, colour: "#009ec6" }}
                  />

                  <StyledTable
                    header={[
                      <h4>Cost</h4>,
                      <StyledIconButton
                        style={{ marginLeft: "16px" }}
                        onClick={(e) => cost_breakdown.current.toggle(e)}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </StyledIconButton>,
                      <OverlayPanel
                        ref={cost_breakdown}
                        style={{ padding: "8px", fontFamily: "Montserrat" }}
                      >
                        <ul>
                          <li>
                            <strong>Idle Cost:</strong>{" "}
                            {currentData &&
                              pounds.format(
                                currentData.scenario["vessels:idle_cost"]
                              )}
                          </li>
                          <li>
                            <strong>Transit Cost:</strong>{" "}
                            {currentData &&
                              pounds.format(
                                currentData.scenario["vessels:transit_cost"]
                              )}
                          </li>
                          <li>
                            <strong>Work Cost:</strong>{" "}
                            {currentData &&
                              pounds.format(
                                currentData.scenario["vessels:work_cost"]
                              )}
                          </li>
                          <li>
                            <strong>Mob/DeMob Cost:</strong>{" "}
                            {currentData &&
                              pounds.format(
                                currentData.scenario[
                                  "vessels:mob_and_demob_cost"
                                ]
                              )}
                          </li>
                        </ul>
                      </OverlayPanel>,
                    ]}
                    data={costData}
                    order={false}
                    style={{ compact: true, colour: "#009ec6" }}
                  />

                  <DatasheetTable
                    title={"Cable Information"}
                    data={cables}
                    filtered={[
                      "cable_name",
                      "cable_type",
                      "conductor_material",
                      "conductor_size",
                      "insulation_material",
                      "system_voltage",
                      "mass",
                      "axial_stiffness",
                      "max_allowable_pulling_tension_basket_grip",
                      "max_allowable_sidewall_pressure",
                      "min_bend_radius_drum",
                      "min_bend_radius_installation",
                    ]}
                    style={{
                      primaryColor: "#009ec6",
                      secondaryColor: "hsla(0,0%,83%,.329)",
                      tertiaryColor: "hsla(0,0%,83%,.329)",
                    }}
                  />

                  <GanttChart
                    resultsData={resultsData?.quantDF}
                    scenarioName={
                      scenarioSelected.value ||
                      batchData[Object.keys(batchData)[0]].scenarioName
                    }
                  />

                  {currentData && (
                    <StyledTable
                      header={<h4>Installation Sequence</h4>}
                      data={
                        currentData?.listIn?.site_exportSections[
                          currentData.scenario["site:minimise_by"]
                        ]
                      }
                      order={[
                        "ids",
                        "End 1",
                        "End 2",
                        "carousel",
                        "Length",
                        "Load",
                      ]}
                      style={{ compact: true, colour: "#009ec6" }}
                    />
                  )}

                  {currentData && (
                    <VesselSummary
                      title={<h4>Vessel Capacities</h4>}
                      cables={[
                        currentData.scenario["vessels:primary_carousel_cable"],
                        currentData.scenario[
                          "vessels:secondary_carousel_cable"
                        ],
                      ]}
                      capacities={currentData?.listIn?.capacities}
                      vessel={currentData.scenario["vessels:selected_vessel"]}
                      style={{ height: "100%", colour: "#009ec6" }}
                    />
                  )}

                  {currentData &&
                    currentData.scenario["site:scenario_setting"] ===
                      "weathered" && (
                      <DeplayBarChart
                        resultData={{
                          data: resultsData?.recordDelays,
                          names: resultsData?.delayNames,
                        }}
                        percentile={percent}
                        title={true}
                      />
                    )}
                  {currentData &&
                    currentData.scenario["site:scenario_setting"] ===
                      "weathered" && (
                      <Steams resultsData={resultsData} title={true} />
                    )}
                  <Sunburst
                    resultData={resultsData?.ganttSummary}
                    title={true}
                  />
                </div>
              </div>
            ) : loading &&
              Object.keys(batchData).length !== findResults(batchData).total &&
              Object.keys(batchData).length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <ScaleLoader color="#009ec6" />
                <label>
                  Loading Scenario(s) Information . . .{" "}
                  {findResults(batchData).total} / {batchSummary.length}
                </label>
              </div>
            ) : (
              <div
                style={
                  loading
                    ? { display: "none" }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }
                }
              >
                No Scenario(s) Run . . .
              </div>
            )}
          </div>
        )}
        {/* ) : ( */}
        {currentData && (
          <ReportRender
            style={{ height: "100%" }}
            logo={logo}
            settings={settings}
            data={{
              resultsData: resultsData,
              costData: costData,
              cables: cables,
              batchData: currentData,
              percent: percent,
            }}
            previewPDF={previewPDF}
          />
        )}
        {/* )} */}

        {/* <SectionFooter> */}
        {/* <SectionFooterLeft></SectionFooterLeft>
          <div style={{ display: "flex" }}>
            <PrimeButton>CSV</PrimeButton>
            <PrimeButton>PDF</PrimeButton>
          </div> */}
        <BottomBar
          previewPDF={previewPDF}
          setPreviewPDF={setPreviewPDF}
          settings={settings}
          setSettings={setSettings}
          logo={logo}
          setLogo={setLogo}
          data={{
            resultsData: resultsData,
            costData: costData,
            cables: cables,
            batchData: currentData,
            percent: percent,
          }}
        />
        {/* </SectionFooter> */}
      </div>
    </>
  );
}

export default Results;
