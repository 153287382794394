import React, { useEffect, useState } from "react";
import BatchSummaryTable from "./BatchSummaryTable";
import {
  Cable,
  PrimeButton,
  Row8pxGap,
  SecButton,
  Section,
  SectionFooter,
  SectionFooterLeft,
} from "../../../styles/styles";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, IconButton, Modal } from "@mui/material";
import styled from "styled-components";
import {
  faCalendarDays,
  faCloudSunRain,
  faCog,
  faGear,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import BpModal from "../bp/BpModal";

import { StyledIconButton } from "../../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";

import cablecrousel from "../data/cableCrousel.json";
// import ScheduleModal from "../common/ScheduleModal";
import ContextMenu from "../../../common/ContextMenu";

import AnalysisRow from "../../../media/tutorials/AnalysisRow.png";
import ScenarioSettings from "../../../media/tutorials/ScenarioSettings.gif";

import Joyride, { STATUS } from "react-joyride";

// const StyledIconButton = styled(IconButton)`
//   width: 35px;
//   height: 35px;
//   border-radius: 50%;
//   background-color: ${(props) =>
//     props.active ? "#e0e0e0" : "transparent"} !important;
//   transition: background-color 0.2s ease, transform 0.2s ease;

//   &:hover {
//     background-color: #009ec641 !important;
//     transform: scale(1.1);
//   }
// `;

function Analysis({
  user,
  batchData,
  setBatchData,
  batchSummary,
  setBatchSummary,
  batchSummarySelect,
  setBatchSummarySelect,
  setResultsData,
}) {
  const [createScenario, setCreateScenario] = useState({
    open: false,
    scenario: false,
  });
  const [scenariosToAnalyse, setScenariosToAnalyse] = useState([]);
  const [globalSettings, setGlobalSettings] = useState(false);
  const [scenarioSettings, setScenarioSettings] = useState({
    open: false,
    scenario: false,
  });

  const [runSettings, setRunSettings] = useState({
    open: false,
    element: null,
  });

  const [caraCable, setcaraCable] = useState({
    data: cablecrousel,
    short: [],
  });

  const [runJoyride, setRunJoyride] = useState(false);
  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Batch Analysis</h2>
            <p>Run multiple scenarios with unique settings all at once.</p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right",
        target: ".section_analysis",
        disableBeacon: true,
      },
      {
        title: "Scenarios to Analyse",
        content: (
          <div>
            <img width={"100%"} src={AnalysisRow} />
            <p>The scenarios for analysis will be listed here when added.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#scenarios_toAnalyse",
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: "Global Scenario Settings",
        content: (
          <div>
            <img width={"100%"} src={ScenarioSettings} />
            <p>
              Add global settings to all scenarios to be analysed. This will
              override the individual scenario settings. Remember to click
              "Apply to All" for the settings to be properly apply.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".global_settings",
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: "Run Analysis",
        content: (
          <p>
            Click this when you are ready to analyse the cables loaded. The
            results will appear in the "Results" panel.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "top",
        target: "#runBatch",
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleRun = async (type) => {
    // console.log("run begins");
    // console.log(batchData);

    for (const key in batchData) {
      const temp = { ...batchData };
      temp[key].results = [];
      setBatchData(temp);

      setBatchSummarySelect(key);
    }

    document.getElementById("hidden_runButton").click();
  };

  const handleDateFormat = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  };

  const handleRemove = (index, id) => {
    const temp = [...batchSummary];

    setBatchData((prev) => {
      const tempBatch = { ...prev };
      delete tempBatch[id];

      return tempBatch;
    });

    temp.splice(index, 1);
    setBatchSummary(temp);
  };

  const renderScenariosToAnalyse = () => {
    const toAnalyse = [];
    // console.log({ batchSummary });

    for (let i = 0; i < batchSummary.length; i++) {
      const batch = batchSummary[i];
      // console.log(batch);

      toAnalyse.push([
        <Cable key={`Scenario To Analyse ${i}`}>
          <Row8pxGap style={{ width: "fit-content" }}>
            <label style={{ textOverflow: "ellipsis" }}>{batch.Name}</label>
            {batch.weather === "weathered" && (
              <FontAwesomeIcon icon={faCloudSunRain} />
            )}
          </Row8pxGap>

          {/* <label className="cableName">{batch.Vessel}</label>
          <label className="cableName">{handleDateFormat(batch.Start)}</label>
          <label className="cableName">{batch.Length} km</label> */}

          <Row8pxGap>
            <StyledIconButton
              title="Remove Scenario"
              onClick={() => handleRemove(i, batch.id)}
            >
              <FontAwesomeIcon color="black" icon={faTrash} />
            </StyledIconButton>

            <StyledIconButton
              title="Scenario Details"
              onClick={() =>
                setCreateScenario({
                  open: true,
                  scenario: batchData[batch.id].scenario,
                  type: "Close",
                })
              }
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>

            <StyledIconButton
              title="Scenario Settings"
              onClick={() => {
                // console.log(batch.id);
                // console.log({ batchData });

                // console.log(batchData[batch.id]);

                setScenarioSettings({
                  open: true,
                  scenario: {
                    ...batchData[batch.id].scenario,
                    batchId: batch.id,
                  },
                });
              }}
            >
              <FontAwesomeIcon color="black" icon={faCog} />
            </StyledIconButton>
          </Row8pxGap>
        </Cable>,
        ...(i < 4 ? [<Divider />] : []),
      ]);
    }
    setScenariosToAnalyse(toAnalyse);
  };

  useEffect(() => {
    renderScenariosToAnalyse();
  }, [batchSummary]);

  return (
    <>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />
      <div
        style={{
          gridArea: "4 / 1 / 7 / 4",
          padding: "0 0 16px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        className="section_analysis"
      >
        <header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ margin: "0" }}>Batch Analysis</h1>
              <StyledIconButton
                title="Collection Guidance"
                onClick={handleTutorial}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </StyledIconButton>
            </div>

            <div className="global_settings">
              <SecButton
                title="Global Cable Run Settings"
                onClick={() => {
                  setGlobalSettings(true);
                }}
              >
                Settings
              </SecButton>
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "16px" }} />
        </header>

        <div
          id="scenarios_toAnalyse"
          style={{
            backgroundColor: "#ffffff",
            border: "1.5px solid lightgrey",
            borderRadius: "8px",
            height: "100%",
            overflowY: "scroll",
            overflowX: "none",
          }}
        >
          {/* <Cable style={{ width: "75%" }}>
            <label style={{ margin: "0", fontWeight: "600" }}>Name</label>
            <label style={{ margin: "0", fontWeight: "600" }}>Vessel</label>
            <label style={{ margin: "0", fontWeight: "600" }}>Start</label>
            <label style={{ margin: "0", fontWeight: "600" }}>Length</label>
          </Cable>
          <Divider /> */}
          {scenariosToAnalyse}
          {/* <BatchSummaryTable
          batchSummary={batchSummary}
          setBatchSummarySelect={setBatchSummarySelect}
        /> */}
        </div>

        <SectionFooter>
          <SectionFooterLeft>
            Scenarios: {batchSummary.length} / 5
          </SectionFooterLeft>
          <PrimeButton
            id="runBatch"
            title="Run Batch"
            onClick={(e) => handleRun("runWeathered")}
          >
            Run
          </PrimeButton>
          {/* <ContextMenu
            status={runSettings}
            item={null}
            reset={() => setRunSettings({ open: false, element: null })}
            sectionOne={[
              {
                name: <div>Run Weathered</div>,
                callback: () => {
                  // console.log("Weathered");
                  setRunSettings({ open: false, element: null });
                  handleRun("runWeathered");
                },
              },
            ]}
            sectionTwo={[
              {
                name: <div>Run Un-Weathered</div>,
                callback: () => {
                  // console.log("Un-Weathered");
                  setRunSettings({ open: false, element: null });
                  handleRun("runUnweathered");
                },
              },
            ]}
            position={{
              vertical1: "right",
              vertical2: "bottom",
              horizontal1: "right",
              horizontal2: "right",
            }}
          /> */}
        </SectionFooter>
      </div>

      <Modal
        open={createScenario.open}
        onClose={() => setCreateScenario({ open: false, scenario: false })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown={true}
      >
        <BpModal
          user={user}
          handleClose={() =>
            setCreateScenario({ open: false, scenario: false })
          }
          type={"Close"}
          modalName={"Scenario Details"}
          caraCable={caraCable}
          setCaraCable={setcaraCable}
          scenario={createScenario.scenario}
          batchData={batchData}
          setBatchData={setBatchData}
          setBatchSummary={setBatchSummary}
        />
      </Modal>

      <Modal
        open={globalSettings}
        onClose={() => setGlobalSettings(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown
      >
        <BpModal
          user={user}
          handleClose={() => setGlobalSettings(false)}
          type="Apply to All"
          modalName={"Global Scenario Settings"}
          caraCable={caraCable}
          batchData={batchData}
          setBatchData={setBatchData}
          setBatchSummary={setBatchSummary}
        />
      </Modal>

      <Modal
        open={scenarioSettings.open}
        onClose={() => setScenarioSettings({ open: false, scenario: null })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown
      >
        <BpModal
          user={user}
          handleClose={() =>
            setScenarioSettings({ open: false, scenario: null })
          }
          type="Apply"
          modalName={"Scenario Settings"}
          scenario={scenarioSettings.scenario}
          caraCable={caraCable}
          batchData={batchData}
          setBatchData={setBatchData}
          setBatchSummary={setBatchSummary}
        />
      </Modal>
    </>
  );
}

export default Analysis;
