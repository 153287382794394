import React from 'react'
import styled from 'styled-components'

const SegmentCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px; 
  /* background-color:rgb(248, 248, 248); */
`



function Notes({reset_cable}) {
  return (
    <SegmentCol>
      <p>This Interactive Simulator helps you understand how Cable Design choices directly impact Electrical Performance.</p>

      <p>
        Experiment with different parameters to see real-time changes in the 66KV 630mm HVAC 3-core submarine cable model.
      </p>
      <p> Click <a href="#" style={{
        color
        : "#00A0C6"
      }} onClick={reset_cable}>reset</a> to return to default design</p>
    </SegmentCol>
  )
}

export default Notes
