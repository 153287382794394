import React, { useEffect } from "react";
import styled from "styled-components";
import Designer from "./CableDesigner/designer/Designer";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

const Base = styled.div`
  padding-top: 72px;
  height: 1072px;
  color: #333;
  display: flex;
  flex-direction: column;
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;  /* stack children vertically */
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: #3e9eff; */
  padding: 20px;
`;

const HeaderContent = styled.div`
  /* background-color: #7c7c7c; */
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 800px; /* Optional: limits the width */
`;

function Education() {
  useEffect(()=>{
    logEvent(analytics, 'Education Page Loaded');
  }, [])
  return (
    <Base>
      <HeaderBlock>
        <HeaderContent>
          <h4 style={{ margin: "0 0 10px 0" }}>
            Welcome to the Interactive Cable Design Simulator
          </h4>
          <p style={{ margin: 0 }}>
            To learn more please watch the{" "}
            <a style={{
              color: "#00A0C6"
            }} href="https://www.youtube.com/watch?v=lWe5vyLF1r4" target="_blank" rel="noopener noreferrer">
              video
            </a>.
          </p>
        </HeaderContent>
      </HeaderBlock>
      <Designer />
    </Base>
  );
}

export default Education;
