import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const boxStyle = {
  position: "absolute",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const NameModal = ({
  open,
  onClose,
  editableName,
  setName,
  error,
  setError,
  type,
  dataToSave,
}) => {
  const [oldFileName, setOldFileName] = useState(null);
  // Update oldFileName whenever editableName changes
  useEffect(() => {
    if (oldFileName === null) {
      if (editableName) {
        setOldFileName(editableName);
      }
    }
  }, [editableName]);
  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleSave = async () => {
    // if (!error) {
    //   console.log(`Data to save: ${dataToSave}`); // add this line
    //   console.log(`Name: ${editableName}`); // add this line
    //   console.log(`Type: ${type}`); // add this line

    //   const saveData = httpsCallable(functions, "saveData");
    //   const result = await saveData({
    //     name: editableName,
    //     data: dataToSave,
    //     type,
    //   });

    //   console.log(result.data); // log the result
    //   onClose();
    // }
  };
  const handleRename = async () => {
    // if (!error) {
    //   console.log(`oldFileName: ${oldFileName}`); // add this line
    //   console.log(`editableName: ${editableName}`); // add this line
    //   console.log(type);
    //   const renameData = httpsCallable(functions, "renameData");
    //   const result = await renameData({
    //     oldFileName,
    //     newFileName: editableName,
    //     type,
    //   });
    //   console.log(result.data); // log the result
    //   setOldFileName(null); // update oldFileName
    //   onClose();
    // }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="New Name"
          type="text"
          fullWidth
          variant="standard"
          value={editableName}
          onChange={handleNameChange}
          spellCheck="false"
          error={error}
          helperText={error ? "Name cannot be empty" : ""}
          sx={{ mt: 2 }}
        />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Box>
            <Button onClick={handleRename} disabled={error} sx={{ mr: 1 }}>
              Rename
            </Button>
            <Button onClick={handleSave} disabled={error}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NameModal;
