import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Cable,
  CableCollection,
  PrimeButton,
  Row8pxGap,
  SectionFooter,
  SectionFooterLeft,
} from "../../../styles/styles";

import { Divider, IconButton, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joyride, { STATUS } from "react-joyride";

import cablecrousel from "../data/cableCrousel";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

import { v4 as uuidv4 } from "uuid";
import {
  faAdd,
  faCloudSunRain,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import BpModal from "../bp/BpModal";
import {
  deleteCable,
  getScenarios,
  initializeScenarios,
} from "../../../functions/functions";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { handleAddScenario } from "../helperFunctions";
import ContextMenu from "../../../common/ContextMenu";

import ScenarioRow from "../../../media/tutorials/ScenarioRow.png";
import ScenarioCreate from "../../../media/tutorials/ScenarioCreate.gif";
import { BeatLoader } from "react-spinners";

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

function Scenarios({
  user,
  batchData,
  setBatchData,
  batchSummary,
  setBatchSummary,
}) {
  const [startDate, setStartDate] = useState("2026-04-01");

  const [createScenario, setCreateScenario] = useState({
    open: false,
    scenario: false,
    type: "Close",
  });
  const [scenarios, setScenarios] = useState([]);
  const [scenarioCollection, setScenarioCollection] = useState([]);
  const [updateCollection, setUpdateCollection] = useState(false);
  const [loadingScenario, setLoadingScenario] = useState({
    loading: false,
    name: "",
  });

  const [siteExportSections, setSiteExportSections] = useState();

  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    steps: [
      {
        content: (
          <div>
            <h2>Scenario Collection</h2>
            <p>Visualise and explore all your created scenarios here.</p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right",
        target: ".section_scenario",
        disableBeacon: true,
      },
      // {
      //   title: "Cable Filtering",
      //   content: (
      //     <div>
      //       {/* <img width={"100%"} src={CableFilter} /> */}
      //       <p>
      //         Searching for a specific cable? Apply filter in this popup to only
      //         show those cables. <br></br>
      //         The "Results" value in the bottom left indicates the amount of
      //         cables that make the filter applied.
      //       </p>
      //     </div>
      //   ),
      //   locale: { skip: <strong aria-label="skip">SKIP</strong> },
      //   placement: "right",
      //   target: "#cable_filtering",
      // },
      {
        title: "Scenario Collection",
        content: (
          <div>
            <img width={"100%"} src={ScenarioRow} />
            <p>
              Each row represents a scenario in your personal collection{" "}
              <br></br>
              The scenario name is unique to you and whatever naming convetion
              you wish. Scenarios with the following icon (
              <FontAwesomeIcon icon={faCloudSunRain} />) are weather included
              scenarios.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: "#ScenarioCollectionScroll",
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: "Create Scenario",
        content: (
          <div>
            <img width={"100%"} src={ScenarioCreate} />
            <p>
              Create custom scenarios and add them to your personal collection.
              <br></br>
              <br></br>
              Fill in the information in the popup after clicking this button
              and save. Any missing required information will be highlighted
              before saving.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: "#scenario_create",
      },
    ],
  });

  const [caraCable, setCaraCable] = useState({ main: null, below: null });
  const [createSettings, setCreateSettings] = useState({
    open: false,
    element: null,
  });

  const [capacities, setCapacities] = useState();

  const [runJoyride, setRunJoyride] = useState(false);
  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };
  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleDelete = async (scenario, index) => {
    try {
      const confirm = window.confirm(
        "You are about to delete a scenario from your collection, Are you sure?"
      );
      if (!confirm) return;

      await deleteCable(scenario, "scenarios");
      // remove cable from collection by index
      const new_scenarios = [...scenarios];
      new_scenarios.splice(index, 1);
      setScenarios(new_scenarios);
      // console.log("Scenario Deleted");
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    const new_collection = [
      ...scenarios.map((scenario, index) => {
        console.log("__________________________");

        console.log({ loadingScenario });
        console.log(
          !loadingScenario.loading &&
            loadingScenario.name !== scenario["site:name"]
        );

        return [
          <Cable title={scenario["site:name"]} key={uuidv4()}>
            {loadingScenario.loading &&
              loadingScenario.name === scenario["site:name"] && (
                <span
                  style={{
                    position: "absolute",
                    background: "#ffffffe0",
                    width: "98%",
                    zIndex: "1",
                    textAlign: "center",
                  }}
                >
                  <label>...Adding to Batch Analysis</label>
                </span>
              )}
            <Row8pxGap style={{ width: "fit-content" }}>
              <label
              // className="cableName"
              >
                {scenario["site:name"]}
              </label>
              {scenario["site:scenario_setting"] === "weathered" && (
                <FontAwesomeIcon icon={faCloudSunRain} />
              )}
            </Row8pxGap>

            <Row8pxGap>
              <StyledIconButton
                title="Delete Scenario"
                onClick={() => handleDelete(scenario, index)}
              >
                <FontAwesomeIcon color="black" icon={faTrash} />
              </StyledIconButton>
              <StyledIconButton
                title="Scenario Details"
                onClick={() =>
                  setCreateScenario({
                    open: true,
                    scenario: scenario,
                    type: "Close",
                  })
                }
              >
                <FontAwesomeIcon color="black" icon={faInfoCircle} />
              </StyledIconButton>

              <StyledIconButton
                title="Add Scenario"
                // disabled={loadingScenario}
                onClick={async () => {
                  setLoadingScenario({
                    loading: true,
                    name: scenario["site:name"],
                  });

                  const temp = await handleAddScenario(
                    scenario,
                    setBatchData,
                    setBatchSummary,
                    true
                  );

                  setLoadingScenario({
                    loading: false,
                    name: "",
                  });

                  console.log(temp);
                }}
              >
                <FontAwesomeIcon color="black" icon={faAdd} />
              </StyledIconButton>
            </Row8pxGap>
          </Cable>,
          <Divider />,
        ];
      }),
    ];

    // if(cables.length > count){
    //     new_collection.push( <div style={{ marginTop: "16px" }}>Scroll to load more...</div> )
    // }

    setScenarioCollection(new_collection);
  }, [scenarios, loadingScenario]);

  const updateScenarios = async () => {
    const fromFirebase = await getScenarios(user);

    // console.log({ fromFirebase });
    setScenarios(fromFirebase);
  };

  useEffect(() => {
    if (user) {
      initializeScenarios(user, setUpdateCollection);
    }
  }, []);

  useEffect(() => {
    if (user) {
      updateScenarios();
    }
  }, [user, updateCollection]);

  return (
    <>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
            margin: 0,
          },
        }}
      />
      <div
        className="section_scenario"
        style={{
          gridArea: "1 / 1 / 4 / 4",
          padding: "16px 0 0 16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ margin: "0" }}>Scenario Collection</h1>
              <StyledIconButton
                title="Collection Guidance"
                onClick={handleTutorial}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </StyledIconButton>
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "16px" }} />
        </header>

        <CableCollection
          id="ScenarioCollectionScroll"
          style={{ height: "100%" }}
        >
          {scenarioCollection.length > 0 ? (
            scenarioCollection
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No Scenarios . . .
            </div>
          )}
        </CableCollection>

        <SectionFooter>
          <SectionFooterLeft>
            Scenarios: {scenarioCollection.length}
          </SectionFooterLeft>
          {/* <SectionFooterRight id="cable_create"> */}
          <PrimeButton
            id="scenario_create"
            title="Create New Cable"
            onClick={(e) =>
              setCreateScenario({
                open: true,
                scenario: false,
                type: "Save",
              })
            }
          >
            Create
          </PrimeButton>
          {/* <ContextMenu
            status={createSettings}
            item={null}
            reset={() => setCreateSettings({ open: false, element: null })}
            sectionOne={[
              {
                name: <div>Create Weathered Scenario</div>,
                callback: () => {
                  // console.log("Weathered");
                  setCreateSettings({ open: false, element: null });
                  // handleRun("runWeathered");
                  setCreateScenario({
                    open: true,
                    scenario: false,
                    type: "Save",
                    setting: "weathered",
                  });
                },
              },
            ]}
            sectionTwo={[
              {
                name: <div>Create Un-Weathered Scenario</div>,
                callback: () => {
                  // console.log("Un-Weathered");
                  setCreateSettings({ open: false, element: null });
                  // handleRun("runUnweathered");
                  setCreateScenario({
                    open: true,
                    scenario: false,
                    type: "Save",
                    setting: "unweathered",
                  });
                },
              },
            ]}
            position={{
              vertical1: "right",
              vertical2: "bottom",
              horizontal1: "right",
              horizontal2: "right",
            }}
          /> */}
          {/* <MeButton >Create</MeButton> */}
          {/* </SectionFooterRight> */}
        </SectionFooter>
      </div>

      <Modal
        open={createScenario.open}
        onClose={() =>
          setCreateScenario({
            open: false,
            scenario: false,
            type: "Close",
          })
        }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown
      >
        <BpModal
          user={user}
          handleClose={() =>
            setCreateScenario({
              open: false,
              scenario: false,
              type: "Close",
            })
          }
          type={createScenario.type}
          modalName={"Scenario Details"}
          caraCable={caraCable}
          setCaraCable={setCaraCable}
          scenario={createScenario.scenario}
          batchData={batchData}
          setBatchData={setBatchData}
          setBatchSummary={setBatchSummary}
        />
      </Modal>
    </>
  );
}

export default Scenarios;
