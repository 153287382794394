import React, { useState } from "react";
import WindPowerIcon from "@mui/icons-material/WindPower";
import { PillButtonLanding } from "../../../styles/styles";
import CableIcon from "../../../media/CableIcon.png";

function ServicesHomeTwo({ className }) {
  const [advanced, setAdvanced] = useState(false);

  return (
    <>
      <section className={`appie-services-2-area ${className}`} id="service">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-md-8">
              <div className="appie-section-title">
                <h3 className="appie-title">How does it work</h3>
                {/* <h4>Basic Features</h4> */}
                <p>
                  Revolutionise your cable RFP and design management workflow
                  with our secure, cloud-based platform meticulously crafted by
                  industry experts.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div
                className="appie-section-title text-right"
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {/* <a className="main-btn" href="#">
                  Discover advanced features{" "}
                  <i className="fal fa-arrow-right" />
                </a> */}
                <PillButtonLanding
                  style={{ width: "fit-content", fontSize: "16px" }}
                  onClick={() => setAdvanced((temp) => !temp)}
                >
                  {advanced
                    ? "Discover the Cables Platform"
                    : "Discover the Installation Module"}
                </PillButtonLanding>
              </div>
            </div>
          </div>
          {!advanced ? (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i>
                      <img src={CableIcon} width={26} />
                    </i>
                  </div>
                  <h4 className="title">Build your perfect cable</h4>
                  <p>
                    Transform cable designs with our secure cable builder
                    platform. Whether you prefer industry-standard defaults or
                    need precise geometric specifications, create cables that
                    match your exact requirements.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-magnifying-glass fa-xl"></i>
                  </div>
                  <h4 className="title">Smart Cable Discovery</h4>
                  <p>
                    Navigate our extensive database of over 1000 cables with
                    precision filtering. Find your ideal cable instantly through
                    intuitive search tools and advanced filtering, complete with
                    detailed cross-sectional visualisations.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-money-bill-wave fa-xl"></i>
                  </div>
                  <h4 className="title">Real-Time Value Intelligence</h4>
                  <p>
                    Access sophisticated pricing analytics for high-voltage
                    cables, powered by market data since 2015. Get accurate cost
                    estimates for onshore and offshore applications
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-file-invoice fa-xl"></i>
                  </div>
                  <h4 className="title">Instant Technical Documentation</h4>
                  <p>
                    Generate professional cable datasheets in seconds. Create
                    comprehensive technical documentation tailored to your
                    project needs, including electrical, mechanical, and
                    environmental parameters.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-business-time fa-xl"></i>
                  </div>
                  <h4 className="title">Automated Pricing Calculations</h4>
                  <p>
                    Streamline your RFQ process with our advanced pricing
                    engine. Reduce response time and costs with automatic
                    calculations based on raw materials, manufacturing costs,
                    and market data.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-file-contract fa-xl"></i>
                  </div>
                  <h4 className="title">Industry-Standard Compatibility</h4>
                  <p>
                    Ensure your cable designs meet global standards. Our
                    platform is compatible with industry benchmarks like IEC
                    60286, CIGRE TB 908, and TB 880, supporting multiple export
                    formats.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-ship fa-xl"></i>
                  </div>
                  <h4 className="title">Vessel Selection</h4>
                  <p>
                    Select the vessel best suited for your project from our
                    database of generic public vessels covering a wide range of
                    specifications and operational performance characteristics.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-dharmachakra fa-xl"></i>
                  </div>
                  <h4 className="title">Explore Carousel Limits</h4>
                  <p>
                    Analyse how much cable you can fit on each vessel type along
                    with better understanding the limits of each vessel carousel
                    configurations relative to weight and stacking height.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i>
                      <WindPowerIcon style={{ fontSize: "x-large" }} />
                    </i>
                  </div>
                  <h4 className="title">Choose your Site</h4>
                  <p>
                    Using our interactive map you can build your project site
                    from our global database of offshore wind sites. Easily
                    calculate the length of cable needed and refine each cable
                    circuit.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-sun-rain fa-xl"></i>
                  </div>
                  <h4 className="title">Incorporated Weather Data</h4>
                  <p>
                    Refine your installation analysis using the built in ERA 5
                    weather data and easily select the weather point closest to
                    your project site, transit route and loadout location or
                    port.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-business-time fa-xl"></i>
                  </div>
                  <h4 className="title">Automated Pricing Calculations</h4>
                  <p>
                    Streamline your RFQ process with our advanced pricing
                    engine. Reduce response time and costs with automatic
                    calculations based on vessel rates and weather downtime.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-file-invoice fa-xl"></i>
                  </div>
                  <h4 className="title">Instant Technical Documentation</h4>
                  <p>
                    Generate professional installation reports in seconds.
                    Create detailed technical documentation tailored to your
                    project needs and scenario results.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ServicesHomeTwo;
