import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

function GenericTable({
  tableInfo,
  input = null,
  pagination = null,
  order = null,
  handleUpdateScenario = () => {},
  edit,
}) {
  const gridRef = useRef();
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([]);
  const [tableData, setTableData] = useState([]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 50,
    };
  }, []);

  useEffect(() => {
    // console.log({ tableData, colDefs });
  }, [tableData, colDefs]);

  useEffect(() => {
    if (tableInfo.length > 0) {
      let headers = Object.keys(tableInfo[0]);
      if (order) headers = order;

      setColDefs(
        headers.map((name) => {
          return {
            ...(tableInfo[0][name].label
              ? { headerName: tableInfo[0][name].label }
              : {}),
            field: name,
            ...(tableInfo[0][name].options && !edit
              ? tableInfo[0][name].options
              : { sortable: true, flex: 1, minWidth: 100, editable: false }),
            ...(tableInfo[0][name].minWidth && {
              width: tableInfo[0][name].minWidth,
            }),
            wrapTest: true,
            autoHeight: true,
          };
        })
      );

      setTableData(
        tableInfo.map((row) => {
          const temp = {};

          Object.keys(row).map((item) => {
            // console.log(item);
            // console.log(row[item].value);
            

            if (row[item].hasOwnProperty("value")) {
              temp[item] = row[item].value;
            } else {
              temp[item] = row[item];
            }
          });

          // console.log(temp);

          return temp;
        })
      );
    }
  }, [tableInfo, edit]);

  const updateToTable = (e) => {
    const temp = [...tableInfo];
    const tempRow = {};

    // console.log({ temp });
    // console.log(e.rowIndex);

    Object.keys(temp[e.rowIndex]).map((item) => {
      tempRow[item] = { ...temp[e.rowIndex][item], value: e.data[item] };
    });
    temp[e.rowIndex] = tempRow;

    handleUpdateScenario({ value: temp }, input);
  };

  return (
    <div className="ag-theme-quartz" style={{ width: "100%" }}>
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        columnDefs={colDefs}
        pagination={tableInfo.length > 4 ? pagination : false}
        paginationPageSizeSelector={[4]}
        paginationPageSize={4}
        domLayout="autoHeight"
        // autoSizeStrategy={autoSizeStrategy}
        onCellValueChanged={updateToTable}
        onGridReady={() => {
          gridRef.current.api.sizeColumnsToFit();
        }}
        onFirstDataRendered={() => {
          gridRef.current.api.sizeColumnsToFit();
        }}
        overlayNoRowsTemplate="No Operations"
      />
    </div>
  );
}

export default GenericTable;
