import React, { useEffect, useState } from "react";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import { InputSwitch } from "primereact/inputswitch";
import {
  PillButton,
  StandardContainerRow,
  StyledIconButton,
} from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";

import Select from "react-select";

function GanttChart({ resultsData, scenarioName }) {
  const [ganttData, setGanttData] = useState([
    {
      start: new Date(2020, 1, 1),
      end: new Date(2020, 1, 2),
      name: "Idea",
      id: "Task 0",
      type: "project",
      progress: 0,
    },
  ]);
  const [view, setView] = useState(ViewMode.Week);
  const [isChecked, setIsChecked] = useState(true);

  const handleDownloadCSV = () => {
    const titleKeys = Object.keys(resultsData[0]);

    const refinedData = [];
    refinedData.push(titleKeys);

    resultsData.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";

    refinedData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    // console.log({ scenarioName });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", scenarioName + "_Gantt.csv");
    link.click();
  };

  useEffect(() => {
    const formatted = [];
    // console.log(resultsData);
    if (resultsData && resultsData.length > 0) {
      // console.log(resultsData);
      resultsData.map((item) => {
        formatted.push({
          start: new Date(item.Start.split(" ")[0]),
          end: new Date(item.Finish.split(" ")[0]),
          name: item.Event,
          id: uuid(),
          type: "task",
          progress: 0,
          isDisabled: true,
          //   styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          //   project: "Test",
        });
      });

      setGanttData(formatted);
    }
  }, [resultsData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <StandardContainerRow style={{ overflow: "hidden" }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <label style={{ margin: "0" }}>Show Task List :</label>
          <InputSwitch
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </div>

        <StandardContainerRow style={{ width: "fit-content", gap: "16px" }}>
          <StandardContainerRow style={{ gap: "8px", overflow: "hidden" }}>
            <FontAwesomeIcon icon={faClock} size="lg" />
            <label style={{ margin: "0" }}>:</label>
            <Select
              value={{ label: view, value: view }}
              menuPortalTarget={document.getElementById("scenarioModal")}
              menuPosition="fixed"
              onChange={(e) => setView(ViewMode[e.value])}
              options={[
                { label: "Week", value: "Week" },
                { label: "Month", value: "Month" },
                { label: "Year", value: "Year" },
              ]}
            />
            {/* <PillButton onClick={() => setView(ViewMode.Week)}>Week</PillButton>
            <PillButton onClick={() => setView(ViewMode.Month)}>
              Month
            </PillButton>
            <PillButton onClick={() => setView(ViewMode.Year)}>Year</PillButton> */}
          </StandardContainerRow>

          <StyledIconButton title="Download Gantt" onClick={handleDownloadCSV}>
            <FontAwesomeIcon color="#00a0c6" icon={faFileArrowDown} />
          </StyledIconButton>
        </StandardContainerRow>
      </StandardContainerRow>

      <Gantt
        tasks={ganttData}
        viewMode={view}
        //   onDateChange={handleTaskChange}
        //   onDelete={handleTaskDelete}
        //   onProgressChange={handleProgressChange}
        //   onDoubleClick={handleDblClick}
        //   onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "130px" : ""}
        columnWidth={300}
        rowHeight={50}
        todayColor="#009ec610"
        ganttHeight={500}
      />
    </div>
  );
}

export default GanttChart;
